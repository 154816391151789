import React, { /*useState, useEffect,*/ useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";
import { UserContext } from "../Components/UserContext";

function UserPage(props) {
  // Sets initial state for skill list to empty array
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  if (!user.auth) {
    navigate("/login");
  }

  const sections = [
    {
      id: 10,
      name: "Il tuo account",
      description: "Gestisci il tuo profilo.",
      img: "dragons-fantasy-artificial-intelligence-image.jpg",
      authUrl:
        "https://www.freepik.com/free-ai-image/dragons-fantasy-artificial-intelligence-image_42080899.htm#fromView=search&term=rpg+character+classes&page=1&position=4",
      authText: "Image By freepik",
      url: "/profilo/impostazioni",
    },
    {
      id: 2,
      name: "I tuoi personaggi",
      description:
        "In questa sezione potrai gestire i personaggi che hai creato.",
      img: "rendering-wizard-controlling-magic.jpg",
      authUrl:
        "https://www.freepik.com/free-ai-image/rendering-wizard-controlling-magic_51251754.htm#fromView=search&term=rpg+character+classes&page=1&position=14",
      authText: "Image By freepik",
      url: "/profilo/personaggi",
    },
  ];
  if (user.level >= 1) {
    sections.push({
      id: 3,
      name: "Le tue campagne",
      description:
        "Qui puoi creare e gestire le tue campagne di gioco, e rendere disponibili le risorse per i tuoi giocatori.",
      img: "floating-civilization-sky.jpg",
      authUrl:
        "https://www.freepik.com/free-ai-image/floating-civilization-sky_44392760.htm#fromView=search&term=fantasy&page=1&position=17",
      authText: "Image By freepik",
      url: "/profilo/campagne",
    });
  }
  if (user.level >= 2) {
    sections.push({
      id: 4,
      name: "I tuoi sistemi di gioco.",
      description:
        "In questa sezione potrai aggiornare le risorse scaricabili relative ai tuoi sistemi di gioco e le loro descrizioni.",
      img: "character-fire-with-skull-his-chest.jpg",
      authUrl:
        "https://www.freepik.com/free-ai-image/character-fire-with-skull-his-chest_40648425.htm#fromView=search&term=rpg+character+classes&page=1&position=45",
      authText: "Image By freepik",
      url: "/sistemi-di-gioco",
    });
  }

  // Render the character list
  const renderSections = () => {
    if (sections.length > 0) {
      return sections.sort((a, b) => a.id - b.id).map((section) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            sx={{ display: "flex" }}
            key={"ch_" + section.id}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                minWidth: "210px",
              }}
            >
              <CardContent align="left">
                <CardMedia
                  component="img"
                  height="300px"
                  image={"/images/" + section.img}
                  alt={section.name}
                />
                <a href={section.authUrl} referrer="noreferrer" target="blank">
                  <Typography
                    variant="caption"
                    textAlign="right"
                    sx={{
                      display: "block",
                      position: "relative",
                      top: "-20px",
                      color: "lightgrey",
                      marginRight: "10px",
                    }}
                  >
                    {section.authText}
                  </Typography>
                </a>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  sx={{ marginTop: "-16px" }}
                  //variant='h6'
                  gutterBottom
                >
                  {section.name}
                </Typography>
                <Typography variant="body2" align="justify">
                  {section.description}
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  mt: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link to={section.url}>
                  <Typography textAlign="center">Entra</Typography>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        );
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Pagina personale</title>
      </Helmet>

      <main className="pg_container">
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Bentornato, {user.name}
          </Typography>
          <Typography align="center" color="textPrimary">
            Scegli cosa vuoi fare
          </Typography>
        </Container>

        <Container maxWidth="lg" align="stretch" className="characters_container">
          <Grid container spacing={2}>
            {renderSections()}
          </Grid>
        </Container>
      </main>
    </>
  );
}

export default UserPage;
