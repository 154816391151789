/*
    Questo file contiene tutti i parametri globali di configurazione del sito.
    Il file contiene varie configurazioni di sviluppo e di produzione.
    Commentare e scommentare i set di parametri in maniera opportuna, specialmente prima di 
    fare un build.
*/

// 1. parametri di sviluppo localhost/test.montefiorino + ws montefiorino ----------------------------------------------
const g_cfgDevelopment = {
  name: "Orremdi",

  // configurazione docker (sviluppo localhost con container docker)
  urlBase: "http://localhost/",
  urlBase2: "http://localhost", // versione senza '/' finale
  urlItemImg: "http:/localhost/images/",
  urlPageImg: "http://localhost/images/", // temporaneo durante fase di sviluppo. Dovrà puntare al sito / test
  //urlWSBase: "http://localhost/api/", // webservice
  urlWSBase: "https://www.orremdi.net/rpg-api/public/api/", // webservice

  authMode: 2, // 0: no auth, 1: fetch token (test), 2: token in cookie
  devTools: false, // if true, show react query devtools panel
  debug: true, // if true, shows debug messages on console log
  onlyLoggedCart: true, // if true, cart is accessible only if a registered user is logged in
  mandatoryConditionsAcceptance: true, // if true, privacy and sales conditions acceptance is mandatory. Otherwise it is optional and ws driven
  defaultImg: "/images/img_not_available.jpg",
  fullLanguages: true,

  accessTokenCookieName: "RpgAppWebserviceAccess",
  refreshTokenCookieName: "RpgAppWebserviceRefresh", // used to store token expiration timestamp (ms)
  sessionCookieName: "RpgAppWebserviceSession",
  sessionCookieDuration: 24, // hours, 0 = no expiring date
  userDataCookieName: "RpgAppUserData",
  consentCookieName: "RpgAppCookieConsent",
  consentCookieDuration: 24 * 365, // hours, 0 = no expiring date
  doofinderId: "",
  googleId: "",
  googleAnalyticsId: "",
  googleMapCoords: "",
  googleMapId: "", // per autocompletamento indirizzo di spedizione nel carrello (unused qui)
  reCaptchaKey: "6LemjlwgAAAAALlvZ0CZvuatw4_DgBcpz7oZfsb1", // testmontefiorino
  // NOTA: i metadati facebook (e potenzialmente altri) andrebbero iniettati dal server al caricamento di index.php
  facebookId: "153870014651629", // montefiorino-login

  facebookUrl: "",
  instagramUrl: "",
  pinterestUrl: "",
  twitterUrl: "",
  youTubeUrl: "",
  linkedinUrl: "",

  IubendaId: "",
  iubendaPrivacyUrl: "",
  iubendaCookieUrl: "",

  cartSilentRegister: true, // se true, la registrazione dell'utente all'invio dell'ordine è automatica
  mediaClause: "(max-width: 1320px)", // clausola delle media query che discrimina tra desktop mode e mobile mode

  // contacts info
  fullName: "",
  address: "",
  city: "",
  cap: "",
  prov: "",
  country: "",
  phone: "",
  mobile: "",
  mobileText: "",
  openingHours: "",
  whatsApp: "",

  // footer text
  footerText_it: "",
  footerText_en: "",
  footerText_de: "",
  footerText_es: "",
  footerText_fr: "",

  // newsletter text
  newsletterText_it: "",
  newsletterText_en: "",
  newsletterText_de: "",
  newsletterText_es: "",
  newsletterText_fr: "",

  // site public info
  siteName: "",
  siteEmail: "",

  htmlItemDescr: false, // se true, la descrizione articolo è in formato Html
  bannerIntervalMs: 8000, // temporizzazione slide banner (millisecondi)
};

// 2. parametri di produzione (webservice su sito committente / awaysolution) ------------------------------------------
const g_cfgProduction = {
  name: "Orremdi",
  urlBase: "https://www.orremdi.net/",
  urlBase2: "https://www.orremdi.net", // versione senza '/' finale
  urlItemImg: "https:/www.orremdi.net/images/",
  urlPageImg: "https://www.orremdi.net/images/", // temporaneo durante fase di sviluppo. Dovrà puntare al sito / test
  urlWSBase: "https://www.orremdi.net/rpg-api/public/api/", // webservice

  authMode: 2, // 0: no auth, 1: fetch token (test), 2: token in cookie
  devTools: false, // if true, show react query devtools panel
  debug: false, // if true, shows debug messages on console log
  onlyLoggedCart: true, // if true, cart is accessible only if logged in
  mandatoryConditionsAcceptance: true, // if true, privacy and sales conditions acceptance is mandatory. Otherwise it is optional and ws driven
  defaultImg: "/images/img_not_available.jpg",
  fullLanguages: false,

  accessTokenCookieName: "RpgAppWebserviceAccess",
  refreshTokenCookieName: "RpgAppWebserviceRefresh", // used to store token expiration timestamp (ms)
  sessionCookieName: "RpgAppWebserviceSession",
  sessionCookieDuration: 24, // hours, 0 = no expiring date
  userDataCookieName: "RpgAppUserData",
  consentCookieName: "RpgAppCookieConsent",
  consentCookieDuration: 24 * 365, // hours, 0 = no expiring date
  doofinderId: "",
  googleId: "",
  googleAnalyticsId: "",
  googleMapCoords: "",
  googleMapId: "", // per autocompletamento indirizzo di spedizione nel carrello (unused qui)
  reCaptchaKey: "6LemjlwgAAAAALlvZ0CZvuatw4_DgBcpz7oZfsb1", // testmontefiorino
  // NOTA: i metadati facebook (e potenzialmente altri) andrebbero iniettati dal server al caricamento di index.php
  facebookId: "153870014651629", // montefiorino-login

  facebookUrl: "",
  instagramUrl: "",
  pinterestUrl: "",
  twitterUrl: "",
  youTubeUrl: "",
  linkedinUrl: "",

  IubendaId: "",
  iubendaPrivacyUrl: "",
  iubendaCookieUrl: "",

  cartSilentRegister: false, // se true, la registrazione dell'utente all'invio dell'ordine è automatica
  mediaClause: "(max-width: 1320px)", // clausola delle media query che discrimina tra desktop mode e mobile mode

  // contacts info
  fullName: "",
  address: "",
  city: "",
  cap: "",
  prov: "",
  country: "",
  phone: "",
  mobile: "",
  mobileText: "",
  openingHours: "",
  whatsApp: "",

  // footer text
  footerText_it: "",
  footerText_en: "",
  footerText_de: "",
  footerText_es: "",
  footerText_fr: "",

  // newsletter text
  newsletterText_it: "",
  newsletterText_en: "",
  newsletterText_de: "",
  newsletterText_es: "",
  newsletterText_fr: "",

  // site public info
  siteName: "",
  siteEmail: "",

  htmlItemDescr: true, // se true, la descrizione articolo è in formato Html
  bannerIntervalMs: 8000, // temporizzazione slide banner (millisecondi)
};
//------------------------------------------------------------------------------------------------------------------

//const g_cfg = g_cfgDevelopment;
const g_cfg = g_cfgProduction;

// NOTA: i metadati facebook (e potenzialmente altri) vengono iniettati dal server al caricamento di index.php

g_cfg.convert = function (num, langExt = "It") {
  //console.log('convert: '+num);
  if (langExt === "En") {
    return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    //console.log('langExt='+langExt)
    return num
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
};

g_cfg.textToSlug = function (txt) {
  if (!txt) {
    return "";
  } else {
    return txt
      .toLowerCase()
      .normalize("NFD")
      .replace(/[®\u0300-\u036f]/g, "")
      .trim()
      .replace(/ /g, "-");
    /*        
                let res;
                res = txt.replaceAll(' ', '-');
                return res.toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")            
                    ;
        */
  }
};

g_cfg.post = function post(path, params) {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement("form");
  form.method = "post";
  form.action = path;

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      //console.log('post param '+key + ': '+ params[key]);
      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
};

g_cfg.rulesetList = [
  { 
    id: "r_f", 
    name: "Rapido & Furioso", 
    unusedStats: ['id', 'character_id'],
    statsNames: {
      AGI: 'Agilità',
      AIM: 'Mira',
      APP: 'Bellezza',
      CHA: 'Personalità',
      CMB: 'Combattività',
      CON: 'Resistenza',
      INT: 'Memoria',
      PER: 'Osservazione',
      POW: 'Potere',
      PRE: 'Precisione',
      STR: 'Forza',
      WIL: 'Determinaz.',
    },
    // to be used
    statsOrder: ['AGI', 'APP', 'CMB', 'WIL', 'STR', 'INT', 'AIM', 'PER', 'CHA', 'POW', 'PRE', 'CON']
  },
  { 
    id: "r_fmod", 
    name: "R & F modificato",
    unusedStats: ['id', 'character_id'],
    statsNames: {
      AGI: 'Agilità',
      AIM: 'Mira',
      APP: 'Bellezza',
      CHA: 'Personalità',
      CMB: 'Combattività',
      CON: 'Resistenza',
      INT: 'Memoria',
      PER: 'Osservazione',
      POW: 'Potere',
      PRE: 'Precisione',
      STR: 'Forza',
      WIL: 'Determinaz.',
    },
    // to be used
    statsOrder: ['AGI', 'APP', 'CMB', 'WIL', 'STR', 'INT', 'AIM', 'PER', 'CHA', 'POW', 'PRE', 'CON']
  },
  { 
    id: "tcsf", 
    name: "Sci-Fi", 
    unusedStats: ['id', 'character_id', 'CMB', 'AIM'],
    statsNames: {
      AGI: 'Agilità',
      AIM: '',
      APP: 'Bellezza',
      CHA: 'Carisma',
      CMB: '',
      CON: 'Costituzione',
      INT: 'Intelligenza',
      PER: 'Percezione',
      POW: 'Potere',
      PRE: 'Precisione',
      STR: 'Forza',
      WIL: 'Volontà',
    },
    // to be used
    statsOrder: ['AGI', 'CON', 'STR', 'PRE', 'CHA', 'INT', 'PER', 'WIL', 'APP', 'POW'],
    //skillsOrder:[],
    skillsInit: [
      {
        archetype_id: 1,  // avventuriero (spec. urbano) 
        bonus: [
          {skCode: 'skill_01', num: 1},
          {skCode: 'skill_02', num: 1},
          {skCode: 'skill_07', num: 2}, // forma atletica
          {skCode: 'skill_08', num: 1}, // furtività
          {skCode: 'skill_10', num: 1}, // interazione sociale
          {skCode: 'skill_14', num: 1}, // veicoli planetari
        ]
      },
      {
        archetype_id: 2,  // combattente 
        bonus: [
          {skCode: 'skill_01', num: 2}, // melee
          {skCode: 'skill_02', num: 2}, // range combat
          {skCode: 'skill_07', num: 1}, // forma atletica
          {skCode: 'skill_09', num: 1}, // conoscenze militari
          {skCode: 'skill_17', num: 1}, // sopravvivenza
        ]
      },
      {
        archetype_id: 3, // diplomatico
        bonus: [
          {skCode: 'skill_04', num: 1}, // commerciare
          {skCode: 'skill_05', num: 1}, // conoscenze generali
          {skCode: 'skill_06', num: 1}, // intrattenimento
          {skCode: 'skill_09', num: 1}, // conoscenze militari
          {skCode: 'skill_10', num: 2}, // interazione sociale
          {skCode: 'skill_11', num: 1}, // investigazione
          {skCode: 'skill_12', num: 1}, // utilizzare tecnologia
        ]
      },
      {
        archetype_id: 4, // esploratore (spec. outdoors)
        bonus: [
          {skCode: 'skill_01', num: 1},
          {skCode: 'skill_02', num: 1},
          {skCode: 'skill_07', num: 1}, // forma atletica
          {skCode: 'skill_08', num: 1}, // furtività
          {skCode: 'skill_10', num: 1}, // interazione sociale
          {skCode: 'skill_14', num: 2}, // veicoli planetari
          {skCode: 'skill_17', num: 1}, // sopravvivenza
        ]
      },
      {
        archetype_id: 5, //mistico
        bonus: [
          {skCode: 'skill_16', num: 1}, // controllo psionico
        ]
      },
      {
        archetype_id: 6, // sapiente
        bonus: [
          {skCode: 'skill_05', num: 2}, // conoscenze generali
          {skCode: 'skill_06', num: 1}, // intrattenimento
          {skCode: 'skill_11', num: 1}, // investigazione
          {skCode: 'skill_12', num: 1}, // utilizzare tecnologia
          {skCode: 'skill_15', num: 1}, // conoscenze mediche
          {skCode: 'skill_19', num: 2}, // conoscenze tecnologiche
        ]
      },
      {
        archetype_id: 7, // tecnico
        bonus: [
          {skCode: 'skill_05', num: 1}, // conoscenze generali
          {skCode: 'skill_13', num: 1}, // conoscenze tecniche
          {skCode: 'skill_19', num: 1}, // conoscenze tecnologiche
          {skCode: 'skill_12', num: 2}, // utilizzare tecnologia
          {skCode: 'skill_14', num: 1}, // veicoli planetari
          {skCode: 'skill_18', num: 1}, // veicoli aerospaziali
        ]
      },
      {
        archetype_id: 5, // creatura
        bonus: [
          {skCode: 'skill_01', num: 1}, // conmbattimento di mischia
        ]
      },
    ]
  },
];
g_cfg.archetypeList = [
  { id: 1, name: "Avventuriero" },
  { id: 2, name: "Combattente" },
  { id: 3, name: "Diplomatico" },
  { id: 4, name: "Esploratore" },
  { id: 5, name: "Mistico" },
  { id: 6, name: "Sapiente" },
  { id: 7, name: "Tecnico" },
  { id: 8, name: "Creatura" },
];
g_cfg.genderList = [
  { id: "male", name: "Maschio" },
  { id: "female", name: "Femmina" },
  { id: "other", name: "Altro" },
];
g_cfg.experienceLevels = [
  { id: 0, name: "Novizio" },
  { id: 1, name: "Qualificato" },
  { id: 2, name: "Veterano" },
  { id: 3, name: "Eroe" },
  { id: 4, name: "Leggenda" },
];
g_cfg.talentTypes = [
  { id: "starting", name: "Talenti iniziali" },
  { id: "generic", name: "Talenti generici" },
  { id: "combat", name: "Talenti di combattimento" },
  { id: "magic", name: "Talenti arcani" },
  { id: "power", name: "Poteri magici" },
  { id: "racial", name: "Talenti razziali" },
];
g_cfg.talentGrades = [
  { id: 1, name: "Grado I" },
  { id: 2, name: "Grado II" },
  { id: 3, name: "Grado III" },
];
g_cfg.skillTypes = [
  { id: "basic", name: "Base" },
  { id: "advanced", name: "Avanzata" },
];


export default g_cfg;
