import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import TalentForm from "../Components/TalentForm";
//import BasicTalent from "../Tools/BasicTalent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import OkPopup from "../Components/OkPopup";
import { UserContext } from "../Components/UserContext";

function TalentEditPage(props) {
  // Sets initial state for skill list to empty array
  const [IsLoading, setIsLoading] = useState(null);
  const [netError, setNetError] = useState(null);
  const [talent, setTalent] = useState(null);
  //const [showDialog, setShowDialog] = useState(false);
  const params = useParams();
  const id = params.id;
  const { wsCall, cfg } = props;
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const getTalent = useCallback(() => {
    let params = [];
    wsCall(
      "talents/" + id,
      "GET",
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (respData) => {
        if (cfg.debug === true) {
          console.log("get talent", respData);
        }
        // handle get order detail response
        if (respData.status === true) {
          setTalent(respData.data);
        } else {
          console.log("Failed: " + respData.message);
          //setCharacter(new BasicCharacter(2, props.cfg.rulesetList[0].id));
        }
      }
    );
  }, [id, wsCall, cfg.debug, IsLoading]);

  useEffect(() => {
    //console.log("id=" + id);
    if (!user.auth || user.level <= 1) {
      navigate("/login");
    } else {
      if (id && id > 0) {
        if (IsLoading !== null) {
          return; // NOTA: questo è necessario perchè altrimenti solo con true e false viene richiamato di continuo al termine della chiamata
        }
        //console.log("calling getTalent");
        getTalent();
      } else {
        setNetError("Id talento non valido");
      }
    }
  }, [user, getTalent, navigate, id, IsLoading]);

  function closeDialog() {
    setNetError(false);
  }

  //if (!talent || IsLoading) {
  if (!talent && !netError) {
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );
  }

  if (netError) {
    return (
      <OkPopup
        titletext={/*props.lang.LBL_ERROR*/ "Errore"}
        text={netError}
        closeHandler={closeDialog}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>Scheda talento</title>
      </Helmet>

      <main>
        <div className="pg_container">
          <Container maxWidth="md" className="title_container">
            <Typography
              variant="h4"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Scheda Talento
            </Typography>
          </Container>

          <Container maxWidth="large" className="character_container">
            <TalentForm
              talent={talent}
              skills={props.skills}
              talentTypes={props.cfg.talentTypes}
              talentGrades={props.cfg.talentGrades}
              rulesetList={props.cfg.rulesetList}
              wsCall={props.wsCall}
              wsUrl="talents"
              redirectUrl="/talenti"
              backUrl="/talenti"
            />
          </Container>
        </div>
      </main>
    </>
  );
}

export default TalentEditPage;
