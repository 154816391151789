import React from "react";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function MUILabeledText(props) {

    return (
        <>
            {
                props.labelRight && props.labelRight === true ? (
                    <Stack direction='row' spacing={1} >
                        <Typography style={{ whiteSpace: "pre-line", textAlign: "left" }}
                            //variant="body2"
                            //color="textSecondary"
                            //color="textPrimary"
                            component="span">
                            {props.text}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {props.label}
                        </Typography>
                    </Stack>

                ) : (
                    <Stack direction='row' spacing={1} >
                        <Typography sx={{ fontWeight: 'bold' }}>
                            {props.label}
                        </Typography>
                        <Typography style={{ whiteSpace: "pre-line", textAlign: "left" }}
                            //variant="body2"
                            //color="textSecondary"
                            //color="textPrimary"
                            component="span">
                            {props.text}
                        </Typography>
                    </Stack>

                )

            }
        </>
    )
}

export default MUILabeledText;
