import React from "react";
import { Typography } from "@mui/material";


const NoPage = (props) => {
  return (
    <main className='pg_container'>
          <Typography variant='h3' textAlign="center">404</Typography>
          <Typography variant='h5' textAlign="center" >Page not found</Typography>
    </main>
  )
};

export default NoPage;
