import React, { useState, useEffect, useContext } from "react";
import { Typography, Button, Stack, Grid, Card, CardMedia, CardContent, Container } from "@mui/material";
//import validator from "validator";
import { Link, useNavigate } from "react-router-dom";
//import Dialog from "@material-ui/core/Dialog";
import TextField from "@mui/material/TextField";
import OkPopup from "../Components/OkPopup";
//import MUITextField from '../Components/MUITextField';
import { UserContext } from '../Components/UserContext';

const LogoutPage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [OutcomeMessage, setOutcomeMessage] = useState("");
    const [netError, setNetError] = useState(null);
    const [formEmail, setFormEmail] = useState("");
    const [formPassword, setFormPassword] = useState("");
    const [errorList, setErrorList] = useState([]);
    const { user, logout } = useContext(UserContext);
    const navigate = useNavigate();

    const closeDialog = () => {
        setShowDialog(false);
    };


    useEffect(() => {
        logoutRequest();
    }, []);

    function logoutRequest() {
        let params = [
            //{ name: "session", value: props.sessionKey },
            //{ name: "lang", value: props.lang.TXT_LANGPAR },
            //{ name: "email", value: formEmail },
            //{ name: "password", value: formPassword },
        ];
        props.wsCall(
            "auth/logout",
            'POST',
            isLoading,
            setIsLoading,
            (errMsg) => {
                if (errMsg) {
                    //console.log(errMsg);
                    //setNetError(errMsg);
                    setOutcomeMessage(errMsg);
                    setShowDialog(true);
                }
            },
            params,
            (respData) => {
                //console.log(respData);
                //props.userUpdate(respData, props.redirect);
                //props.userUpdate(respData, false); // no redirect there
                if (respData.status === true) {
                    logout();
                    navigate('/');
                } else {
                    setOutcomeMessage(respData.message);
                    setShowDialog(true);
                }
            }
        );
    }

    if (isLoading === true) {
        return (
            <div className="spinner">
                <img src="/images/waiting.gif" alt="operazione in corso ..." />
            </div>
        );
    }

    return (
        <main className='pg_container'>
            {showDialog === true ? (
                <OkPopup
                    titletext="Errore!"
                    text={OutcomeMessage}
                    closeHandler={closeDialog}
                />
            ) : null}

            <Container maxWidth="md" className='title_container'>
                <Typography variant="h4" align="center" color="textPrimary" gutterBottom >
                    Logout
                </Typography>
            </Container>
            <Container maxWidth="md" className='title_container'>
                <Grid container spacing={4} >

                </Grid>
            </Container>

        </main>
    );
};
export default LogoutPage;
