import React, { useState, useEffect } from "react";
import { Button, Box, Stack, Divider } from "@mui/material";
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import LoupeIcon from '@mui/icons-material/Loupe';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import MUISelectField from './MUISelectField';
import MUITextField from '../Components/MUITextField';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './SideTalentPicker.css';

function TalentPickerDrawer(props) {
    const [typeFilter, setTypeFilter] = useState("starting");
    const [gradeFilter, setGradeFilter] = useState(0);
    const [textFilter, setTextFilter] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [netError, setNetError] = useState(null);
    const [talents, setTalents] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    //const [panelIn, setPanelIn] = useState(props.panelIn === true ? 'side_tp_panel pnl_in' : 'side_tp_panel pnl_out');
    const [expanded, setExpanded] = useState(false);
    const anchor = 'left';
    const drawerWidth = anchor === 'top' || anchor === 'bottom' ? 'auto' : 350;
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const endMessage = total < 0 ? "premi Invio per iniziare la ricerca" : "Caricamento completato.";

    const toggleDrawer = (anchor, open) => (event) => {
        //console.log('EVENT', event)
        if (event.type === 'keydown' && (event.key !== 'Tab' && event.key !== 'Escape')) {
            return;
        }
        //console.log('toggleDrawer ' + anchor + ', '+ open)
        //setState({ ...state, [anchor]: open });
        props.openPanel(open);
    };

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        //console.log('panelIn = ' + props.panelIn)
        setState({ ...state, [anchor]: props.panelIn });
    }, [props.panelIn])

    useEffect(() => {
        getTalents();
    }, [typeFilter, gradeFilter]);

    function changeFilterHandler(name, value) {
        if (name === 'searchText') {
            setTextFilter(value);
            setTotal(-1); // per differenziare il messaggio di continuazione
        } else {
            if (name === 'type')
                setTypeFilter(value);
            if (name === 'grade')
                setGradeFilter(value);
            setTotal(0);
        }
        setTalents([]);
        setPage(1);
    };

    //console.log('SideTalentPicker', props);
    const pagination = false;

    function getTalents() {
        let params = [];
        //let urlParms = "page=" + page + "&or=1&np=1";
        let urlParms = pagination ? "page=" + page + "&or=1" : "np=1&or=1";
        urlParms += "&rs=" + props.ruleset;
        if (typeFilter.length > 0)
            urlParms += "&tp=" + typeFilter;
        if (gradeFilter > 0)
            urlParms += "&gd=" + gradeFilter;
        if (textFilter.length > 0)
            urlParms += "&q=" + textFilter;
        props.wsCall(
            urlParms.length > 0 ? "talents?" + urlParms : "talents",
            "GET",
            isLoading,
            setIsLoading,
            //netError,
            setNetError,
            params,
            (respData) => {
                console.log("get talents", respData);
                // handle get order detail response
                if (respData.status === true) {
                    if (pagination) {
                        setTalents(talents => [...talents, ...respData.data.data]);
                        setPage((respData.data.current_page + 1));
                        setTotal(respData.data.total);
                    } else {
                        setTalents(talents => [...talents, ...respData.data]);
                        setPage(1);
                        setTotal(respData.data.length);
                    }
                } else {
                    console.log("Failed: " + respData.message);
                }
            }
        );
    }

    const renderTalents = () => {
        if (talents.length > 0) {
            return talents.map((talent) => {
                let type = "";
                let t = props.talentTypes.filter((el) => el.id === talent.type);
                if (t.length > 0)
                    type = t[0].name;
                let grade = "";
                if (talent.grade === 2)
                    grade = " (II)";
                if (talent.grade === 3)
                    grade = " (III)";
                //t = props.talentGrades.filter((el) => el.id === talent.grade);
                //if (t.length > 0)
                //grade = t[0].name;
                return (
                    <Stack direction='row' justifyContent="left" key={'tacc_' + talent.id}>
                        <Box>
                            <IconButton size="small" onClick={() => props.addTalent(talent)}>
                                <LoupeIcon />
                            </IconButton>
                        </Box>
                        <Accordion
                            expanded={expanded === 'panel' + talent.id}
                            onChange={handleChange('panel' + talent.id)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id={"panel-header" + talent.id}
                            >
                                <Typography sx={{ textAlign: 'left', width: '80%', flexShrink: 0 }}>
                                    {talent.name + grade}
                                </Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{talent.cost + " p."}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography align='justify' gutterBottom>
                                    {talent.prerequisites && talent.prerequisites.length > 2 ? "Prerequisiti: " + talent.prerequisites : "Prerequisiti: nessuno"}
                                </Typography>
                                {
                                    talent.limitations && talent.limitations.length > 0 ? (
                                        <Typography align='justify' gutterBottom>
                                            {"Limitazioni: " + talent.limitations.replace(/↵/g, "\n")}
                                        </Typography>

                                    ) : null
                                }
                                <Typography sx={{ textAlign: 'justify', color: 'text.secondary' }}>
                                    {talent.description.replace(/↵/g, "\n")}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                );
            });
        }
    }


    const content = (anchor) => (
        <Box
            sx={{ width: drawerWidth }}
            role="presentation"
            //onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Stack
                direction='row'
                sx={{ backgroundColor: 'lightblue', display: 'flex', alignItems: 'center' }}
            >
                <IconButton onClick={toggleDrawer(anchor, false)}>
                    <CloseIcon />
                </IconButton>
                <Box sx={{ width: '100%' }}>
                    <Typography variant='h6' textAlign='center'>
                        Scelta Talenti
                    </Typography>
                </Box>
            </Stack>
            <Stack
                direction="column"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: '10px' }}
            >
                <MUISelectField
                    required={false}
                    lbl="Tipo"
                    name="type"
                    value={typeFilter}
                    options={props.talentTypes}
                    emptyOption={[0, " "]}
                    change={changeFilterHandler}
                    composeText={(el) => { return el.name; }}
                    valName="id"
                    cssClass="input_el"
                    minWidth="240px"
                    errList={[]}
                />
                <MUISelectField
                    required={false}
                    lbl="Grado"
                    name="grade"
                    value={gradeFilter}
                    options={props.talentGrades}
                    emptyOption={[0, " "]}
                    change={changeFilterHandler}
                    composeText={(el) => { return el.name; }}
                    valName="id"
                    cssClass="input_el"
                    minWidth="240px"
                    errList={[]}
                />
                <Stack
                    direction="row"
                    justifyContent="center"
                    spacing={1}
                    sx={{ marginTop: '10px' }}
                >
                    <MUITextField
                        required={false}
                        lbl="Cerca"
                        name="searchText"
                        value={textFilter}
                        change={changeFilterHandler}
                        cssClass="input_el"
                        minWidth="160px"
                        errList={[]}
                        onenter={() => { setTalents([]); getTalents(); }}
                    />
                    <IconButton onClick={() => { setTalents([]); getTalents(); }}>
                        <SearchIcon />
                    </IconButton>
                </Stack>
            </Stack>

            <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
            <Stack direction='column' style={{ maxHeight: '65vh', overflow: 'auto' }}>
                {renderTalents()}
            </Stack>
            <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
            {isLoading === true ? (
                <Typography textAlign='center' >Loading..</Typography>
            ) :
                pagination && talents.length < total ? (
                    <Button onClick={() => getTalents()}>Carica</Button>
                ) : (
                    <Typography textAlign='center'>{endMessage}</Typography>
                )}
        </Box>
    );

    return (
        <React.Fragment key={anchor}>
            <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
            >
                {content(anchor)}
            </Drawer>
        </React.Fragment>
    )

}

export default TalentPickerDrawer;
