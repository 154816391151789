import React, { /*useState, useEffect,*/ useContext } from "react";
//import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Typography, Container } from "@mui/material";
import { UserContext } from "../Components/UserContext";
import RulesetView from '../Components/RulesetView.tsx';

function RulesetPage(props) {
  // Sets initial state for skill list to empty array
  //const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const rulesets = [
    {
      id: 'r_f',
      name: 'Rapido & Furioso',
      files: [
        {
          path: "R&F_D12_Regolamento.pdf",
          text: "Regolamento",
          date: "2023-01-14"
        },
        {
          path: "R&F_D12_Talenti_avanzati.pdf",
          text: "Talenti",
          date: "2021-11-29"
        },
        {
          path: "R&F_D12_Talenti_arcani.pdf",
          text: "Talenti arcani",
          date: "2024-01-27"
        },
        {
          path: "R&F_D12_Equipaggiamento.pdf",
          text: "Equipaggiamento",
          date: "2021-11-29"
        },
        {
          path: "R&F_D12_Poteri_sovrannaturali.pdf",
          text: "Poteri sovrannaturali",
          date: "2024-04-07"
        },
        {
          path: "R&F_D12_Foci_e_rituali_arcani.pdf",
          text: "Foci e rituali arcani",
          date: "2020-02-18"
        },
        {
          path: "R&F_D12_Talenti_razziali.pdf",
          text: "Talenti razziali",
          date: "2023-09-17"
        },
        {
          path: "R&F_Bestiario.pdf",
          text: "Bestiario",
          date: "2024-02-29"
        },
      ]
    },
    {
      id: 'r_fmod',
      name: 'Rapido & Furioso modificato',
      files: []
    },
    {
      id: 'tcsf',
      name: 'Sci-Fi',
      files: []
    }


  ];

  let rset = rulesets.filter((el) => el.id === props.id);

  return (
    <>
      <Helmet>
        <title>{rset[0].name}</title>
      </Helmet>

      <main className="pg_container">
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {rset[0].name}
          </Typography>

        </Container>
        <Container maxWidth="lg">
           <RulesetView
            id={rset[0].id}
            name={rset[0].name}
            files={rset[0].files}
            skills={props.skills.filter((sk) => sk.ruleset === rset[0].id)}
            path="/dloads/"
            cfg={props.cfg}
            wsCall={props.wsCall}
          />
        </Container>
     </main>
    </>
  );
}

export default RulesetPage;
