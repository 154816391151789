import React, { useState, useEffect } from "react";
//import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Container,
  Divider,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import MUISelectField from "../Components/MUISelectField";

function SkillsPage(props) {
  // Sets initial state for skill list to empty array
  const [skills, setSkills] = useState(props.skills);
  const [rulesetFilter, setRulesetFilter] = useState("");

  useEffect(() => {
    if (rulesetFilter.length === 0) setSkills(props.skills);
    else setSkills(props.skills.filter((sk) => sk.ruleset === rulesetFilter));
  }, [rulesetFilter]);
/*
  useEffect(() => {
    console.log('... SCROLL ... ', skills.length);
    window.scrollBy({
        top: 500,
        //left: 100,
        behavior: "auto"
    });
  }, [skills]);
*/
/*
  useEffect(() => {
    let div = document.querySelector('body');
    if (div) {
        console.log('......... SCROLLDIV ......... ', skills.length, div);
        div.scrollBy({ top: 500, left: 0, behavior: 'auto'});
    }

  }, [skills]);
*/
  

  function changeFilterHandler(name, value) {
    if (name === "ruleset") setRulesetFilter(value);
  }

  // Render the skills
  const renderSkills = () => {
    if (skills.length > 0) {
      return skills.map((skill) => {
        if (skill.active === 1) {
          let rulesetName = "";
          let t = props.cfg.rulesetList.filter((el) => el.id === skill.ruleset);
          if (t.length > 0) rulesetName = t[0].name;
          let typeName = "";
          t = props.cfg.skillTypes.filter((el) => el.id === skill.type);
          if (t.length > 0) typeName = t[0].name;
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{ display: "flex" }}
              key={"ch_" + skill.id}
            >
              <Card className="skillCard">
                <CardContent align="left">
                  <Typography
                    variant="subtitle1"
                    //variant='h6'
                    gutterBottom
                  >
                    {skill.name}
                  </Typography>
                  <Divider light={true} sx={{ marginBottom: "6px" }}></Divider>
                  <Typography>{rulesetName}</Typography>
                  <Typography>Costo base: {skill.cost}</Typography>
                  <Typography>Tipo: {typeName}</Typography>
                  <Typography
                    style={{ whiteSpace: "pre-line" }}
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    align='justify'
                  >
                    {skill.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Typography align="center">Nessuna abilità trovata</Typography>
        </Grid>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Elenco abilità</title>
      </Helmet>

      <main className="pg_container" >
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Abilità
          </Typography>
          <Typography align="center" color="textPrimary" gutterBottom>
            Filtra per:
          </Typography>
          <Container className="filters_container">
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ display: "flex" }}
                key={"f1"}
                justifyContent="center"
              >
                <MUISelectField
                  required={false}
                  lbl="Sistema di gioco"
                  name="ruleset"
                  value={rulesetFilter}
                  options={props.cfg.rulesetList}
                  emptyOption={["", " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="210px"
                  errList={[]}
                />
              </Grid>
            </Grid>
          </Container>
        </Container>

        <Container maxWidth="md" className="characters_container">
          <Grid container spacing={4}>
            {renderSkills()}
          </Grid>
        </Container>
      </main>
    </>
  );
}

export default SkillsPage;
