export default class BasicTalent {
    id = 0;
    name = "";
    description = "";
    prerequisites = "";
    limitations = "";
    type = 'starting';
    grade = 1;
    ruleset = 'r_f';
    cost = 0;
    talent_id = 0;  // id talento prerequisito
    multipick = 0;

    constructor(type = 'starting', grade = 1, ruleset = 'r_f', cost = 0) {
        this.type = type;
        this.grade = grade;
        this.ruleset = ruleset;
        this.cost = cost;
    }
}