import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { Typography, Stack, Grid, IconButton, Container, InputLabel } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
//import EditIcon from '@mui/icons-material/Edit';
//import EditNoteIcon from '@mui/icons-material/EditNote';
//import VisibilityIcon from '@mui/icons-material/Visibility';
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
//import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import MUISpeedDialMenu from '../Components/MUISpeedDialMenu';
import MUISelectField from '../Components/MUISelectField';
import MUITextField from '../Components/MUITextField';
import CharacterCard from "../Components/CharacterCard.tsx";
//import CircledText from '../Components/CircledText';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserContext } from '../Components/UserContext';

function CharactersPage(props) {
    // Sets initial state for skill list to empty array
    //let location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [IsLoading, setIsLoading] = useState(false);
    const [netError, setNetError] = useState(null);
    const [characters, setCharacters] = useState([]);
    const [rulesetFilter, setRulesetFilter] = useState(initFilter('rs', props.cfg.rulesetList));
    const [archetypeFilter, setArchetypeFilter] = useState(initFilter('ar', props.cfg.archetypeList, true));
    const [genderFilter, setGenderFilter] = useState(initFilter('gn', props.cfg.genderList));
    const [textFilter, setTextFilter] = useState(searchParams.get('q')? searchParams.get('q') : "");
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const endMessage = total < 0 ? "premi Invio per iniziare la ricerca" : "Caricamento completato."; 
    const actions = [

    ];
    if (user.auth === true) {
        actions.push({ icon: <PersonAddIcon />, name: 'Crea nuovo', action: () => navigate('/personaggi/nuovo') });
    }

    function initFilter(parname, valueArray, intvalue = false) {
        let val = searchParams.get(parname);
        if (intvalue === true)
            val = parseInt(val);
        let obj = valueArray.find(el => el.id === val);
        if (obj && obj.id)
            return obj.id;
        else 
            return "";
    }

    function changeFilterHandler(name, value) {
        if (name === 'searchText') {
            setTextFilter(value);
            setTotal(-1);   // per differenziare il messaggio di terminazione scroll infinito
        } else {
            if (name === 'ruleset')
                setRulesetFilter(value);
            if (name === 'archetype')
                setArchetypeFilter(value);
            if (name === 'gender')
                setGenderFilter(value);
            setTotal(0);
        }
        setCharacters([]);
        setPage(1);
    };
    
    // paged version
    const  getCharacters = useCallback(() => {
        let params = [];
        let urlParms = "page=" + page;
        if (rulesetFilter.length > 0)
            urlParms += "&rs=" + rulesetFilter;
        if (archetypeFilter > 0)
            urlParms += "&ar=" + archetypeFilter;
        if (genderFilter.length > 0)
            urlParms += "&gn=" + genderFilter;
        if (textFilter.length > 0)
            urlParms += "&q=" + textFilter;
        props.wsCall(
            urlParms.length > 0 ? "characters?" + urlParms : "characters",
            "GET",
            IsLoading,
            setIsLoading,
            //netError,
            setNetError,
            params,
            (respData) => {
                if (props.cfg.debug === true) {
                    console.log("get characters!", respData);
                }
                // handle get characters response
                if (respData.status === true) {
                    //console.log('respData.data.data', respData.data.data)
                    setCharacters(characters => [...characters, ...respData.data.data]);
                    setPage((respData.data.current_page + 1));
                    setTotal(respData.data.total);
                } else {
                    console.log("Failed: " + respData.message);
                }
            }
        );
    },[IsLoading, archetypeFilter, genderFilter, rulesetFilter, page, props, textFilter]);

    useEffect(() => {
        //console.log('AHA!!!')
        getCharacters();
    }, [rulesetFilter, archetypeFilter, genderFilter]);

    //console.log('---- characters ', characters);
    //const classes = useStyles();

    if (characters.length === 0 && IsLoading)
        return (
            <div className="spinner">
                <img src="/images/waiting.gif" alt="loading..." />
            </div>
        );

    return (
        <>
            <Helmet>
                <title>Elenco personaggi</title>
            </Helmet>

            <MUISpeedDialMenu
                //icon={<SpeedDialIcon />}
                actions={actions}
            />

            <main className='pg_container'>
                <Container maxWidth="md" className='title_container'>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom >
                        Personaggi
                    </Typography>
                    <Typography align="center" color="textPrimary" gutterBottom>
                        Filtra per:
                    </Typography>
                    <Container className='filters_container'>
                        <Grid container spacing={4} >
                            <Grid item xs={12} sm={6} md={3} lg={3} key={'f1'}>
                                <MUISelectField
                                    required={false}
                                    lbl="Sistema di gioco"
                                    name="ruleset"
                                    value={rulesetFilter}
                                    options={props.cfg.rulesetList}
                                    emptyOption={["", " "]}
                                    change={changeFilterHandler}
                                    composeText={(el) => { return el.name; }}
                                    valName="id"
                                    cssClass="input_el"
                                    minWidth="160px"
                                    errList={[]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} key={'f2'}>
                                <MUISelectField
                                    required={false}
                                    lbl="Archetipo"
                                    name="archetype"
                                    value={archetypeFilter}
                                    options={props.cfg.archetypeList}
                                    emptyOption={[0, " "]}
                                    change={changeFilterHandler}
                                    composeText={(el) => { return el.name; }}
                                    valName="id"
                                    cssClass="input_el"
                                    minWidth="160px"
                                    errList={[]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} key={'f3'}>
                                <MUISelectField
                                    required={false}
                                    lbl="Genere"
                                    name="gender"
                                    value={genderFilter}
                                    options={props.cfg.genderList}
                                    emptyOption={["", " "]}
                                    change={changeFilterHandler}
                                    composeText={(el) => { return el.name; }}
                                    valName="id"
                                    cssClass="input_el"
                                    minWidth="160px"
                                    errList={[]}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} sx={{ display: 'flex' }} key={'f4'}>
                                <Stack direction="column" justifyContent="center" sx={{ marginTop: '-10px' }}>
                                    <InputLabel id={"searchText_label"}>
                                        Testo
                                    </InputLabel>
                                    <Stack direction="row" spacing={1} >
                                        <MUITextField
                                            required={false}
                                            lbl="Cerca"
                                            name="searchText"
                                            value={textFilter}
                                            change={changeFilterHandler}
                                            cssClass="input_el"
                                            minWidth="160px"
                                            errList={[]}
                                            onenter={() => {getCharacters()}}
                                        />
                                        <IconButton onClick={() => {getCharacters()}}>
                                            <SearchIcon variant='outlined' />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Container>

                <InfiniteScroll
                    dataLength={characters.length}
                    next={getCharacters}
                    hasMore={characters.length >= total ? false : true}
                    loader={<Typography sx={{ marginTop: '20px' }} gutterBottom>Loading...</Typography>}
                    endMessage={<Typography sx={{ marginTop: '20px', marginBottom: '20px' }} >{endMessage}</Typography>}
                    //style={{ width: '100%' }}
                >
                    <Container maxWidth="md" className='characters_container'>
                        <Grid container spacing={2} >
                            {
                            //renderCharacters()
                            characters.map((el) => (
                                <CharacterCard
                                    rulesets={props.cfg.rulesetList}
                                    archetypes={props.cfg.archetypeList}
                                    character={el}
                                    editable={user.id === el.user_id || user.level > 1 ? true: false}
                                    key={'chcd_'+el.id}
                                />
                            ))
                            }
                        </Grid>
                    </Container>
                </InfiniteScroll>

            </main>


        </>

    );
}

export default CharactersPage;
