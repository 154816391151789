import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, CardContent, Container } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddCardIcon from "@mui/icons-material/AddCard";
//import EditIcon from '@mui/icons-material/Edit';
import MUISpeedDialMenu from "./MUISpeedDialMenu";
import MUITextField from "./MUITextField";
import MUITextAreaField from "./MUITextAreaField";
import MUISelectField from "./MUISelectField";
import MUISwitchField from "./MUISwitchField";
//import MUINumberField from './MUINumberField';
//import MUISwitchField from './MUISwitchField';
import isEmail from "validator/lib/isEmail";
import OkPopup from "./OkPopup";
import ConfirmPopup from "./ConfirmPopup";
import BasicTalent from "../Tools/BasicTalent";
import "./TalentForm.css";

function TalentForm(props) {
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  // talent data
  const [State, setState] = useState(props.talent);
  //const [maxStat, setMaxStat] = useState(props.character.archety)

  const [ErrorList, setErrorList] = useState([]);
  const [Message, setMessage] = useState("");
  const [OutcomeTitle, setOutcomeTitle] = useState("");
  const [OutcomeMessage, setOutcomeMessage] = useState("");
  const [ConfirmDelete, setConfirmDelete] = useState(false);

  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  //console.log('talentForm::talent', props.talent);

  function changeStateHandler(name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function checkTextValue(txt, special = "") {
    //console.log(fieldName + ': ' + txt);
    if (txt === null || txt.trim().length === 0)
      return "Il campo non può essere vuoto";
    if (special === "email") {
      if (!isEmail(txt)) return "Indirizzo email non valido";
    } else if (special === "posint") {
      let val = parseInt(txt);
      if (isNaN(val)) return "Il valore inserito deve essere un numero >= 0";
      if (val < 0) return "Il valore inserito deve essere un numero >= 0";
    }
    return "";
  }

  function formValidation() {
    var errList = [];
    let res = "";

    res = checkTextValue(State.name);
    if (res !== "") errList.push({ id: "name", msg: res });
    res = checkTextValue(State.description);
    if (res !== "") errList.push({ id: "description", msg: res });
    res = checkTextValue(State.cost + "", "posint");
    if (res !== "") errList.push({ id: "cost", msg: res });

    //    res = checkTextValue(State.prerequisites);
    //    if (res !== "") errList.push({ id: "race", msg: res });

    return errList;
  }

  function resetForm() {
    if (State.id > 0) {
      setState(props.talent);
    } else {
      let chr = new BasicTalent(State.type, 1, State.ruleset, 0);
      //chr.id = State.id;
      setState(chr);
    }
  }

  const sendFormHandler = () => {
    var errList = formValidation();

    setErrorList(errList);
    //console.log(errList);

    if (errList.length > 0) {
      setMessage("Dati non corretti");
      return;
    } else {
      setMessage("");
    }

    // validation ok, submit subscription

    // NOTE: Laravel ha problemi con le chiamate PUT (e PATCH)
    // per farlo funzionare occorre fare comunque una chiamata POST,
    // aggiungendo un parametro "_method: PUT"
    //console.log(obj);
    let params = [
      { name: "id", value: State.id },
      { name: "name", value: State.name },
      { name: "description", value: State.description },
      { name: "prerequisites", value: State.prerequisites },
      { name: "limitations", value: State.limitations },
      { name: "type", value: State.type },
      { name: "grade", value: State.grade },
      { name: "ruleset", value: State.ruleset },
      { name: "cost", value: State.cost },
      { name: "multipick", value: State.multipick },
      { name: "talent_id", value: State.talent_id },
    ];
    let url = props.wsUrl;
    if (State.id > 0) {
      // update (simulare il PUT per Laravel)
      params.push({ name: "_method", value: "PUT" });
      url += "/" + State.id;
    }

    props.wsCall(
      url,
      "POST", //State.id > 0 ? "PUT" : "POST", problemi con Lavarel API
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (result) => {
        if (result.status === true) {
          // operation successful
          setOutcomeTitle("Salvataggio riuscito");
          setOutcomeMessage(result.message);
          if (result.redirect && result.redirect.length > 0) {
            navigate(result.redirect);
          }
          //setState(result.data);
          //resetForm();
        } else {
          // processing error
          setOutcomeTitle("Salvataggio fallito");
          setOutcomeMessage(result.message);
          if (Object.keys(result.data).length > 0) {
            // form error list (should not happen, since we check it client side)
            var errList = [];
            Object.entries(result.data).forEach(([key, val]) => {
              errList.push({ id: key, msg: val });
            });
            //console.log('err');
            //console.log(errList);
            setErrorList(errList);
          }
        }
        setShowDialog(true);
        //console.log(result);
      }
    );
  };

  const deleteTalentHandler = () => {
    let params = [];

    props.wsCall(
      props.wsUrl + "/" + State.id,
      "DELETE", //State.id > 0 ? "PUT" : "POST", problemi con Lavarel API
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (result) => {
        if (result.status === true) {
          // operation successful
          setOutcomeTitle("Cancellazione riuscita");
          setOutcomeMessage(result.message);
          resetForm();
          setRedirect(true);
        } else {
          // processing error
          setOutcomeTitle("Cancellazione fallita");
          setOutcomeMessage(result.message);
          if (Object.keys(result.data).length > 0) {
            // form error list (should not happen, since we check it client side)
            var errList = [];
            Object.entries(result.data).forEach(([key, val]) => {
              errList.push({ id: key, msg: val });
            });
            //console.log('err');
            //console.log(errList);
            setErrorList(errList);
          }
        }
        setShowDialog(true);
        //console.log(result);
      }
    );
  };

  const closeDialog = () => {
    if (redirect === true) {
      navigate(props.redirectUrl);
    } else {
      setShowDialog(false);
      setNetError(null);
      setMessage(null);
    }
  };

  const handleConfirmResponse = (bResponse) => {
    if (bResponse === true) {
      deleteTalentHandler();
    }
    setConfirmDelete(false);
  };

  function deleteHandler() {
    setConfirmDelete(true);
  }

  //const [open, setOpen] = useState(false);
  //const handleOpen = () => setOpen(true);
  //const handleClose = () => setOpen(false);
  //const [autoGenerator, setAutogenerator] = useState(false);
  //const toggleGenerator = () => setAutogenerator(!autoGenerator);
  const actions = [
    {
      icon: <ArrowBackIosIcon />,
      name: "Indietro",
      action: () => navigate(props.backUrl),
    },
    {
      icon: <AddCardIcon />,
      name: "Nuovo",
      action: () => navigate("/talenti/nuovo"),
    },
    { icon: <RefreshIcon />, name: "Ricarica", action: resetForm },
    { icon: <SaveIcon />, name: "Salva", action: sendFormHandler },
    { icon: <DeleteIcon />, name: "Elimina", action: deleteHandler },
  ];

  return (
    <>
      {IsLoading ? (
        <div className="spinner">
          <img src="/images/waiting.gif" alt="loading..." />
        </div>
      ) : null}
      {showDialog ? (
        <OkPopup
          titletext={OutcomeTitle}
          text={OutcomeMessage}
          closeHandler={closeDialog}
        />
      ) : null}
      {netError ? (
        <OkPopup
          titletext={/*props.lang.LBL_ERROR*/ "Errore"}
          text={netError}
          closeHandler={closeDialog}
        />
      ) : null}
      {Message ? (
        <OkPopup
          titletext={"Avviso"}
          text={Message}
          closeHandler={closeDialog}
        />
      ) : null}
      {ConfirmDelete ? (
        <ConfirmPopup
          titletext={"Attenzione"}
          text="Vuoi procedere con la cancellazione di questo talento?"
          responseHandler={handleConfirmResponse}
        />
      ) : null}

      <MUISpeedDialMenu actions={actions} myVariant="crud" />

      <Container maxWidth="lg" className="talent_data">
        <Grid container spacing={0} justifyContent="flex-end">
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            sx={{ display: "flex" }}
            key={"col1"}
            justifyContent="right" /*style={{ backgroundColor: 'cyan' }}*/
          >
            <CardContent
              align="center"
              className="talent_col_01"
              style={{
                border: "1px solid #d0d7de",
                boxShadow: "0px 2px 2px #f6f8fa",
              }}
            >
              <MUITextField
                required={true}
                type="text"
                lbl="Nome talento"
                name="name"
                value={State.name}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
                maxlen={255}
              />
              <MUITextField
                required={true}
                //type="number"
                type="text"
                lbl="Costo"
                name="cost"
                value={State.cost}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
              />
              <MUISelectField
                required={true}
                lbl="Tipo"
                name="type"
                value={State.type}
                options={props.talentTypes}
                emptyOption={[]}
                change={changeStateHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
              />
              <MUISelectField
                required={false}
                lbl="Grado"
                name="grade"
                value={State.grade}
                options={props.talentGrades}
                //emptyOption={[0, " "]}
                emptyOption={[]}
                change={changeStateHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
              />
              <MUISelectField
                required={true}
                lbl="Sistema di gioco"
                name="ruleset"
                value={State.ruleset}
                options={props.rulesetList}
                //emptyOption={["", ""]}
                emptyOption={[]}
                change={changeStateHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
                readonly={State.id > 0 ? true : false}
              />
              <MUISwitchField
                required={true}
                lbl="Selezionabile più volte"
                name="multipick"
                value={State.multipick}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                placement="start"
              />
            </CardContent>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={8}
            sx={{ display: "flex" }}
            justifyContent="center"
            key={"col2"}
          >
            <CardContent
              align="center"
              className="talent_col_02"
              style={{ minWidth: "100%" }}
            >
              <MUITextAreaField
                required={false}
                lbl="Prerequisiti"
                name="prerequisites"
                value={State.prerequisites}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                minRows={2}
                maxRows={4}
              />
              <MUITextAreaField
                required={false}
                lbl="Limitazioni"
                name="limitations"
                value={State.limitations}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                minRows={2}
                maxRows={4}
              />
              <MUITextAreaField
                required={true}
                lbl="Descrizione"
                name="description"
                value={State.description}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                minRows={9}
                maxRows={12}
              />
            </CardContent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default TalentForm;
