import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Typography, Button, Grid, Card, CardActions, CardContent, Stack, CardMedia, Container, Divider } from "@mui/material";
import { Helmet } from 'react-helmet-async';
import MUISelectField from '../Components/MUISelectField';

function SkillsPage(props) {
    // Sets initial state for skill list to empty array
    //const [skills, setSkills] = useState(props.skills);
    //const [rulesetFilter, setRulesetFilter] = useState("");

    /*


    */


    return (
        <>
            <Helmet>
                <title>Orremdi RPG Tools</title>
            </Helmet>

            <main className='pg_container' >
                <Container maxWidth="md" className='title_container'>
                    <Typography variant="h4" align="center" color="textPrimary" gutterBottom >
                        Strumenti per GDR Orremdi e oltre
                    </Typography>
                    <Container maxWidth="lg" className='home_container' >
                        <Grid container spacing={0} >

                            <Grid item xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex' }} key={'f2'}>
                                <CardMedia
                                    component="img"
                                    //height="194"
                                    image={"/images/orry_2880x1620.jpg"}
                                    alt="strumenti per gdr"
                                />
                            </Grid>

                            <Typography variant="h6">
                                Gli strumenti necessari per creare i personaggi e gestire le campagne dei nostri Giochi di Ruolo preferiti.
                            </Typography>

                        </Grid>
                    </Container>
                </Container>

            </main>
        </>

    );

}

export default SkillsPage;
