import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  CardContent,
  Stack,
  Container,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
//import BuildIcon from '@mui/icons-material/Build';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ComputerIcon from "@mui/icons-material/Computer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import MUISpeedDialMenu from "./MUISpeedDialMenu";
import MUITextField from "./MUITextField";
import MUITextAreaField from "./MUITextAreaField";
import MUISelectField from "./MUISelectField";
import MUINumberField from "./MUINumberField";
//import MUINumberFieldAdv from './MUINumberFieldAdv';
import MUISwitchField from "./MUISwitchField";
import CircledText from "./CircledText";
import isEmail from "validator/lib/isEmail";
import OkPopup from "./OkPopup";
import ConfirmPopup from "./ConfirmPopup";
//import SideCmdPanel from './SideCmdPanel';
//import SideTalentPicker from './SideTalentPicker';
import TalentPickerDrawer from "./TalentPickerDrawer";
import Snackbar from "@mui/material/Snackbar";
import BuildPopup from "./BuildPopup";
import BasicCharacter from "../Tools/BasicCharacter";
import TalentVariantPopup from "./TalentVariantPopup";
import "./CharacterForm.css";

function CharacterForm(props) {
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  // character data
  const [State, setState] = useState(props.character);
  const [Stats, setStats] = useState(props.character.stats);
  const [Skills, setSkills] = useState(props.character.skills);
  const [Talents, setTalents] = useState(
    props.character.talents ? props.character.talents : []
  );
  //const [maxStat, setMaxStat] = useState(props.character.archety)
  var maxStat = State.archetype_id === 8 ? 8 : 7;

  const [ErrorList, setErrorList] = useState([]);
  const [Message, setMessage] = useState("");
  const [OutcomeTitle, setOutcomeTitle] = useState("");
  const [OutcomeMessage, setOutcomeMessage] = useState("");
  const [ConfirmDelete, setConfirmDelete] = useState(false);

  const [experienceLevel, setExperienceLevel] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [panelIn, setPanelIn] = useState(false);
  const [talentVariant, setTalentVariant] = useState(null);
  const [snackMsg, setSnackMsg] = useState("");
  const navigate = useNavigate();
  
  function changeStateHandler(name, value) {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function changeStatsHandler(name, value) {
    setStats((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function changeSkillsHandler(name, value) {
    setSkills((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleVariantChange(name, value) {
    setTalentVariant((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  function handleVariantResponse(bval) {
    if (bval === true && talentVariant.variant.length > 0) {
      setTalents((prevTalents) => [...prevTalents, talentVariant]);
      setSnackMsg("Talento aggiunto con successo");
    }
    setTalentVariant(null);
  }
  function addTalentHandler(talent) {
    talent.variant = "";
    if (talent.name.includes("***")) {
      setTalentVariant(talent);
    } else {
      setTalents((prevTalents) => [...prevTalents, talent]);
      setSnackMsg("Talento aggiunto con successo");
    }
  }
  function removeTalentHandler(idx) {
    setTalents((current) => current.filter((talent, index) => index !== idx));
  }

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMsg("");
  };

  const snackAction = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  //console.log("characterForm::character", props.character);
  //console.log('panelIn = ', panelIn);
  //console.log('characterForm::talents', Talents);

  function checkTextValue(txt, special = "") {
    //console.log(fieldName + ': ' + txt);
    if (txt === null || txt.trim().length === 0)
      return "Il campo non può essere vuoto";
    if (special === "email") {
      if (!isEmail(txt)) return "Indirizzo email non valido";
    }
    //if (special === "attribute")

    return "";
  }

  function formValidation() {
    var errList = [];
    let res = "";

    res = checkTextValue(State.name);
    if (res !== "") errList.push({ id: "name", msg: res });
    res = checkTextValue(State.race);
    if (res !== "") errList.push({ id: "race", msg: res });
    //res = checkTextValue(State.description);
    //if (res !== "") errList.push({ id: "description", msg: res });

    return errList;
  }

  function resetForm() {
    if (State.id === 0) {
      let chr = new BasicCharacter(2, State.ruleset);
      chr.user_id = State.user_id;
      setState(chr);
      setStats(chr.stats);
      setSkills(chr.skills);
    } else {
      setState(props.character);
      setStats(props.character.stats);
      setSkills(props.character.skills);
    }
  }

  function generateCharacterHandler() {
    let chr = new BasicCharacter(2, State.ruleset);
    chr.user_id = State.user_id;
    chr.gender = State.gender;
    setErrorList([]);
    try {
      if (State.archetype_id === 0 || State.archetype_id === 8)
        throw new Error(
          "Devi selezionare un archetipo diverso da Creatura per generare automaticamente un personaggio."
        );
      chr.randomCharacter(State.ruleset, State.archetype_id, experienceLevel);
      //console.log('generated character', chr);
    } catch (error) {
      setShowDialog(true);
      setOutcomeTitle("Errore");
      setOutcomeMessage(error.message);
      setErrorList([
        { id: "archetype_id", msg: "Seleziona un Archetipo valido" },
      ]);
      return;
    }
    //console.log('state.id = '+State.id);
    if (State.id > 0) {
      // stiamo generando un personaggio già esistente. Modifichiamo quello, conserviamo alcuni dati
      chr.id = State.id;
      chr.name = State.name;
      chr.description = State.description;
    }
    setState(chr);
    setStats(chr.stats);
    setSkills(chr.skills);
  }
  function generateNotes() {
    let chr = new BasicCharacter(2, State.ruleset);
    chr.user_id = State.user_id;
    chr.gender = State.gender;
    changeStateHandler('notes', chr.generateNotes())
  }

  //const { wsCall } = props;
  const sendFormHandler = () => {
    var errList = formValidation();

    setErrorList(errList);
    //console.log(errList);

    if (errList.length > 0) {
      setMessage("Dati non corretti");
      return;
    } else {
      setMessage("");
    }

    // validation ok, submit subscription
    // build character object
    let character = State;
    character.stats = Stats;
    character.skills = Skills;
    character.talents =
      Talents.length > 0
        ? Talents.map((el) => {
          return {
            id: el.id,
            variant: el.variant
              ? el.variant
              : el.pivot
                ? el.pivot.variant
                : "",
          };
        })
        : [];
    //console.log('... talents', Talents, character.talents);

    // NOTE: Laravel ha problemi con le chiamate PUT (e PATCH)
    // per farlo funzionare occorre fare comunque una chiamata POST,
    // aggiungendo un parametro "_method: PUT"
    //console.log(obj);
    let jsonString = JSON.stringify(character);
    let params = [{ name: "data_obj", value: jsonString }];
    let url = props.wsUrl;
    if (State.id > 0) {
      // update (simulare il PUT per Laravel)
      params.push({ name: "_method", value: "PUT" });
      url += "/" + State.id;
    }

    props.wsCall(
      url,
      "POST", //State.id > 0 ? "PUT" : "POST", problemi con Lavarel API
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (result) => {
        if (result.status === true) {
          // operation successful
          setOutcomeTitle("Salvataggio riuscito");
          setOutcomeMessage(result.message);
          if (result.redirect && result.redirect.length > 0) {
            navigate(result.redirect);
          }
          //setState(result.data);
          //resetForm();
        } else {
          // processing error
          setOutcomeTitle("Salvataggio fallito");
          setOutcomeMessage(result.message);
          if (result.data && Object.keys(result.data).length > 0) {
            // form error list (should not happen, since we check it client side)
            let errList = [];
            Object.entries(result.data).forEach(([key, val]) => {
              errList.push({ id: key, msg: val });
            });
            //console.log('err');
            //console.log(errList);
            setErrorList(errList);
          }
        }
        setShowDialog(true);
        //console.log(result);
      }
    );
  };

  const deleteCharacterHandler = () => {
    let params = [];

    props.wsCall(
      props.wsUrl + "/" + State.id,
      "DELETE", //State.id > 0 ? "PUT" : "POST", problemi con Lavarel API
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (result) => {
        if (result.status === true) {
          // operation successful
          setOutcomeTitle("Cancellazione riuscita");
          setOutcomeMessage(result.message);
          resetForm();
          setRedirect(true);
        } else {
          // processing error
          setOutcomeTitle("Cancellazione fallita");
          setOutcomeMessage(result.message);
          if (Object.keys(result.data).length > 0) {
            // form error list (should not happen, since we check it client side)
            var errList = [];
            Object.entries(result.data).forEach(([key, val]) => {
              errList.push({ id: key, msg: val });
            });
            //console.log('err');
            //console.log(errList);
            setErrorList(errList);
          }
        }
        setShowDialog(true);
        //console.log(result);
      }
    );
  };

  const closeDialog = () => {
    if (redirect === true) {
      navigate(props.redirectUrl);
    } else {
      setShowDialog(false);
      setNetError(null);
      setMessage(null);
    }
  };
  const handleConfirmResponse = (bResponse) => {
    if (bResponse === true) {
      deleteCharacterHandler();
    }
    setConfirmDelete(false);
  };
  const handleExpLevel = (name, level) => {
    setExperienceLevel(level);
  };

  function statCost(val) {
    if (val <= 4) return val;
    if (val === 5) return 6;
    else return 6 + (val - 5) * 3;
  }
  function statsTotalCost() {
    return (
      statCost(Stats.AGI) +
      statCost(Stats.AIM) +
      statCost(Stats.APP) +
      statCost(Stats.CHA) +
      statCost(Stats.CMB) +
      statCost(Stats.CON) +
      statCost(Stats.INT) +
      statCost(Stats.PER) +
      statCost(Stats.POW) +
      statCost(Stats.PRE) +
      statCost(Stats.STR) +
      statCost(Stats.WIL)
    );
  }
  function skillsTotalCost() {
    let sum = 0;
    let rk = 0;
    props.skills.forEach((sk) => {
      if (sk.active === 1 && sk.ruleset === State.ruleset) {
        rk = Skills[sk.code];
        sum += sk.cost * ((rk * (rk + 1)) / 2);
      }
    });
    return sum;
  }

  const statsPts = statsTotalCost();
  const skillsPts = skillsTotalCost();
  let talentsPts = 0;
  Talents.forEach((t) => {
    talentsPts += t.cost;
  });

  function deleteHandler() {
    setConfirmDelete(true);
  }

  const [autoGenerator, setAutogenerator] = useState(false);
  const toggleGenerator = () => setAutogenerator(!autoGenerator);
  const actions = [
    //{ icon: <ArrowBackIosIcon />, name: 'Indietro', action: () => navigate(props.backUrl) },
    {
      icon: <ArrowBackIosIcon />,
      name: "Indietro",
      action: () => navigate(-1),
    },
    {
      icon: <PersonAddIcon />,
      name: "Nuovo",
      action: () => navigate("/personaggi/nuovo"),
    },
    { icon: <RefreshIcon />, name: "Reset", action: resetForm },
    { icon: <SaveIcon />, name: "Salva", action: sendFormHandler },
    { icon: <ComputerIcon />, name: "Genera", action: toggleGenerator },
  ];
  if (State.id > 0) {
    actions.push({
      icon: <DeleteIcon />,
      name: "Elimina",
      action: deleteHandler,
    });
  }
  const buildHandler = (val) => {
    if (val === true) {
      generateCharacterHandler();
    }
    setAutogenerator(false);
  };

  function compareObj(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const settings = props.rulesetList.find((el) => el.id === State.ruleset);

  //console.log('character ', props.character);
  return (
    <>
      {IsLoading ? (
        <div className="spinner">
          <img src="/images/waiting.gif" alt="loading..." />
        </div>
      ) : null}
      {showDialog ? (
        <OkPopup
          titletext={OutcomeTitle}
          text={OutcomeMessage}
          closeHandler={closeDialog}
        />
      ) : null}
      {netError ? (
        <OkPopup
          titletext={/*props.lang.LBL_ERROR*/ "Errore"}
          text={netError}
          closeHandler={closeDialog}
        />
      ) : null}
      {Message ? (
        <OkPopup
          titletext={"Avviso"}
          text={Message}
          closeHandler={closeDialog}
        />
      ) : null}
      {ConfirmDelete ? (
        <ConfirmPopup
          titletext="Attenzione"
          text="Vuoi procedere con la cancellazione di questo personaggio?"
          responseHandler={handleConfirmResponse}
        />
      ) : null}
      {talentVariant ? (
        <TalentVariantPopup
          titletext="Talento multiplo"
          text="Devi indicare la specializzazione, argomento o campo specifico di questo Talento."
          variant={talentVariant.variant}
          change={handleVariantChange}
          responseHandler={handleVariantResponse}
        />
      ) : null}
      {autoGenerator === true ? (
        <BuildPopup
          expSetting={experienceLevel}
          expLevels={props.expLevels}
          experienceHandler={handleExpLevel}
          responseHandler={buildHandler}
        />
      ) : null}
      {snackMsg.length > 0 ? (
        <Snackbar
          open={snackMsg.length > 0}
          autoHideDuration={4000}
          onClose={handleSnackClose}
          message={snackMsg}
          action={snackAction}
        />
      ) : null}

      <MUISpeedDialMenu actions={actions} myVariant="crud" />

      <TalentPickerDrawer
        wsCall={props.wsCall}
        ruleset={props.character.ruleset}
        talentGrades={props.talentGrades}
        talentTypes={props.talentTypes}
        panelIn={panelIn}
        addTalent={addTalentHandler}
        openPanel={setPanelIn}
      />

      <Container
        maxWidth="lg"
        className="character_data" 
      >
        <Grid
          container
          className="character_grid"
          spacing={0}
          alignItems="stretch"
          justifyContent="flex-end"
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            sx={{ display: "flex" }} /*justifyContent="right"*/
          >
            <CardContent className="character_col_01">
              <Typography
                align="center"
                sx={{ fontWeight: "bold" }}
                gutterBottom
              >
                Dati generali
              </Typography>
              <MUITextField
                required={true}
                type="text"
                lbl="Nome"
                name="name"
                value={State.name}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
                maxlen={255}
              />
              <MUITextField
                required={true}
                type="text"
                lbl="Razza"
                name="race"
                value={State.race}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                maxlen={255}
              />
              <MUINumberField
                required={true}
                lbl="Taglia"
                name="size"
                value={State.size}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                min={0}
                max={8}
              />
              <MUISelectField
                required={true}
                lbl="Genere"
                name="gender"
                value={State.gender}
                options={props.genderList}
                //emptyOption={["", ""]}
                emptyOption={[]}
                change={changeStateHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
              //lang={props.lang}
              />

              <MUISelectField
                required={false}
                lbl="Archetipo"
                name="archetype_id"
                value={State.archetype_id}
                options={props.archetypeList}
                //emptyOption={["", ""]}
                emptyOption={[0, " "]}
                change={changeStateHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
              //lang={props.lang}
              />
              <MUISelectField
                required={true}
                lbl="Sistema di gioco"
                name="ruleset"
                value={State.ruleset}
                options={props.rulesetList}
                //emptyOption={["", ""]}
                emptyOption={[]}
                change={State.id > 0 ? null : props.changeRuleset}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
                readonly={State.id > 0 ? true : false}
              />
              <MUISwitchField
                required={true}
                lbl="Pubblico"
                name="public"
                value={State.public}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                placement="start"
              />
              <MUITextAreaField
                required={true}
                type="text"
                lbl="Descrizione"
                name="description"
                value={State.description}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                maxlen={255}
              />
              <MUITextAreaField
                required={true}
                type="text"
                lbl="Note"
                name="notes"
                value={State.notes}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                maxlen={255}
              />
              <Stack direction='row' sx={{float: 'right', marginTop: '-10px', displayPrint: 'none'}}>
                <Typography variant='caption'>Rigenera note</Typography>
                <IconButton
                  size="small"
                  aria-label="rigenera note"
                  color="inherit"
                  onClick={generateNotes}
                  sx={{marginTop: '-6px'}}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Stack>
            </CardContent>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            sx={{ display: "flex" }} /*justifyContent="center"*/
          >
            <Badge
              badgeContent={statsPts}
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: 16,
                  fontWeight: "bold",
                  height: 25,
                  minWidth: 15,
                },
              }}
              max={99999}
            >
              <CardContent align="center" className="character_col_02">
                <Typography
                  align="center"
                  sx={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  Caratteristiche
                </Typography>
                <div className="stats_grid">

                  {
                    Object.keys(Stats).map((key) => 
                      settings.unusedStats.includes(key)?
                      null:
                      <MUINumberField
                        key={key}
                        required={true}
                        lbl={settings.statsNames[key]}
                        name={key}
                        value={Stats[key]}
                        change={changeStatsHandler}
                        cssClass="input_el_02"
                        errList={ErrorList}
                        min={0}
                        max={maxStat}
                      />
                    )
                  }
                  
                </div>
                <Divider sx={{ marginBottom: "10px", marginTop: "10px" }} />
                <Stack direction="row" justifyContent="center">
                  <CircledText
                    label="VC"
                    text={Stats.CMB + Skills.skill_01}
                    noSquare={true}
                  />
                  <CircledText
                    label="VT"
                    text={Stats.AIM + Skills.skill_02}
                    noSquare={true}
                  />
                  <CircledText
                    label="VM"
                    text={
                      State.ruleset === "r_f"
                        ? Stats.INT + Skills.skill_16
                        : Stats.INT + Skills.skill_16
                    }
                    noSquare={true}
                  />
                </Stack>
              </CardContent>
            </Badge>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            sx={{
              display: "flex",
              width: "fit-content",
            }} /*justifyContent="left"*/
          >
            <Badge
              badgeContent={skillsPts}
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: 16,
                  fontWeight: "bold",
                  height: 25,
                  minWidth: 15,
                },
              }}
              max={10000}
            >
              <CardContent align="left" className="character_col_03">
                <Typography
                  align="center"
                  sx={{ fontWeight: "bold" }}
                  gutterBottom
                >
                  Abilità
                </Typography>
                {props.skills.map((sk) =>
                  sk.active === 1 && sk.ruleset === State.ruleset ? (
                    <MUINumberField
                      key={"sk_" + sk.id}
                      required={true}
                      lbl={sk.name}
                      name={sk.code}
                      value={Skills[sk.code]}
                      change={changeSkillsHandler}
                      cssClass="input_el_02"
                      errList={ErrorList}
                      min={0}
                      max={12}
                      right
                    />
                  ) : null
                )}
              </CardContent>
            </Badge>
          </Grid>

          <Grid
            item
            xs={12}
            sm={3}
            md={6}
            lg={3}
            sx={{
              display: "flex",
              width: "fit-content",
            }} /*justifyContent="left"*/
          >
            <Badge
              badgeContent={talentsPts}
              color="primary"
              sx={{
                "& .MuiBadge-badge": {
                  fontSize: 16,
                  fontWeight: "bold",
                  height: 25,
                  minWidth: 15,
                },
              }}
              max={10000}
            >
              <CardContent align="left" className="character_col_04">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ marginTop: "-10px" }}
                >
                  <Typography align="center" sx={{ fontWeight: "bold" }}>
                    Talenti
                  </Typography>
                  <Box>
                    <IconButton
                      aria-label="aggiungi talento"
                      onClick={() => setPanelIn(!panelIn)}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Box>
                </Stack>
                {Talents.sort(compareObj).map((tal, index) => {
                  let grade = "";
                  if (tal.grade === 2) grade = " (II)";
                  if (tal.grade === 3) grade = " (III)";
                  let name = tal.name;
                  if (tal.variant && tal.variant.length > 0) {
                    name = name.replace("***", tal.variant);
                    //console.log('replacing: ' + tal.name + ' , ' + tal.variant +' = ' + name )
                  } else if (tal.pivot && tal.pivot.variant.length > 0) {
                    name = name.replace("***", tal.pivot.variant);
                  }
                  return (
                    <Box
                      direction="row"
                      justifyContent="left"
                      key={"talchr_" + tal.id + "." + index}
                    >
                      <Box
                        sx={{
                          minWidth: "240px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => removeTalentHandler(index)}
                          sx={{ paddingLeft: "0px" }}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                        <Typography
                          sx={{
                            textAlign: "left",
                            width: "80%",
                            flexShrink: 0,
                          }}
                        >
                          {name + grade}
                        </Typography>
                        <Typography
                          sx={{ color: "text.secondary", textAlign: "right" }}
                        >
                          {tal.cost}
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}
              </CardContent>
            </Badge>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CharacterForm;
