import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Grid, CardContent, Container } from "@mui/material";
import MUILabeledText from './MUILabeledText';
import MUITextAreaField from './MUITextAreaField';
import OkPopup from "./OkPopup";
//import BasicTalent from "../Tools/BasicCharacter";
import './TalentForm.css';

function TalentView(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [Message, setMessage] = useState("");
  const [OutcomeTitle, setOutcomeTitle] = useState("");
  const [OutcomeMessage, setOutcomeMessage] = useState("");

  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  //console.log('talentView::talent', props.talent);
  const closeDialog = () => {
    if (redirect === true) {
      navigate(props.redirectUrl);
    } else {
      setShowDialog(false);
      setMessage(null);
    }
  }

  let rulesetName = "";
  let t = props.cfg.rulesetList.filter((el) => el.id === props.talent.ruleset);
  if (t.length > 0)
    rulesetName = t[0].name;
  var typeName = "";
  t = props.cfg.talentTypes.filter((el) => el.id === props.talent.type);
  if (t.length > 0)
    typeName = t[0].name;
  let gradeName = "";
  t = props.cfg.talentGrades.filter((el) => el.id === props.talent.grade);
  if (t.length > 0)
    gradeName = t[0].name;

  if (!props.talent) {
    return (
      <Box>
        <Typography>Talento non trovato</Typography>
      </Box>
    )
  }

  return (
    <>
      {showDialog ? (
        <OkPopup
          titletext={OutcomeTitle}
          text={OutcomeMessage}
          closeHandler={closeDialog}
        />
      ) : null}
      {Message ? (
        <OkPopup
          titletext={"Avviso"}
          text={Message}
          closeHandler={closeDialog}
        />
      ) : null}

      <Container maxWidth="lg" className='talent_data' align='right'>
        <Grid container spacing={0} justifyContent="flex-end" >

          <Grid item xs={12} sm={4} md={4} lg={4} sx={{ display: 'flex' }} key={'col1'} justifyContent="right" /*style={{ backgroundColor: 'cyan' }}*/>
            <CardContent align="center" className='talent_col_01' style={{ border: '1px solid #d0d7de', boxShadow: '0px 2px 2px #f6f8fa' }}>
              <MUILabeledText label="Nome talento" text={props.talent.name} />
              <MUILabeledText label="Costo" text={props.talent.cost} />
              <MUILabeledText label="Tipo" text={typeName} />
              <MUILabeledText label="Grado" text={gradeName} />
              <MUILabeledText label="Regolamento" text={rulesetName} />
            </CardContent>
          </Grid>

          <Grid item xs={12} sm={8} md={8} lg={8} sx={{ display: 'flex' }} justifyContent="center" key={'col2'} >
            <CardContent align="center" className='talent_col_02' style={{ minWidth: '100%' }} >
            <MUITextAreaField
                required={true}
                lbl="Prerequisiti"
                name="prerequisites"
                value={props.talent.prerequisites? props.talent.prerequisites.replace(/↵/g, "\n"): ""}
                change={null}
                cssClass="input_el"
                errList={null}
                minRows={2}
                maxRows={4}
                readnly
              />
              <MUITextAreaField
                required={true}
                lbl="Limitazioni"
                name="limitations"
                value={props.talent.limitations? props.talent.limitations.replace(/↵/g, "\n"): ""}
                change={null}
                cssClass="input_el"
                errList={null}
                minRows={2}
                maxRows={4}
                readnly
              />
              <MUITextAreaField
                required={true}
                lbl="Descrizione"
                name="description"
                value={props.talent.description.replace(/↵/g, "\n")}
                change={null}
                cssClass="input_el"
                errList={null}
                minRows={9}
                maxRows={12}
                readonly
              />
            </CardContent>
          </Grid>


        </Grid>
      </Container>
    </>
  );
}

export default TalentView;