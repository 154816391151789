import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Button,
  Stack,
  Grid,
  Card,
  CardActions,
  CardContent,
  //CardMedia,
  Container,
  Divider,
  InputLabel,
  IconButton,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
//import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from "@mui/icons-material/Visibility";
//import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import MUISpeedDialMenu from "../Components/MUISpeedDialMenu";
import MUISelectField from "../Components/MUISelectField";
import MUITextField from "../Components/MUITextField";
import InfiniteScroll from "react-infinite-scroll-component";
import CharacterCard from "../Components/CharacterCard.tsx";
import { UserContext } from "../Components/UserContext";

function UserCharactersPage(props) {
  // Sets initial state for skill list to empty array
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [characters, setCharacters] = useState([]);
  const [rulesetFilter, setRulesetFilter] = useState("");
  const [archetypeFilter, setArchetypeFilter] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const endMessage =
    total < 0
      ? "premi Invio per iniziare la ricerca"
      : "Caricamento completato.";

  if (!user.auth) {
    navigate("/login");
  }

  const actions = [
    {
      icon: <PersonAddIcon />,
      name: "Crea nuovo",
      action: () => navigate("/personaggi/nuovo"),
    },
  ];

  useEffect(() => {
    getCharacters();
  }, [rulesetFilter, archetypeFilter, genderFilter]);

  function changeFilterHandler(name, value) {
    if (name === "searchText") {
      setTextFilter(value);
      setTotal(-1); // per differenziare il messaggio di terminazione scroll infinito
    } else {
      if (name === "ruleset") setRulesetFilter(value);
      if (name === "archetype") setArchetypeFilter(value);
      if (name === "gender") setGenderFilter(value);
      setTotal(0);
    }
    setCharacters([]);
    setPage(1);
  }

  // paged version
  function getCharacters() {
    let params = [];
    let urlParms = "page=" + page;
    if (rulesetFilter.length > 0) urlParms += "&rs=" + rulesetFilter;
    if (archetypeFilter > 0) urlParms += "&ar=" + archetypeFilter;
    if (genderFilter.length > 0) urlParms += "&gn=" + genderFilter;
    if (textFilter.length > 0) urlParms += "&q=" + textFilter;
    props.wsCall(
      "user/" + user.id + "/characters?" + urlParms,
      "GET",
      IsLoading,
      setIsLoading,
      //netError,
      setNetError,
      params,
      (respData) => {
        if (props.cfg.debug === true) {
          console.log("get characters!", respData);
        }
        // handle get order detail response
        if (respData.status === true) {
          //console.log('respData.data.data', respData.data.data)
          setCharacters((characters) => [...characters, ...respData.data.data]);
          setPage(respData.data.current_page + 1);
          setTotal(respData.data.total);
        } else {
          console.log("Failed: " + respData.message);
        }
      }
    );
  }
/*
  // Render the character list
  const renderCharacters = () => {
    if (characters.length > 0) {
      return characters.map((character) => {
        let ruleset = "";
        let t = props.cfg.rulesetList.filter(
          (el) => el.id === character.ruleset
        );
        if (t.length > 0) ruleset = t[0].name;
        let arch = "";
        t = props.cfg.archetypeList.filter(
          (el) => el.id === character.archetype_id
        );
        if (t.length > 0) arch = t[0].name;
        let gender = "a";
        if (character.gender === "male") gender = "m";
        else if (character.gender === "female") gender = "f";
        else gender = "a";
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={3}
            sx={{ display: "flex" }}
            key={"ch_" + character.id}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                minWidth: "210px",
              }}
            >
              <CardContent align="left">
                <Typography
                  variant="subtitle1"
                  //variant='h6'
                  gutterBottom
                >
                  {character.name}
                </Typography>
                <Divider light={true} sx={{ marginBottom: "6px" }}></Divider>
                <Typography>{character.race + " (" + gender + ")"}</Typography>
                <Typography>{arch}</Typography>
                <Typography>{ruleset}</Typography>
                <Typography>
                  Punti: {character.statPoints + " - " + character.skillPoints + " - " + character.talentPoints}
                </Typography>
              </CardContent>
              <CardActions disableSpacing sx={{ mt: "auto" }}>
                <Button size="sm">
                  <Link to={"/personaggi/" + character.id}>
                    <VisibilityIcon />
                  </Link>
                </Button>
                {user.id === character.user_id || user.level > 1 ? (
                  <Button size="sm">
                    <Link to={"/personaggi/modifica/" + character.id}>
                      <EditIcon />
                    </Link>
                  </Button>
                ) : null}
              </CardActions>
            </Card>
          </Grid>
        );
      });
    }
  };
*/
  //console.log('---- characters ', characters);
  //const classes = useStyles();

  if (characters.length === 0 && IsLoading)
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>I tuoi personaggi</title>
      </Helmet>

      <MUISpeedDialMenu
        //icon={<SpeedDialIcon />}
        actions={actions}
      />

      <main className="pg_container">
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            I tuoi personaggi, {user.name}
          </Typography>
          <Typography align="center" color="textPrimary" gutterBottom>
            Filtra per:
          </Typography>
          <Container className="filters_container">
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f1"}
              >
                <MUISelectField
                  required={false}
                  lbl="Sistema di gioco"
                  name="ruleset"
                  value={rulesetFilter}
                  options={props.cfg.rulesetList}
                  emptyOption={["", " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="160px"
                  errList={[]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f2"}
              >
                <MUISelectField
                  required={false}
                  lbl="Archetipo"
                  name="archetype"
                  value={archetypeFilter}
                  options={props.cfg.archetypeList}
                  emptyOption={[0, " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="160px"
                  errList={[]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f3"}
              >
                <MUISelectField
                  required={false}
                  lbl="Genere"
                  name="gender"
                  value={genderFilter}
                  options={props.cfg.genderList}
                  emptyOption={["", " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="160px"
                  errList={[]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f4"}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{ marginTop: "-10px" }}
                >
                  <InputLabel id={"searchText_label"}>Testo</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <MUITextField
                      required={false}
                      lbl="Cerca"
                      name="searchText"
                      value={textFilter}
                      change={changeFilterHandler}
                      cssClass="input_el"
                      minWidth="150px"
                      errList={[]}
                      onenter={() => {
                        getCharacters();
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        getCharacters();
                      }}
                    >
                      <SearchIcon variant="outlined" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <InfiniteScroll
          dataLength={characters.length}
          next={getCharacters}
          hasMore={characters.length >= total ? false : true}
          loader={
            <Typography sx={{ marginTop: "20px" }} gutterBottom>
              Loading...
            </Typography>
          }
          endMessage={
            <Typography sx={{ marginTop: "20px", marginBottom: "20px" }}>
              {endMessage}
            </Typography>
          }
          //style={{ width: '100%' }}
        >
          <Container maxWidth="md" className="characters_container">
            <Grid container spacing={2}>
              {
              //renderCharacters()
              characters.map((el,i) => (
                <CharacterCard
                    rulesets={props.cfg.rulesetList}
                    archetypes={props.cfg.archetypeList}
                    character={el}
                    editable={user.id === el.user_id || user.level > 1 ? true: false}
                    key={'chl_'+i}
                />
            ))

              }
            </Grid>
          </Container>
        </InfiniteScroll>
      </main>
    </>
  );
}

export default UserCharactersPage;
