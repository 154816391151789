import React, { useCallback, useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Stack,
  Divider,
  Container,
  InputLabel,
  IconButton,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Typography from "@mui/material/Typography";
import MUISelectField from "./MUISelectField";
import MUITextField from "./MUITextField";
import InfiniteScroll from "react-infinite-scroll-component";
//import { Center } from "devextreme-react/map";

//import "devextreme/dist/css/dx.light.css";
//import "./RulesetView.css";
//import Drawer from "devextreme-react/drawer";

type FileDescrType = {
  path: string;
  text: string;
  date: string;
};

type SkillDescrType = {
  id: number;
  active: 0 | 1;
  type: number;
  name: string;
  cost: number;
  description: string;
};

type RulesetViewProps = {
  id: string;
  name: string;
  files: FileDescrType[];
  path: string;
  skills: SkillDescrType[];
  cfg: { [key: string]: any };
  wsCall: Function;
};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function RulesetView(props: RulesetViewProps) {
  const [value, setValue] = useState(0);
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [talents, setTalents] = useState<any>([]);
  const [rulesetFilter, setRulesetFilter] = useState(props.id);
  const [typeFilter, setTypeFilter] = useState("starting");
  const [gradeFilter, setGradeFilter] = useState(0);
  const [textFilter, setTextFilter] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const endMessage =
    total < 0
      ? "premi Invio per iniziare la ricerca"
      : "Caricamento completato.";

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function descriptionContent() {
    return <Typography>Descrizione</Typography>;
  }

  // Render the skills
  const renderSkills = () => {
    if (props.skills.length > 0) {
      return props.skills.map((skill) => {
        if (skill.active === 1) {
          let typeName = "";
          let t = props.cfg.skillTypes.filter((el) => el.id === skill.type);
          if (t.length > 0) typeName = t[0].name;
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{ display: "flex" }}
              key={"ch_" + skill.id}
            >
              <Card className="skillCard">
                <Typography
                  variant="subtitle1"
                  align="center"
                  //variant='h6'
                  gutterBottom
                >
                  {skill.name}
                </Typography>
                <Divider light={true} sx={{ marginBottom: "6px" }}></Divider>
                <Typography>Costo base: {skill.cost}</Typography>
                <Typography>Tipo: {typeName}</Typography>
                <Typography
                  style={{ whiteSpace: "pre-line", padding: "10px" }}
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  align="justify"
                >
                  {skill.description}
                </Typography>
              </Card>
            </Grid>
          );
        } else {
          return null;
        }
      });
    } else {
      return (
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Typography align="center">Nessuna abilità trovata</Typography>
        </Grid>
      );
    }
  };

  function changeFilterHandler(name, value) {
    if (name === "searchText") {
      setTextFilter(value);
      setTotal(-1);
    } else {
      if (name === "ruleset") setRulesetFilter(value);
      if (name === "type") setTypeFilter(value);
      if (name === "grade") setGradeFilter(value);
      setTotal(0);
    }
    setTalents([]);
    setPage(1);
  }

  function getTalents() {
    let params = [];
    let urlParms = "or=1&page=" + page;
    if (rulesetFilter.length > 0) urlParms += "&rs=" + rulesetFilter;
    if (typeFilter.length > 0) urlParms += "&tp=" + typeFilter;
    if (gradeFilter > 0) urlParms += "&gd=" + gradeFilter;
    if (textFilter.length > 0) urlParms += "&q=" + textFilter;
    props.wsCall(
      urlParms.length > 0 ? "talents?" + urlParms : "talents",
      "GET",
      IsLoading,
      setIsLoading,
      //netError,
      setNetError,
      params,
      (respData: any) => {
        //console.log('getTalents OK', respData);
        if (props.cfg.debug === true) {
          console.log("get talents", respData);
        }
        // handle get order detail response
        if (respData.status === true) {
          setTalents((talents) => [...talents, ...respData.data.data]);
          setPage(respData.data.current_page + 1);
          setTotal(respData.data.total);
        } else {
          console.log("Failed: " + respData.message);
        }
      }
    );
  }

  useEffect(() => {
    getTalents();
  }, [rulesetFilter, typeFilter, gradeFilter]);

  //console.log('talents', talents)
  // Render the talent list
  const renderTalentsDescr = () => {
    if (talents.length > 0) {
      return talents.map((talent) => {
        let ruleset = "";
        let t = props.cfg.rulesetList.filter((el) => el.id === talent.ruleset);
        if (t.length > 0) ruleset = t[0].name;
        let type = "";
        t = props.cfg.talentTypes.filter((el) => el.id === talent.type);
        if (t.length > 0) type = t[0].name;
        let grade = "";
        t = props.cfg.talentGrades.filter((el) => el.id === talent.grade);
        if (t.length > 0) grade = t[0].name;
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ display: "flex" }}
            key={"tal_" + talent.id}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                minWidth: "210px",
              }}
              className="characterCard"
            >
              <CardContent
              //component="div" // FORCED BY TS
              //salign="" // FORCED BY TS
              >
                <Typography
                  variant="subtitle1"
                  //variant='h6'
                  gutterBottom
                >
                  {talent.name}
                </Typography>
                <Divider light={true} sx={{ marginBottom: "6px" }}></Divider>
                <Typography>{type}</Typography>
                <Typography>{grade}</Typography>
                <Typography>Costo: {talent.cost} punti</Typography>
              </CardContent>
              <CardActions disableSpacing sx={{ mt: "auto" }}>
                <Button>
                  <Link to={"/talenti/" + talent.id}>
                    <VisibilityIcon />
                  </Link>
                </Button>
              </CardActions>
            </Card>
          </Grid>
        );
      });
    }
  };

  function renderTalents() {
    return (
      <Container maxWidth="md" className="title_container">
        <Typography align="center" color="textPrimary" gutterBottom>
          Filtra per:
        </Typography>
        <Container className="filters_container">
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{ display: "flex" }}
              key={"f2"}
            >
              <MUISelectField
                required={false}
                lbl="Tipo"
                name="type"
                value={typeFilter}
                options={props.cfg.talentTypes}
                emptyOption={[0, " "]}
                change={changeFilterHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                minWidth="160px"
                errList={[]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{ display: "flex" }}
              key={"f3"}
            >
              <MUISelectField
                required={false}
                lbl="Grado"
                name="grade"
                value={gradeFilter}
                options={props.cfg.talentGrades}
                emptyOption={[0, " "]}
                change={changeFilterHandler}
                composeText={(el) => {
                  return el.name;
                }}
                valName="id"
                cssClass="input_el"
                minWidth="160px"
                errList={[]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{ display: "flex" }}
              key={"f4"}
            >
              <Stack
                direction="column"
                justifyContent="center"
                sx={{ marginTop: "-10px" }}
              >
                <InputLabel id={"searchText_label"}>Testo</InputLabel>
                <Stack direction="row" spacing={1}>
                  <MUITextField
                    required={false}
                    lbl="Cerca"
                    name="searchText"
                    value={textFilter}
                    change={changeFilterHandler}
                    cssClass="input_el"
                    minWidth="160px"
                    errList={[]}
                    onenter={() => {
                      setTalents([]);
                      getTalents();
                    }}
                  />
                  <IconButton
                    onClick={() => {
                      setTalents([]);
                      getTalents();
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }

  function renderFile(el: FileDescrType, idx: number) {
    return (
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        lg={3}
        //sx={{ display: "flex" }}
        sx={{ display: "flex", flexGrow: "0", flexBasis: "1" }}
        key={"fd_" + idx}
      >
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            minWidth: "210px",
          }}
          //className="characterCard"
        >
          <CardContent
            //component="div" // FORCED BY TS
            //align="" // FORCED BY TS
            sx={{ textAlign: "left" }}
          >
            <Typography
              variant="subtitle1"
              //variant='h6'
            >
              {el.text}
            </Typography>
            <Typography
              variant="subtitle2"
              //variant='h6'
            >
              Data: {el.date}
            </Typography>
          </CardContent>
          <CardActions
            disableSpacing
            sx={{ marginRight: "auto", marginLeft: "auto" }}
          >
            <Link to={"/dloads/" + el.path} target="_blank" download>
              <Typography variant="subtitle1">Download</Typography>
            </Link>
          </CardActions>
        </Card>
      </Grid>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "inline-grid",
          justifyContent: "center",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="sezioni informative"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          //sx={{width: '100%'}}
          //centered
        >
          <Tab label="Descrizione" />
          <Tab label="Abilità" />
          <Tab label="Talenti" />
          <Tab label="Files" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {descriptionContent()}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {/*
          <Typography gutterBottom>Abilità</Typography>
          */}
        <Grid
          container
          spacing={2}
          sx={{ justify: "space-between", marginTop: "10px" }}
          alignItems="stretch"
          //justifyContent="flex-end"
        >
          {renderSkills()}
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {/*
      <Typography gutterBottom>Talenti</Typography>
          */}
        {renderTalents()}
        <InfiniteScroll
          dataLength={talents.length}
          next={getTalents}
          hasMore={talents.length >= total ? false : true}
          loader={
            <Typography sx={{ marginTop: "20px" }} gutterBottom>
              Loading...
            </Typography>
          }
          endMessage={
            <Typography sx={{ marginTop: "20px", marginBottom: "20px" }}>
              {endMessage}
            </Typography>
          }
          //style={{ width: '100%' }}
        >
          <Container maxWidth="md" className="characters_container">
            <Grid container spacing={4}>
              {renderTalentsDescr()}
            </Grid>
          </Container>
        </InfiniteScroll>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <>
          {/*
         <Typography gutterBottom>Regolamento e download</Typography>
          */}
          <Grid
            container
            spacing={2}
            sx={{ justify: "space-between", marginTop: "10px" }}
            alignItems="stretch"
            //justifyContent="flex-end"
          >
            {props.files.map((el, idx) => renderFile(el, idx))}
          </Grid>
        </>
      </CustomTabPanel>
    </Box>
  );
}

export default RulesetView;
