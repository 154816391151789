import React from "react";
import { Link } from "react-router-dom";
import {
//   Typography,
//   Button,
//   Stack,
  Grid,
  Card,
//  CardActions,
  CardContent,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
//import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
//import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import BasicCharacter from "../Tools/BasicCharacter.js";
import StatsSvg from "./StatsSvg.tsx";
import type { ArchetypeDescrType, SkillDescrType, RulesetDescrType } from '../types.tsx';
import MUINumberField from "./MUINumberField.js";
import MUINumberFieldAdv from "./MUINumberFieldAdv.js";
import MUISelectField from "./MUISelectField.js";
import { Select, MenuItem } from "@mui/material";
import Badge from '@mui/material/Badge';

declare module '@mui/material/CardContent' {
    interface CardContentOwnProps {
        align: string;
    }
}
type SkillValueCardSFPropsType = {
    archetype?: ArchetypeDescrType;
    ruleset: RulesetDescrType;
    character: BasicCharacter;
    skill: SkillDescrType;
    level: number;
    onChangeLevel: Function;
    onAddSubskill: Function;
    onRemoveSubskill: Function;
    subskillList?: any[];
    talentList?: any[];
    editable?: boolean; // unused
}

type skLevelType = {
    valName: number
}
const skillLevels: skLevelType[] = [
    { valName: 0 },
    { valName: 1 },
    { valName: 2 },
    { valName: 3 },
    { valName: 4 },
    { valName: 5 },
    { valName: 6 },
    { valName: 7 },
    { valName: 8 },
    { valName: 9 },
    { valName: 10 },
    { valName: 11 },
    { valName: 12 },
];
function composeText(el: skLevelType) {
    return el.valName;
}

function SkillValueCardSF({
    archetype,
    ruleset,
    character,
    skill,
    level,
    onChangeLevel,
    editable }: SkillValueCardSFPropsType) {

    /*
                        <MUINumberFieldAdv
                            required={true}
                            lbl={skill.name}
                            name={skill.code}
                            value={level}
                            change={onChangeLevel}
                            cssClass={skill.type === 'advanced' ? "field_cont_adv" : "field_cont"}
                            //errList={ErrorList}
                            min={0}
                            max={12}
                            right
                            //left
                            small
                            //vertical
                        />
    */

    const archBonus = ruleset.skillInit && ruleset.skillInit.filter((el) => el.archetype_id === character.archetype_id);
    const maxTalents = archBonus && archBonus[0].bonus.filter((el) => el.skCode === skill.code).length > 0 ? level + archBonus[0].bonus.filter((el) => el.skCode === skill.code)[0].num : level;
    //console.log('---- SkillCard ', props);
    //const classes = useStyles();
    return (
        <Grid
            item
            xs={12} sm={6} md={3} lg={3}
            key={'skill_' + character.id}
        >
            <Card
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "210px",
                    minHeight: "140px"
                }}
            >
                <CardContent align="left"
                    style={{ padding: '6px' }}
                >
                    <div style={{ height: '30px' }}>
                        <Select
                            key={'sk_' + skill.code}
                            name={skill.code}
                            value={level}
                            //label={label}
                            onChange={(e) => onChangeLevel(skill.code, e.target.value)}
                            variant='outlined'
                            //style={{ minWidth: "220px" }}
                            //style={{ minWidth: minWidth }}
                            style={{ height: '100%', float: 'left', width: '58px', marginRight: '6px' }}
                            //readOnly={props.readonly && props.readonly === true ? true : false}
                            size="small"
                            //error={ErrorMsg.length > 0 ? true : false}
                        >
                            {skillLevels.map((el) => (
                                <MenuItem
                                    key={"op_" + skill.name + "_" + el.valName}
                                    value={el.valName}
                                //style={{color: el.valName===0?'lightgray':el.valName<2?'red':'black'}}
                                >
                                    {el.valName}
                                </MenuItem >
                            ))}
                        </Select>
                        <div
                            style={{
                                //marginLeft: '10px', 
                                fontSize: '14px',
                                height: '30px',
                                //float:'left',
                                lineHeight: '30px',
                                textDecoration: skill.type === 'advanced' ? 'underline' : 'normal'
                            }}
                        >
                            {skill.name}
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Grid>

    );
}

export default SkillValueCardSF;
