import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CharacterForm from "../Components/CharacterForm";
import CharacterFormTCSF from "../Components/CharacterFormTCSF";
//import BasicCharacter from "../Tools/BasicCharacter";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import OkPopup from "../Components/OkPopup";
import { UserContext } from "../Components/UserContext";

function CharacterEditPage(props) {
  // Sets initial state for skill list to empty array
  const [IsLoading, setIsLoading] = useState(null);
  const [netError, setNetError] = useState(null);
  const [character, setCharacter] = useState(null);
  //const [showDialog, setShowDialog] = useState(false);
  const params = useParams();
  const id = params.id ? params.id : 0;
  const { wsCall, cfg } = props;
  const navigate = useNavigate();
  const { user, checkCharacterEditAccess } = useContext(UserContext);

  //console.log('CharacterEditPage. ');

  useEffect(() => {
    // get character (invoke API)
    if (user.auth === false) {
      navigate("/login");
    }
    if (IsLoading !== null) {
      return; // NOTA: questo è necessario perchè altrimenti solo con true e false viene richiamato di continuo al termine della chiamata
    }
    if (id > 0) {
      //if (IsLoading) return;
      //setIsLoading(true);
      let params = [];
      wsCall(
        "characters/" + id,
        "GET",
        IsLoading,
        setIsLoading,
        setNetError,
        params,
        (respData) => {
          if (cfg.debug === true) console.log("get character", respData);
          // handle get order detail response
          if (respData.status === true) {
            if (
              checkCharacterEditAccess(respData.data) === true
/*              
              respData.data.public === 1 ||
              respData.data.user_id === user.id ||
              user.level > 1
*/
              ) {
              setCharacter(respData.data);
            } else {
              console.log("Accesso non autorizzato");
              navigate("/personaggi");
            }
          } else {
            console.log("Failed: " + respData.message);
            setNetError(respData.message);
            //setCharacter(new BasicCharacter(2, props.cfg.rulesetList[0].id));
          }
        }
      );
    } else {
      //setCharacter(new BasicCharacter(2, cfg.rulesetList[0].id));
      setNetError("Id personaggio non valido");
    }
  }, [user.auth, id, wsCall, cfg.rulesetList, cfg.debug, IsLoading]);

  function closeDialog() {
    setNetError(false);
  }

  //if (!character || IsLoading) {
  if (!character && netError === null) {
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );
  }

  if (netError) {
    return (
      <OkPopup
        titletext={/*props.lang.LBL_ERROR*/ "Errore"}
        text={netError}
        closeHandler={closeDialog}
      />
    );
  }

  /*    
    <div className={"container_01"}>
        <div className="content_01">
            <div className='title_container'>
                <h1>Personaggio</h1>
            </div>
            <div>
                <CharacterForm
                    character={character}
                    skills={props.skills}
                    archetypeList={props.cfg.archetypeList}
                    rulesetList={props.cfg.rulesetList}
                    genderList={props.cfg.genderList}
                    wsCall={props.wsCall}
                    wsUrl="characters"
                />
            </div>
        </div>
    </div>
    */

  return (
    <>
      <Helmet>
        <title>Scheda personaggio</title>
      </Helmet>

      <main className="pg_container">
        <Container maxWidth="xl" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Scheda personaggio
          </Typography>
        </Container>

        <Container maxWidth="xl" className="character_container">
          {character.ruleset === "tcsf" ? (
            <CharacterFormTCSF
              userId={user.id}
              character={character}
              skills={props.skills}
              changeRuleset={null}
              archetypeList={props.cfg.archetypeList}
              rulesetList={props.cfg.rulesetList}
              genderList={props.cfg.genderList}
              expLevels={props.cfg.experienceLevels}
              talentGrades={props.cfg.talentGrades}
              talentTypes={props.cfg.talentTypes}
              wsCall={props.wsCall}
              wsUrl="characters"
              redirectUrl="/personaggi"
              //backUrl={-1}
            />
          ) : (
            <CharacterForm
              userId={user.id}
              character={character}
              skills={props.skills}
              changeRuleset={null}
              archetypeList={props.cfg.archetypeList}
              rulesetList={props.cfg.rulesetList}
              genderList={props.cfg.genderList}
              expLevels={props.cfg.experienceLevels}
              talentGrades={props.cfg.talentGrades}
              talentTypes={props.cfg.talentTypes}
              wsCall={props.wsCall}
              wsUrl="characters"
              redirectUrl="/personaggi"
              //backUrl={-1}
            />
          )}
        </Container>
      </main>
    </>
  );
}

export default CharacterEditPage;
