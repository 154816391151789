import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

function ConfirmPopup(props) {

  return (
    <Dialog open={true}>
      <DialogTitle>{props.titletext}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {props.text}
        </Typography>
        <br />
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="outlined" onClick={() => props.responseHandler(false)}>
            CHIUDI
          </Button>
          <Button variant="contained" onClick={() => props.responseHandler(true)}>
            OK
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmPopup;
