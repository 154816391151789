import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import EditIcon from "@mui/icons-material/Edit";
//import BuildIcon from '@mui/icons-material/Build';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DownloadIcon from "@mui/icons-material/Download";
import MUISpeedDialMenu from "../Components/MUISpeedDialMenu";
import CharacterView from "../Components/CharacterView";
//import BasicCharacter from "../Tools/BasicCharacter";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { UserContext } from "../Components/UserContext";

function CharacterViewPage(props) {
  // Sets initial state for skill list to empty array
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [character, setCharacter] = useState(null);
  //const [showDialog, setShowDialog] = useState(false);
  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const { user, checkCharacterEditAccess } = useContext(UserContext);

  /*
    function downloadCSV() {

    }
*/
  const actions = [
    //{ icon: <DownloadIcon />, name: 'Download', action: downloadCSV },
    {
      icon: <ArrowBackIosIcon />,
      name: "Indietro",
      action: () => navigate(-1),
    },
  ];
  if (user.auth === true && checkCharacterEditAccess(character)) {
    actions.push({
      icon: <EditIcon />,
      name: "Modifica",
      action: () => navigate("/personaggi/modifica/" + id),
    });
  }

  useEffect(() => {
    //console.log('id='+id);
    getCharacter();
  }, []);

  function getCharacter() {
    let params = [];
    props.wsCall(
      "characters/" + id,
      "GET",
      IsLoading,
      setIsLoading,
      //netError,
      setNetError,
      params,
      (respData) => {
        if (props.cfg.debug === true) {
          console.log("get character", respData);
        }
        // handle get order detail response
        if (respData.status === true) {
          setCharacter(respData.data);
        } else {
          console.log("Failed: " + respData.message);
          //setCharacter(new BasicCharacter(2, props.cfg.rulesetList[0].id));
        }
      }
    );
  }

  if (!character || IsLoading) {
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );
  }

  // unreacheable, viene effettuato il redirect a 404
  if (!character && netError && id > 0) {
    return (
      <Container maxWidth="md" className="title_container">
        <Typography
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Scheda Personaggio
        </Typography>
        <Typography>{netError}</Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>Scheda personaggio</title>
      </Helmet>

      <MUISpeedDialMenu
        actions={actions}
        //myVariant="crud"
      />

      <main className="pg_container">
        <Container maxWidth="ml" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Scheda Personaggio
          </Typography>
        </Container>

        <Container maxWidth="large" className="character_container">
          <CharacterView
            character={character}
            skills={props.skills}
            cfg={props.cfg}
            redirectUrl="/personaggi"
          />
        </Container>
      </main>
    </>
  );
}

export default CharacterViewPage;
