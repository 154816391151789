import React, { /*useState, useEffect,*/ useContext } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
} from "@mui/material";
import { UserContext } from "../Components/UserContext";

function RulesetsPage(props) {
  // Sets initial state for skill list to empty array
  //const navigate = useNavigate();
  const { user } = useContext(UserContext);

  //console.log('Rulesets ...', user);

  const rulesets = [
    {
      id: 1,
      name: "Rapido & Furioso",
      description: "Il Gioco di ruolo universale del Trollo, furioso al punto giusto!",
      img: "character-fire-with-skull-his-chest.jpg",
      authUrl:
        "https://www.freepik.com/free-ai-image/character-fire-with-skull-his-chest_40648425.htm#fromView=search&term=rpg+character+classes&page=1&position=45",
      authText: "Image By freepik",
      url: "/sistemi-di-gioco/rapido-e-furioso",
    },
    {
      id: 2,
      name: "Rapido & Furioso modificato",
      description:
        "Ecco quel che avviene se si prende il R & F base e lo si modifica incrociandolo col sistema storico del Manuale. In eterna via di completamento.",
      img: "draghi-e-immagini-di-intelligenza-artificiale-fantasy.jpg",
      authUrl:
        "https://it.freepik.com/foto-gratuito/draghi-e-immagini-di-intelligenza-artificiale-fantasy_42080892.htm#query=ai%20generated%20fantasy%20knight&position=32&from_view=search&track=ais",
      authText: "Image By freepik",
      url: "/sistemi-di-gioco/rapido-e-furioso-modificato",
    },
    {
      id: 3,
      name: "Sci-Fi",
      description:
        "Sistema di gioco realizzato principalmente per ambientazioni contemporanee o di hard science fiction.",
      img: "pittura-digitale-di-orribili-creature-con-mutazioni-genetiche-in-un-laboratorio-in-rovina.jpg",
      authUrl:
        "https://it.freepik.com/foto-gratuito/picco-di-montagna-nel-mistero-spettrale-del-cielo-notturno-generato-dall-intelligenza-artificiale_41439614.htm#query=mountain-peak-spooky-night-sky-mystery-generated-by-ai&position=0&from_view=search&track=sph",
      authText: "Image By freepik",
      url: "/sistemi-di-gioco/sci-fi",
    },
  ];
  /*
https://it.freepik.com/foto-gratuito/picco-di-montagna-nel-mistero-spettrale-del-cielo-notturno-generato-dall-intelligenza-artificiale_41439614.htm#query=mountain-peak-spooky-night-sky-mystery-generated-by-ai&position=0&from_view=search&track=sph
  <a href="https://it.freepik.com/foto-gratuito/pittura-digitale-di-orribili-creature-con-mutazioni-genetiche-in-un-laboratorio-in-rovina_14541147.htm#query=ai%20generated%20fantasy%20fight&position=46&from_view=search&track=ais">Immagine di liuzishan</a> su Freepik
  Immagine di <a href="https://it.freepik.com/foto-gratuito/personaggio-spaventoso-a-tutto-campo-in-posa_59233213.htm#query=ai%20generated%20fantasy%20fight&position=15&from_view=search&track=ais">Freepik</a>
  Immagine di <a href="https://it.freepik.com/foto-gratuito/posa-spettrale-del-carattere-di-vista-frontale_59232485.htm#page=3&query=ai%20generated%20fantasy%20skeleton%20armed&position=45&from_view=search&track=ais">Freepik</a>
  */

  // Render the character list
  const renderRulesets = () => {
    if (rulesets.length > 0) {
      return rulesets.sort((a, b) => a.id - b.id).map((ruleset) => {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            sx={{ display: "flex" }}
            key={"ch_" + ruleset.id}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                minWidth: "210px",
              }}
            >
              <CardContent align="left">
                <CardMedia
                  component="img"
                  height="340px"
                  image={"/images/" + ruleset.img}
                  alt={ruleset.name}
                />
                <a href={ruleset.authUrl} referrer="noreferrer" target="blank">
                  <Typography
                    variant="caption"
                    textAlign="right"
                    sx={{
                      display: "block",
                      position: "relative",
                      top: "-20px",
                      color: "lightgrey",
                      marginRight: "10px",
                    }}
                  >
                    {ruleset.authText}
                  </Typography>
                </a>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  sx={{ marginTop: "-16px" }}
                  //variant='h6'
                  gutterBottom
                >
                  {ruleset.name}
                </Typography>
                <Typography variant="body2" align="justify">
                  {ruleset.description}
                </Typography>
              </CardContent>
              <CardActions
                disableSpacing
                sx={{
                  mt: "auto",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Link to={ruleset.url}>
                  <Typography textAlign="center">Entra</Typography>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        );
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sistemi di gioco</title>
      </Helmet>

      <main className="pg_container">
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Sistemi di gioco
          </Typography>
        </Container>

        <Container maxWidth="lg" align="stretch" className="characters_container">
          <Grid container spacing={2}>
            {renderRulesets()}
          </Grid>
        </Container>
      </main>
    </>
  );
}

export default RulesetsPage;
