export class Stats {
  AGI = 0;
  AIM = 0;
  APP = 0;
  CHA = 0;
  CMB = 0;
  CON = 0;
  INT = 0;
  PER = 0;
  POW = 0;
  PRE = 0;
  STR = 0;
  WIL = 0;

  copy(stats) {
    this.AGI = stats.AGI;
    this.AIM = stats.AIM;
    this.APP = stats.APP;
    this.CHA = stats.CHA;
    this.CMB = stats.CMB;
    this.CON = stats.CON;
    this.INT = stats.INT;
    this.PER = stats.PER;
    this.POW = stats.POW;
    this.PRE = stats.PRE;
    this.STR = stats.STR;
    this.WIL = stats.WIL;
  }
}

export class Skills {
  skill_01 = 0;
  skill_02 = 0;
  skill_03 = 0;
  skill_04 = 0;
  skill_05 = 0;
  skill_06 = 0;
  skill_07 = 0;
  skill_08 = 0;
  skill_09 = 0;
  skill_10 = 0;
  skill_11 = 0;
  skill_12 = 0;
  skill_13 = 0;
  skill_14 = 0;
  skill_15 = 0;
  skill_16 = 0;
  skill_17 = 0;
  skill_18 = 0;
  skill_19 = 0;
  skill_20 = 0;
}

// array values by archetype ids (-1), Creature archetype excluded (8)
export const statsTable_RF = {
  //  ['Av', 'Co',	'Di', 'Es',	'Mi', 'Sa', 'Te',],

  AGI: ["P", "S", "S", "S", "M", "M", "M"],
  AIM: ["S", "S", "S", "P", "M", "M", "M"],
  APP: ["B", "B", "B", "B", "B", "B", "B"],
  CHA: ["S", "S", "P", "S", "S", "S", "S"],
  CMB: ["S", "P", "S", "S", "M", "M", "M"],
  CON: ["S", "P", "S", "P", "S", "S", "S"],
  INT: ["M", "M", "S", "M", "P", "P", "P"],
  PER: ["P", "S", "P", "P", "S", "P", "S"],
  POW: ["N", "N", "N", "N", "P", "M", "N"],
  PRE: ["P", "M", "S", "S", "S", "S", "P"],
  STR: ["S", "P", "S", "S", "M", "M", "M"],
  WIL: ["S", "S", "S", "S", "P", "M", "S"],
};

// array values by archetype ids (-1), Creature archetype excluded (8)
export const skillsTable_RF = {
  //       ['Av', 'Co', 'Di', 'Es',  'Mi', 'Sa', 'Te'],
  skill_01: ["S", "P", "S", "S", "M", "M", "M"],
  skill_02: ["S", "S", "S", "P", "M", "M", "S"],
  skill_03: ["P", "S", "P", "P", "S", "S", "S"],
  skill_04: ["S", "M", "P", "S", "S", "S", "P"],
  skill_05: ["M", "M", "S", "M", "P", "P", "S"],
  skill_06: ["M", "M", "S", "M", "M", "M", "M"],
  skill_07: ["P", "S", "S", "S", "M", "M", "M"],
  skill_08: ["P", "S", "S", "S", "M", "M", "M"],
  skill_09: ["S", "S", "S", "S", "M", "S", "S"],
  skill_10: ["S", "S", "P", "S", "S", "S", "S"],
  skill_11: ["S", "M", "P", "S", "S", "S", "M"],
  skill_12: ["M", "M", "S", "M", "S", "S", "P"],
  skill_13: ["M", "M", "M", "M", "M", "M", "P"],
  skill_14: ["S", "M", "S", "S", "M", "M", "P"],
  skill_15: ["M", "S", "M", "S", "S", "P", "P"],
  skill_16: ["M", "M", "M", "M", "P", "S", "M"],
  skill_17: ["S", "S", "S", "P", "M", "M", "M"],
  skill_18: ["N", "N", "N", "N", "N", "N", "N"],
  skill_19: ["N", "N", "N", "N", "N", "N", "N"],
  skill_20: ["N", "N", "N", "N", "N", "N", "N"],
};

function getRandomInt(max) {
  return Math.floor(Math.random() * max) + 1;
}

// each column per experience category (0 = novice, 4 = legend), one row for every classification ('P', 'S', 'M')
export const randomSkillLevels = {
  P: [
    () => {
      return getRandomInt(3);
    }, // novice
    () => {
      return getRandomInt(3) + 1;
    }, // certified/professional
    () => {
      return getRandomInt(3) + getRandomInt(3);
    }, // veteran
    () => {
      return getRandomInt(3) + getRandomInt(3) + 3;
    }, // hero
    () => {
      return getRandomInt(3) + getRandomInt(3) + 6;
    }, // legend
  ],
  S: [
    () => {
      return getRandomInt(2);
    }, // novice
    () => {
      return getRandomInt(3);
    }, // certified/professional
    () => {
      return getRandomInt(4);
    }, // veteran
    () => {
      return getRandomInt(4) + 1;
    }, // hero
    () => {
      return getRandomInt(4) + 2;
    }, // legend
  ],
  M: [
    () => {
      return getRandomInt(2) - 1;
    }, // novice
    () => {
      return getRandomInt(2) - 1;
    }, // certified/professional
    () => {
      return getRandomInt(2) - 1;
    }, // veteran
    () => {
      return getRandomInt(3) - 1;
    }, // hero
    () => {
      return getRandomInt(3) - 1;
    }, // legend
  ],
  // actually used for not active skills
  N: [
    () => {
      return 0;
    }, // novice
    () => {
      return 0;
    }, // certified/professional
    () => {
      return 0;
    }, // veteran
    () => {
      return 0;
    }, // hero
    () => {
      return 0;
    }, // legend
  ],
};

export const personalityTraits = [
  "Aggressivo",
  "Allegro",
  "Altruista",
  "Ambizioso",
  "Amichevole",
  "Amorale",
  "Arrogante",
  "Asociale",
  "Avido",
  "Autoritario",
  "Bigotto",
  "Calmo",
  "Cavalleresco",
  "Codardo",
  "Compassionevole",
  "Conformista",
  "Coraggioso",
  "Corretto",
  "Crudele",
  "Curioso",
  "Determinato",
  "Dipendente",
  "Disonesto",
  "Disonorevole",
  "Egoista",
  "Energico",
  "Estroverso",
  "Festaiolo",
  "Fiducioso",
  "'Fighetto'",
  "Frivolo",
  "Geloso",
  "Generoso",
  "Gentile",
  "Idealista",
  "Irrazionale",
  "Impacciato",
  "Impertinente",
  "Impulsivo",
  "Impudente",
  "Indipendente",
  "Indifferente",
  "Inflessibile",
  "Insensibile",
  "Insicuro",
  "Introverso",
  "Irrispettoso",
  "Irrequieto",
  "Irresponsabile",
  "Leader",
  "Leale",
  "Logico",
  "Loquace",
  "Lussurioso",
  "Minaccioso",
  "Misericordioso",
  "Misterioso",
  "Mite",
  "Mutevole",
  "Onesto",
  "Onorevole",
  "Ossessivo",
  "Ostile",
  "Ottimista",
  "Ottuso",
  "Pacifico",
  "Paranoico",
  "Passivo",
  "Permaloso",
  "Perseverante",
  "Pessimista",
  "Pettegolo",
  "Pigro",
  "Possessivo",
  "Preciso",
  "Premuroso",
  "Prepotente",
  "Presuntuoso",
  "Protettivo",
  "Provocatorio",
  "Prudente",
  "Raffinato",
  "Religioso",
  "Responsabile",
  "Romantico",
  "Sadico",
  "Sarcastico",
  "Scandaloso",
  "Scettico",
  "Scortese",
  "Seguace",
  "Sensibile",
  "Sensuale",
  "Seduttore",
  "Senza scrupoli",
  "Serioso",
  "Sicuro di sè",
  "Silenzioso",
  "Sleale",
  "Snob",
  "Socievole",
  "Sospettoso",
  "Spavaldo",
  "Spietato",
  "Spiritoso",
  "Stoico",
  "Superstizioso",
  "Tenace",
  "Temerario",
  "Timido",
  "Timoroso",
  "Umile",
  "Violento",
  "Vendicativo",
  "Vile",
  "Vittimista",
  "Volgare",
];

export const moralityTraits = [
  "Anti autorità",
  "Indifferente",
  "Conformista",
  "Corrotto",
  "Deplorevole",
  "Etico",
  "Cavalleresco",
  "Onorevole",
  "Legale",
  "Egoista",
  "Senza scrupoli",
  "Virtuoso",
  "Materialista",
];

export const motivations = [
  "Amore",
  "Avidità",
  "Significato profondo",
  "Scoperta",
  "Divertimento",
  "Aiutare il prossimo",
  "Passato irrisolto",
  "In missione",
  "Potere personale",
  "Sopravvivenza",
  "Fede",
  "Vendetta",
  "Competitività",
  "Ricerca della conoscenza",
  "Senso del dovere",
  "Giuramento",
  "Spirito Avventuroso",
];

export default class BasicCharacter {
  id = 0;
  archetype_id = 0;
  user_id = 0;
  name = "";
  race = "Umano";
  gender = "male";
  ruleset = "r_f";
  size = 3;
  description = "";
  notes = "";
  public = 1;

  stats = new Stats();
  skills = new Skills();
  talents = [];

  statPoints = 0;
  skillPoints = 0;
  talentPoints = 0;

  constructor(defStat = 2, ruleset = "r_f") {
    this.stats.AGI = defStat;
    this.stats.AIM = defStat;
    this.stats.APP = defStat;
    this.stats.CHA = defStat;
    this.stats.CMB = defStat;
    this.stats.CON = defStat;
    this.stats.INT = defStat;
    this.stats.PER = defStat;
    this.stats.POW = defStat;
    this.stats.PRE = defStat;
    this.stats.STR = defStat;
    this.stats.WIL = defStat;
    this.ruleset = ruleset;
    this.statPoints = this.statCost(defStat) * 12;
  }

  statCost(val) {
    if (val <= 4) return val;
    if (val === 5) return 6;
    else return 6 + (val - 5) * 3;
  }

  // returns an integer between 1 and max

  // type can be 'P' (Primary), 'S' (Secondary), 'M' (Marginal) or 'N' (Negligible)
  randomStat(type) {
    let val = 0;
    switch (type) {
      case "R": // random (1-6)
        val = getRandomInt(6);
        break;
      case "B": // random, balanced (0-6)
        val = getRandomInt(3) + getRandomInt(3) + getRandomInt(3) - 3;
        break;
      case "P": // 2-5
        val = getRandomInt(2) + getRandomInt(2) + getRandomInt(2) - 1;
        if (val < 3) val = 3;
        //val = getRandomInt(3) + getRandomInt(3) - 1;
        //val = getRandomInt(4)+1;
        break;
      case "S": // 1-4
        //val = getRandomInt(4);
        val = getRandomInt(3) + 1;
        break;
      case "M": // 1-3
        val = getRandomInt(3);
        break;
      case "N": // 0-1
        val = getRandomInt(2) - 1;
        break;
      default:
        throw new Error("Tipo caratteristica non supportata");
    }
    return val;
  }

  // unused
  rollStat() {
    let val = 0;
    let roll = getRandomInt(6) + getRandomInt(6) + getRandomInt(6);
    switch (roll) {
      case 3:
        val = 0;
        break;
      case 4:
      case 5:
      case 6:
        val = 1;
        break;
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        val = 2;
        break;
      case 12:
      case 13:
        val = 3;
        break;
      case 14:
      case 15:
        val = 4;
        break;
      case 16:
      case 17:
        val = 5;
        break;
      case 18:
        val = 6;
        break;
      default:
        throw new Error("Valore casuale non previsto");
    }
    return val;
  }

  creatureRandomStat(baseVal) {
    let val = baseVal;
    let roll =
      getRandomInt(3) + getRandomInt(3) + getRandomInt(3) + getRandomInt(3);
    switch (roll) {
      case 4:
        val -= 2;
        break;
      case 5:
        val -= 1;
        break;
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
        val += 0;
        break;
      case 11:
        val += 1;
        break;
      case 12:
        val += 2;
        break;
      default:
        throw new Error("Valore casuale non previsto");
    }
    return val;
  }

  // type: 'P', 'S', 'M'; experience (0 = novice, ... 5 = legend)
  randomSkill(type, exp) {
    let val = randomSkillLevels[type][exp];
    return val;
  }

  randomCharacter(ruleset, archetype_id, experienceClass) {
    if (archetype_id < 0 || archetype_id > skillsTable_RF.skill_01.length)
      throw new Error("Archetipo fuori range " + archetype_id);
    if (experienceClass < 0 || experienceClass >= randomSkillLevels.P.length)
      throw new Error("Classe di esperienza fuori range");
    this.archetype_id = archetype_id;
    this.ruleset = ruleset;
    /*
        if (archetype_id === 8 ) {
            return randomCreature(experienceClass);
        }
    */
    // set stats
    switch (ruleset) {
      case "r_f":
      case "r_fmod":
        Object.keys(this.stats).forEach((k) => {
          let type = statsTable_RF[k][archetype_id - 1];
          //console.log('Stats '+k+' - type =' + type);
          this.stats[k] = this.randomStat(type);
        });
        break;
      default:
        throw new Error("Sistema di gioco non supportato");
    }

    // set skills
    switch (ruleset) {
      case "r_f":
      case "r_fmod":
        Object.keys(this.skills).forEach((k) => {
          let type = skillsTable_RF[k][archetype_id - 1];
          //console.log('Skill '+k+' - type =' + type);
          this.skills[k] = randomSkillLevels[type][experienceClass]();
          //console.log('rolled: '+this.skills[k]);
        });
        break;
      default:
        throw new Error("Sistema di gioco non supportato");
    }

    // personality traits
    this.notes = this.generateNotes();
  }

  generateNotes() {
    let traits = "Tratti caratteriali: ";
    for (let i = 0; i < 3; i++) {
      if (i > 0) traits += ", ";
      traits += personalityTraits[getRandomInt(personalityTraits.length) - 1];
    }

    traits += "\nApprezza: ";
    traits += personalityTraits[getRandomInt(personalityTraits.length) - 1];
    traits += "\nDetesta: ";
    traits += personalityTraits[getRandomInt(personalityTraits.length) - 1];
  
    traits += "\nMoralità: ";
    traits += moralityTraits[getRandomInt(moralityTraits.length) - 1];
    traits += "\nMotivazione: ";
    traits += motivations[getRandomInt(moralityTraits.length) - 1];

    return traits;
  }

  randomCreature(experienceClass) {}

  getMaxStat() {
    if (this.ruleset === "r_f") return 7;
    else return 8;
  }
}
