import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import EditIcon from '@mui/icons-material/Edit';
import MUISpeedDialMenu from '../Components/MUISpeedDialMenu';
import TalentView from "../Components/TalentView";
//import BasicTalent from "../Tools/BasicTalent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { UserContext } from '../Components/UserContext';

function TalentViewPage(props) {
    // Sets initial state for skill list to empty array
    const [IsLoading, setIsLoading] = useState(false);
    const [netError, setNetError] = useState(null);
    const [talent, setTalent] = useState(null);
    //const [showDialog, setShowDialog] = useState(false);
    const params = useParams();
    const id = params.id;
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const actions = [
        { icon: <ArrowBackIosIcon />, name: 'Indietro', action: () => navigate('/talenti') },
    ];
    if (user.auth === true && user.level > 1) {
        actions.push({ icon: <EditIcon />, name: 'Modifica', action: () => navigate('/talenti/modifica/' + id) });
    }

    useEffect(() => {
        //console.log('id='+id);
        getTalent();
    }, []);

    function getTalent() {
        let params = [];
        props.wsCall(
            "talents/" + id,
            "GET",
            IsLoading,
            setIsLoading,
            //netError,
            setNetError,
            params,
            (respData) => {
                if (props.cfg.debug === true) {
                    console.log("get talent", respData);
                }
                // handle get order detail response
                if (respData.status === true) {
                    setTalent(respData.data);
                } else {
                    console.log("Failed: " + respData.message);
                }
            }
        );
    }

    if (!talent || IsLoading) {
        return (
            <div className="spinner">
                <img src="/images/waiting.gif" alt="loading..." />
            </div>
        );
    }


    // unreacheable, viene effettuato il redirect a 404
    if (!talent && netError && id > 0) {
        return (
            <Container maxWidth="md" className='title_container'>
                <Typography variant="h4" align="center" color="textPrimary" gutterBottom >
                    Scheda Personaggio
                </Typography>
                <Typography>
                    {netError}
                </Typography>
            </Container>
        )
    }

    return (
        <>
            <Helmet>
                <title>Scheda talento</title>
            </Helmet>

            <MUISpeedDialMenu
                actions={actions}
            />
            <main>
                <div className='pg_container'>
                    <Container maxWidth="md" className='title_container'>
                        <Typography variant="h4" align="center" color="textPrimary" gutterBottom >
                            Scheda Talento
                        </Typography>
                    </Container>

                    <Container maxWidth="large" className='character_container'>
                        <TalentView
                            talent={talent}
                            skills={props.skills}
                            cfg={props.cfg}
                            redirectUrl='/talenti'
                        />
                    </Container>
                </div>
            </main>
        </>
    );
}

export default TalentViewPage;
