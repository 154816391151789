import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CharacterForm from "../Components/CharacterForm";
import CharacterFormTCSF from "../Components/CharacterFormTCSF";
import BasicCharacter from "../Tools/BasicCharacter";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { UserContext } from "../Components/UserContext";

function CharacterCreatePage(props) {
  // Sets initial state for skill list to empty array
  const [character, setCharacter] = useState(null);
  const [ruleset, setRuleset] = useState(props.cfg.rulesetList[0].id);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  function rulesetHandler(name, value) {
    //console.log('changing ' + name + ' = ' + value);
    if (name === "ruleset") setRuleset(value);
  }

  //console.log('CharacterCreatePage', character);

  useEffect(() => {
    //console.log('id='+id);
    if (!user.auth) {
      navigate("/login");
    } else {
      let chr = new BasicCharacter(2, ruleset);
      chr.user_id = user.id;
      setCharacter(chr);
      //console.log('character', chr)
    }
  }, [user, ruleset]);

  //if (!character || IsLoading) {
  if (!character) {
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Scheda nuovo personaggio</title>
      </Helmet>

      <main className="pg_container">
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Scheda Nuovo Personaggio
          </Typography>
        </Container>

        <Container maxWidth="xl" className="character_container">
          {character.ruleset === "tcsf" ? (
            <CharacterFormTCSF
              userId={user.id}
              character={character}
              skills={props.skills}
              changeRuleset={rulesetHandler}
              archetypeList={props.cfg.archetypeList}
              rulesetList={props.cfg.rulesetList}
              genderList={props.cfg.genderList}
              expLevels={props.cfg.experienceLevels}
              talentGrades={props.cfg.talentGrades}
              talentTypes={props.cfg.talentTypes}
              wsCall={props.wsCall}
              wsUrl="characters"
              redirectUrl="/personaggi"
              //backUrl={-1}
            />
          ) : (
            <CharacterForm
              userId={user.id}
              character={character}
              skills={props.skills}
              changeRuleset={rulesetHandler}
              archetypeList={props.cfg.archetypeList}
              rulesetList={props.cfg.rulesetList}
              genderList={props.cfg.genderList}
              expLevels={props.cfg.experienceLevels}
              talentGrades={props.cfg.talentGrades}
              talentTypes={props.cfg.talentTypes}
              wsCall={props.wsCall}
              wsUrl="characters"
              redirectUrl="/personaggi"
              //backUrl={-1}
            />
          )}
        </Container>
      </main>
    </>
  );
}

export default CharacterCreatePage;
