import React, { useState, useEffect } from "react";
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

function MUITextAreaField(props) {
/*
    const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
  };

  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };

  
  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );
*/
  const [ErrorMsg, setErrorMsg] = useState(() => {
    const initialState = initErrorMsg();
    return initialState;
  });

  function initErrorMsg() {
    if (props.type === "radio") return "";
    if (!props.errList || props.errList.length === 0) return "";
    let txt = "";
    let errList = props.errList.filter((el) => el.id === props.name);
    if (errList.length > 0) {
      errList.forEach((el) => (txt += el.msg + " "));
    }
    const initialState = txt;
    return initialState;
  }

  useEffect(() => {
    if (ErrorMsg === "") {
      let txt = initErrorMsg();
      //if (txt !== "") 
      setErrorMsg(txt);
    }
  }, [props.errList]);

  function checkField(ev) {
    //console.log('updating '+props.name +': '+ev.target.value)
    props.change(props.name, ev.target.value);
    let val = ev.target.value;
    if (props.maxlen && props.maxlen > 0 && val.length > props.maxlen) {
      setErrorMsg('Testo troppo lungo: max ' + props.maxlen + ' caratteri');
      return;
    }
    if (props.required && val.trim().length === 0) {
      setErrorMsg('Inserisci un testo.');
    } else {
      setErrorMsg("");
    }
  }

  let label = props.lbl;
  //console.log(props);
  //console.log('... '+props.name + ' - ' + ErrorMsg);
  if (props.required === true && label.length > 0) label += "*";

  return (
    <div className={props.cssClass}>

      <p style={{marginBottom: '4px'}} >{props.lbl}</p>
      <TextareaAutosize 
        key={'txta_'+props.name}
        aria-label={label} 
        label={label} 
        variant="outlined"
        name={props.name}
        value={props.value}
        placeholder={props.placeholder ? props.placeholder : ""}
        onChange={(e) => checkField(e)}
        minRows={props.minRows? props.minRows : 4}
        maxRows={props.maxRows? props.maxRows : 8}
        //style={setStyle()}
        //style={{width: "100%"}}
        readOnly={props.readonly && props.readonly === true ? true : false}
      />

      {ErrorMsg.length > 0 ? (
        <div
          className={
            props.labelRight === true ? "errore_cart" : "campo_vuoto_cart"
          }
        >
          <p>{ErrorMsg}</p>
        </div>
      ) : null}
    </div>
  );
}

export default MUITextAreaField;
