import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Divider, Grid, Card, CardActions, CardContent, Stack, CardMedia, Container } from "@mui/material";
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import CircledText from './CircledText';
import MUILabeledText from './MUILabeledText';
import MUITextAreaField from './MUITextAreaField';
import CircledSkill from './CircledSkill';
import OkPopup from "./OkPopup";
//import BasicCharacter from "../Tools/BasicCharacter";
import './CharacterForm.css';

function CharacterView(props) {
  const [showDialog, setShowDialog] = useState(false);
  const [Message, setMessage] = useState("");
  const [OutcomeTitle, setOutcomeTitle] = useState("");
  const [OutcomeMessage, setOutcomeMessage] = useState("");

  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  //let maxStat = props.character.archetype_id === 8 ? 8 : 6;

  const closeDialog = () => {
    if (redirect === true) {
      navigate(props.redirectUrl);
    } else {
      setShowDialog(false);
      setMessage(null);
    }
  }

  let ruleset = null;
  let rulesetName = "";
  let arr = props.cfg.rulesetList.filter((el) => el.id === props.character.ruleset);
  if (arr.length > 0) {
    rulesetName = arr[0].name;
    ruleset = arr[0];
  }
  let archetypeName = "";
  arr = props.cfg.archetypeList.filter((el) => el.id === props.character.archetype_id);
  if (arr.length > 0)
    archetypeName = arr[0].name;
  let genderName = "";
  arr = props.cfg.genderList.filter((el) => el.id === props.character.gender);
  if (arr.length > 0)
    genderName = arr[0].name;
  let statNocircle = true;

  function compareObj( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }

  /*
                  <CircledText label="Agilità" text={props.character.stats.AGI} nocircle={statNocircle} />
                  <CircledText label="Bellezza" text={props.character.stats.APP} nocircle={statNocircle} />
                  <CircledText label="Combattività" text={props.character.stats.CMB} nocircle={statNocircle} />
                  <CircledText label="Determinaz." text={props.character.stats.WIL} nocircle={statNocircle} />
                  <CircledText label="Forza" text={props.character.stats.STR} nocircle={statNocircle} />
                  <CircledText label="Memoria" text={props.character.stats.INT} nocircle={statNocircle} />
                  <CircledText label="Mira" text={props.character.stats.AIM} nocircle={statNocircle} />
                  <CircledText label="Osservazione" text={props.character.stats.PER} nocircle={statNocircle} />
                  <CircledText label="Personalità" text={props.character.stats.CHA} nocircle={statNocircle} />
                  <CircledText label="Potere" text={props.character.stats.POW} nocircle={statNocircle} />
                  <CircledText label="Precisione" text={props.character.stats.PRE} nocircle={statNocircle} />
                  <CircledText label="Resistenza" text={props.character.stats.CON} nocircle={statNocircle} />

  */
  return (
    <>
      {showDialog ? (
        <OkPopup
          titletext={OutcomeTitle}
          text={OutcomeMessage}
          closeHandler={closeDialog}
        />
      ) : null}
      {Message ? (
        <OkPopup
          titletext={"Avviso"}
          text={Message}
          closeHandler={closeDialog}
        />
      ) : null}


      <Container maxWidth="lg" className='character_data' align='right'>
        <Grid container spacing={0} justifyContent="flex-end" >
          <Grid item xs={12} sm={3} md={3} lg={3} sx={{ display: 'flex' }} key={'col1'} /*justifyContent="right"*/ >
            <CardContent align="center" className='character_col_01' style={{ border: '1px solid #d0d7de', boxShadow: '0px 2px 2px #f6f8fa' }}>
              <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                Dati generali
              </Typography>
              <MUILabeledText label="Nome" text={props.character.name} />
              <MUILabeledText label="Razza" text={props.character.race} />
              <MUILabeledText label="Taglia" text={props.character.size} />
              <MUILabeledText label="Movimento" text={props.character.movement + " m/round"} />
              <MUILabeledText label="Genere" text={genderName} />
              <MUILabeledText label="Archetipo" text={archetypeName} />
              <MUILabeledText label="Regolamento" text={rulesetName} />
              <MUITextAreaField
                required={true}
                lbl="Descrizione"
                name="description"
                value={props.character.description.replace(/↵/g, "\n")}
                change={null}
                cssClass="input_el"
                errList={null}
                minRows={6}
                maxRows={8}
                readonly
              />
              <MUITextAreaField
                required={true}
                lbl="Note"
                name="notes"
                value={props.character.notes.replace(/↵/g, "\n")}
                change={null}
                cssClass="input_el"
                errList={null}
                minRows={6}
                maxRows={8}
                readonly
              />

            </CardContent>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} sx={{ display: 'flex' }} /*justifyContent="center"*/ key={'col2'} >
            <Badge badgeContent={props.character.statPoints} color="secondary" sx={{ "& .MuiBadge-badge": { fontSize: 16, fontWeight: 'bold', height: 25, minWidth: 15 } }} max={10000}>
              <CardContent align="center" className='character_col_02'>
                <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                  Caratteristiche
                </Typography>
                <div className='stats_grid'>
                  {
                    ruleset?.statsOrder.map((attr, idx) => 
                      attr.length > 0? (
                      <CircledText 
                        key={'stat_'+idx}
                        label={ruleset.statsNames[attr]} 
                        text={props.character.stats[attr]} 
                        nocircle={statNocircle} 
                      />
                      ):null
                    )
                  }
                </div>
                <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
                <Stack direction="row" justifyContent='center'>
                  {
                    ruleset.id === 'tcsf'? (
                      <>
                        <CircledText 
                          label="VC" 
                          text={
                                (Math.round((props.character.stats.AGI + props.character.stats.AGI + props.character.stats.STR) / 3) + props.character.skills.skill_01)
                                +"/"+
                                (Math.round((props.character.stats.AGI + props.character.stats.STR + props.character.stats.STR) / 3) + props.character.skills.skill_01)
                          } 
                          noSquare={true} 
                        />
                        <CircledText 
                          label="VT" 
                          text={
                            (Math.round((props.character.stats.PRE + props.character.stats.PRE + props.character.stats.PER) / 3) + props.character.skills.skill_01)
                            +"/"+
                            (Math.round((props.character.stats.AGI + props.character.stats.AGI + props.character.stats.PER) / 3) + props.character.skills.skill_01)
                          } 
                          noSquare={true} 
                        />
                        <CircledText label="VM" text={props.character.stats.WIL + props.character.skills.skill_16} noSquare={true} />
                      </>  
                    ) : (
                      <>
                        <CircledText label="VC" text={props.character.stats.CMB + props.character.skills.skill_01} noSquare={true} />
                        <CircledText label="VT" text={props.character.stats.AIM + props.character.skills.skill_02} noSquare={true} />
                        <CircledText label="VM" text={props.character.stats.INT + props.character.skills.skill_16} noSquare={true} />
                      </>
                    )
                  }
                </Stack>
              </CardContent>
            </Badge>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} sx={{ display: 'flex' }} key={'col3'} /*justifyContent="left"*/ /*style={{ backgroundColor: 'cyan' }}*/ >
            <Badge badgeContent={props.character.skillPoints} color="secondary" sx={{ "& .MuiBadge-badge": { fontSize: 16, fontWeight: 'bold', height: 25, minWidth: 15 } }} max={10000}>
              <CardContent align="left" className='character_col_03'>
                <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                  Abilità
                </Typography>
                {
                  props.skills.map((sk) => sk.active === 1 && sk.ruleset === props.character.ruleset ?
                    (
                      <CircledSkill value={props.character.skills[sk.code]} label={sk.name} key={"sk_" + sk.id} />
                    ) : null
                  )
                }

              </CardContent>
            </Badge>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} sx={{ display: 'flex' }} key={'col4'} /*justifyContent="left"*/ /*style={{ backgroundColor: 'cyan' }}*/ >
            <Badge badgeContent={props.character.talentPoints} color="secondary" sx={{ "& .MuiBadge-badge": { fontSize: 16, fontWeight: 'bold', height: 25, minWidth: 15 } }} max={10000}>
              <CardContent align="left" className='character_colv_04'>
                <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                  Talenti
                </Typography>
                {
                  props.character.talents.sort(compareObj).map((tal, index) => {
                    let grade = "";
                    if (tal.grade === 2)
                      grade = " (II)";
                    if (tal.grade === 3)
                      grade = " (III)";
                    let name = tal.name;
                    if (tal.pivot.variant && tal.pivot.variant.length > 0) 
                      name = name.replace('***', tal.pivot.variant);
                    return (
                      <Box direction='row' justifyContent="left" key={'talchr_' + tal.id+'.'+index} >
                        <Box
                          sx={{ minWidth: '200px', display: 'flex', alignItems: 'left'}}
                        >
                          <Typography sx={{ textAlign: 'left', width: '85%', flexShrink: 0 }}>
                            {name + grade}
                          </Typography>
                          <Typography sx={{ color: 'text.secondary', textAlign: 'right', marginLeft: '6px', paddingRight: '0px'}}>
                            {tal.cost}
                          </Typography>
                        </Box>
                      </Box>
                    )
                  })
                }

              </CardContent>
            </Badge>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default CharacterView;