import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MUISelectField from "./MUISelectField";

function ConfirmPopup(props) {
  return (
    <Dialog open={true}>
      <DialogTitle>{props.titletext}</DialogTitle>
      <DialogContent dividers>
        <Typography>
          Puoi generare automaticamente un personaggio appartenente
          all'archetipo selezionato (purchè non Creatura).
        </Typography>
        <Typography gutterBottom>
          Seleziona la classe di esperienza desiderata, per generare i livelli
          di conoscenza nelle varie Abilità in base all'Archetipo scelto.
        </Typography>
        <MUISelectField
          required={false}
          lbl="Classe di esperienza"
          name="experience"
          value={props.expSetting}
          options={props.expLevels}
          //emptyOption={["", ""]}
          emptyOption={[]}
          change={props.experienceHandler}
          composeText={(el) => {
            return el.name;
          }}
          valName="id"
          cssClass="input_el"
        />
        <br />
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            variant="outlined"
            onClick={() => props.responseHandler(false)}
          >
            CHIUDI
          </Button>
          <Button
            variant="contained"
            onClick={() => props.responseHandler(true)}
          >
            OK
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmPopup;
