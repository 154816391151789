import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './CircledSkill.css';

function CircledSkill(props) {

    return (
        <Stack
            direction="row"
            justifyContent="left"
            sx={{marginBottom: '4px'}}
            spacing={1}
        >
            <Box
                className={props.nocircle && props.nocircle === true ? "sk_nocircle" : "sk_circle"}
            >
                {props.value > 0? props.value:""}
            </Box>
            <Box
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <Typography>{props.label}</Typography>
            </Box>
        </Stack>
    )
}

export default CircledSkill;