import React from "react";
import { Link } from "react-router-dom";
import { 
    Typography, 
    Button, 
    Grid, 
    Card, 
    CardActions, 
    CardContent, 
    Divider, 
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
//import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from '@mui/icons-material/Visibility';
//import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
//import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import BasicCharacter from "../Tools/BasicCharacter";
import StatsSvg from "./StatsSvg.tsx";
import type {ArchetypeDescrType, GenderDescrType, RulesetDescrType} from '../types';

declare module '@mui/material/CardContent' {
    interface CardContentOwnProps {
        align: string;
    }
}
type CharacterCardPropsType = {
    archetypes: ArchetypeDescrType[];
    rulesets: RulesetDescrType[];
    character: BasicCharacter;
    skillList?: any[];
    editable?: boolean; // unused
}

function CharacterCard({archetypes, rulesets, character, editable}: CharacterCardPropsType) {  
    let ruleset: RulesetDescrType| null = null;
    let t = rulesets.filter((el) => el.id === character.ruleset);
    if (t.length > 0) {
        ruleset = t[0];
    }
    let arch = "";
    let a = archetypes.filter((el) => el.id === character.archetype_id);
    if (a.length > 0)
        arch = a[0].name;
    let gender = "a";
    if (character.gender === "male")
        gender = "m";
    else if (character.gender === "female")
        gender = "f";
    else
        gender = "a";
    //console.log('---- characterCard ', characters);
    //const classes = useStyles();
    return (
        <Grid
            item
            xs={12} sm={6} md={3} lg={3}
            key={'chl_' + character.id}
        >
            <Card
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "210px"
                }}
            >
                <CardContent align="left">
                    {ruleset? (
                        <StatsSvg
                            key={"ksvg_"+character.id}
                            character={character}
                            ruleset={ruleset}
                            scale={true}
                        />                        
                    ):null}
                    <Typography
                        variant='subtitle1'
                        //variant='h6'
                        gutterBottom
                    >
                        {character.name}
                    </Typography>
                    <Divider light={true} sx={{ marginBottom: '6px' }} ></Divider>
                    <Typography>{character.race + " (" + gender + ")"}</Typography>
                    <Typography>{arch}</Typography>
                    <Typography>{ruleset? ruleset.name : 'NOT FOUND'}</Typography>
                    <Typography>
                        Punti: {character.statPoints + " - " + character.skillPoints + " - " + character.talentPoints}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing sx={{ mt: "auto" }} >
                    <Button size='small'>
                        <Link to={"/personaggi/" + character.id}>
                            <VisibilityIcon />
                        </Link>
                    </Button>
                    {
                        editable === true ? (
                            <Button size='small'>
                                <Link to={"/personaggi/modifica/" + character.id}>
                                    <EditIcon />
                                </Link>
                            </Button>
                        ) : null
                    }
                </CardActions>
            </Card>
        </Grid>

    );
}

export default CharacterCard;
