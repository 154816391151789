import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

/*
                <Button size="md" sx={{fontWeight: 'bold'}}>
                    {props.value}
                </Button>

*/
function MUINumberField(props) {
  //console.log("MUI Field ", props.name, props.value);
  return (
    <div
      className={
        props.cssClass && props.cssClass.length > 0
          ? props.cssClass
          : "field_cont"
      }
    >
      {props.left ? <span className="lbltop">{props.lbl}</span> : null}
      <ButtonGroup 
        size="small" 
        aria-label="small outlined button group" 
        orientation={props.vertical?"vertical":"horizontal"}
      >
        <Button
          onClick={(e) => {
            if (props.value > props.min) {
              props.change(props.name, props.value - 1);
            }
          }}
        >
          -
        </Button>

        <Button size={props.small?"small":"md"} sx={{ fontWeight: "bold" }}>
          {props.value}
        </Button>

        <Button
          onClick={(e) => {
            if (props.value < props.max) {
              props.change(props.name, props.value + 1);
            }
          }}
        >
          +
        </Button>
      </ButtonGroup>
      {props.right ? (
        <span className="lblright">{props.lbl}</span>
      ) : props.left ? null : (
        <p className="lblbottom">{props.lbl}</p>
      )}
    </div>
  );
}

export default MUINumberField;
