import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
//import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MUITextField from "./MUITextField";

function TalentVariantPopup(props) {
  return (
    <Dialog open={true}>
      <DialogTitle>{props.titletext}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{props.text}</Typography>

        <Stack direction="row" justifyContent="center">
          <MUITextField
            required={true}
            type="text"
            lbl="Argomento / campo specifico"
            name="variant"
            value={props.value}
            change={props.change}
            cssClass="input_el"
            errList={props.errList}
            maxlen={255}
          />
        </Stack>

        <br />
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            variant="outlined"
            onClick={() => props.responseHandler(false)}
          >
            ANNULLA
          </Button>
          <Button
            variant="contained"
            onClick={() => props.responseHandler(true)}
          >
            CONFERMA
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default TalentVariantPopup;
