import React, { useState, useContext } from "react";
import {
  Typography,
  Button,
  Stack,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import validator from "validator";
import { useNavigate } from "react-router-dom";
//import Dialog from "@material-ui/core/Dialog";
import TextField from "@mui/material/TextField";
import OkPopup from "../Components/OkPopup";
//import ConfirmPopup from '../Components/ConfirmPopup';
import { UserContext } from "../Components/UserContext";

const RegisterPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [outcomeMessage, setOutcomeMessage] = useState("");
  //const [netError, setNetError] = useState(null);
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formConfirmPassword, setFormConfirmPassword] = useState("");
  const [errorList, setErrorList] = useState([]);
  const { user, login } = useContext(UserContext);
  const navigate = useNavigate();

  /*
    Image by <a href="https://www.freepik.com/free-photo/still-life-objects-with-role-playing-game-sheet_24749858.htm#query=rpg%20game&position=14&from_view=keyword&track=ais">Freepik</a>
    Image by <a href="https://www.freepik.com/free-photo/top-view-beautiful-rpg-still-life-items_22820832.htm#query=rpg%20game&position=31&from_view=keyword&track=ais">Freepik</a>
    */

  function checkTextValue(txt, special = "") {
    if (txt.trim().length === 0) {
      return "Campo obbligatorio.";
    }
    switch (special) {
      case "phone":
        if (!txt.match(/^[0-9\s(+)-]*$/)) {
          return "Formato errato";
        }
        if (txt.length < 8) {
          return "Numero troppo corto";
        }
        break;
      case "email":
        if (!validator.isEmail(txt)) {
          return "Formato email non valido";
        }
        break;
      case "cap":
        if (!validator.isPostalCode(txt, "IT")) {
          return "CAP non valido";
        }
        break;
      case "password":
        if (
          !validator.isStrongPassword(txt, {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 0,
          })
        )
          return "La password deve essere di almeno 8 caratteri, di cui almeno una lettera maiuscola, una lettera minuscola e un numero";
        break;
      default:
        if (special.trim().length > 0) {
          return "Tipo non supportato";
        }
    }
    return "";
  }

  function formValidation() {
    var errList = [];
    let res = "";

    res = checkTextValue(formName);
    if (res !== "") errList.push({ id: "name", msg: res });
    res = checkTextValue(formEmail, "email");
    if (res !== "") errList.push({ id: "email", msg: res });
    res = checkTextValue(formPassword, "password");
    if (res !== "") errList.push({ id: "password", msg: res });
    if (formPassword !== formConfirmPassword)
      errList.push({
        id: "confirmPassword",
        msg: "Le due password non coincidono",
      });

    return errList;
  }

  const closeDialog = () => {
    setShowDialog(false);
  };

  function registerRequest() {
    let errList = formValidation();
    if (errList.length > 0) {
      setErrorList(errList);
      return;
    }

    let params = [
      //{ name: "session", value: props.sessionKey },
      //{ name: "lang", value: props.lang.TXT_LANGPAR },
      { name: "name", value: formName },
      { name: "email", value: formEmail },
      { name: "password", value: formPassword },
    ];
    props.wsCall(
      "auth/register",
      "POST",
      isLoading,
      setIsLoading,
      (errMsg) => {
        if (errMsg) {
          //console.log(errMsg);
          //setNetError(errMsg);
          setErrMessage(errMsg);
          setShowDialog(true);
        }
      },
      params,
      (respData) => {
        //console.log(respData);
        //props.userUpdate(respData, props.redirect);
        //props.userUpdate(respData, false); // no redirect there
        if (respData.status === true) {
          //props.userUpdate(respData.data.account, false, '', respData.data.new_session); // no redirect there
          //navigate("/login");
          setOutcomeMessage(respData.message);
          setShowDialog(true);
        } else {
          setErrMessage(respData.message);
          setShowDialog(true);
        }
      }
    );
  }

  function handleResponse() {
    setShowDialog(false);
    setOutcomeMessage("");
    navigate('/login');
  }

  if (isLoading === true) {
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="operazione in corso ..." />
      </div>
    );
  }

  return (
    <main className="pg_container">
      {showDialog === true && errMessage.length > 0 ? (
        <OkPopup
          titletext="Errore!"
          text={errMessage}
          closeHandler={closeDialog}
        />
      ) : null}
      {showDialog === true && outcomeMessage.length > 0 ? (
        <OkPopup
          titletext="Grazie per esserti registrato!"
          text={outcomeMessage}
          closeHandler={handleResponse}
        />
      ) : null}

      <Container maxWidth="md" className="title_container">
        <Typography
          variant="h4"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Registrati
        </Typography>
        <Typography align="center" color="textPrimary" gutterBottom>
          Una volta registrato, potrai creare e modificare i tuoi personaggi!
        </Typography>
      </Container>
      <Container maxWidth="md" className="title_container">
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{ display: "flex" }}
            key={"f1"}
            justifyContent="right"
          >
            <Stack direction="column" justifyContent="center" spacing={3}>
              <TextField
                label="Email*"
                variant="outlined"
                name="email"
                type="email"
                value={formEmail}
                placeholder="Email"
                onChange={(e) => setFormEmail(e.target.value)}
                error={
                  errorList.filter((el) => el.id === "email").length > 0
                    ? true
                    : false
                }
                helperText={
                  errorList.filter((el) => el.id === "email").length > 0
                    ? errorList.filter((el) => el.id === "email")[0].msg
                    : ""
                }
                size="small"
              />
              <TextField
                label="Nome*"
                variant="outlined"
                name="name"
                type="text"
                value={formName}
                placeholder="Nome"
                onChange={(e) => setFormName(e.target.value)}
                error={
                  errorList.filter((el) => el.id === "name").length > 0
                    ? true
                    : false
                }
                helperText={
                  errorList.filter((el) => el.id === "name").length > 0
                    ? errorList.filter((el) => el.id === "name")[0].msg
                    : ""
                }
                size="small"
              />
              <TextField
                label="Password*"
                variant="outlined"
                name="password"
                type="password"
                value={formPassword}
                placeholder="Password"
                onChange={(e) => setFormPassword(e.target.value)}
                error={
                  errorList.filter((el) => el.id === "password").length > 0
                    ? true
                    : false
                }
                helperText={
                  errorList.filter((el) => el.id === "password").length > 0
                    ? errorList.filter((el) => el.id === "password")[0].msg
                    : ""
                }
                size="small"
              />
              <TextField
                label="Conferma password*"
                variant="outlined"
                name="confirmPassword"
                type="password"
                value={formConfirmPassword}
                placeholder="Conferma password"
                onChange={(e) => setFormConfirmPassword(e.target.value)}
                error={
                  errorList.filter((el) => el.id === "confirmPassword").length >
                    0
                    ? true
                    : false
                }
                helperText={
                  errorList.filter((el) => el.id === "confirmPassword").length >
                    0
                    ? errorList.filter((el) => el.id === "confirmPassword")[0]
                      .msg
                    : ""
                }
                size="small"
              />
              <Button variant="contained" onClick={() => registerRequest()}>
                REGISTRATI
              </Button>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{ display: "flex" }}
            key={"f2"}
          >
            <Card>
              <CardContent
                align="center" /* style={{ border: '1px solid #d0d7de', boxShadow: '0px 2px 2px #f6f8fa' }} */
              >
                <CardMedia
                  component="img"
                  //height="194"
                  image={
                    props.cfg.urlPageImg +
                    "top-view-beautiful-rpg-still-life-items.jpg"
                  }
                  alt="login to game board"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};
export default RegisterPage;
