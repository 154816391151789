import React, {useState} from "react";
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import BuildIcon from '@mui/icons-material/Build';
import CloseIcon from '@mui/icons-material/Close';
//import './MUISpeedDialMenu.css';

function MUISpeedDialMenu(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box 
            className="speed_dial_box"
            //sx={{ position: 'fixed', right: '36px', top: '36px', mt: 3, height: 320, zIndex: '10' }}
            //sx={{ position: 'fixed', left: '26px', top: '60px', mt: 3, height: 320, zIndex: '10', displayPrint: 'none'}}            
            sx={{ displayPrint: 'none'}}            
        >
            <SpeedDial
                ariaLabel="Comandi"
                //hidden={hidden}
                //icon={<SpeedDialIcon />}
                icon={props.myVariant === 'crud'?
                <SpeedDialIcon icon={<BuildIcon />} openIcon={<CloseIcon />} />
                :<SpeedDialIcon />}
                direction={"down"}
                onClose={handleClose}
                onOpen={handleOpen}                
                open={open}
            >
                {props.actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        //tooltipOpen
                        tooltipPlacement="right"
                        //tooltipPlacement="bottom"
                        onClick={() => action.action()}                                                
                    />
                ))}
            </SpeedDial>
        </Box>
    )
}

export default MUISpeedDialMenu;