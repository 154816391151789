import React, { useState, useContext } from 'react';
//import { slide as Menu } from 'react-burger-menu';
import { elastic as Menu } from 'react-burger-menu';
//import { scaleRotate as Menu } from 'react-burger-menu';
import MUISwitchField from "./MUISwitchField";
import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from '../Components/UserContext';

import './Slidebar.css';

export default function Slidebar(props) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };
  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  //console.log('Slidebar::user', user);


  return (
    <Menu isOpen={isMenuOpen} onStateChange={handleStateChange}>
      <NavLink
        className="menu-item"
        to="/"
        onClick={() => setIsMenuOpen(false)}
      >
        Home
      </NavLink>
      <NavLink
        className="menu-item"
        to="/personaggi"
        onClick={() => setIsMenuOpen(false)}
      >
        Personaggi
      </NavLink>
      <NavLink
        className="menu-item"
        to="/abilita"
        onClick={() => setIsMenuOpen(false)}
      >
        Abilità
      </NavLink>
      <NavLink
        className="menu-item"
        to="/talenti"
        onClick={() => setIsMenuOpen(false)}
      >
        Talenti
      </NavLink>
      <NavLink
        className="menu-item"
        to="/sistemi-di-gioco"
        onClick={() => setIsMenuOpen(false)}
      >
        Sistemi di gioco
      </NavLink>
      {user && user.auth === true && user.id > 0 ? (
        <NavLink
          className="menu-item"
          to="/profilo"
          onClick={() => setIsMenuOpen(false)}
        >
          Pagina personale
        </NavLink>
      ) : null}
      {user && user.auth === true && user.id > 0 ? (
        <NavLink
          className="menu-item"
          to="/logout"
          onClick={() => setIsMenuOpen(false)}
        >
          Esci
        </NavLink>
      ) : (
        <NavLink
          className="menu-item"
          to="/login"
          onClick={() => setIsMenuOpen(false)}
        >
          Accedi
        </NavLink>
      )}

      {/* <MUISwitchField
        required={false}
        lbl="Theme"
        name="theme"
        value={props.theme}
        change={props.changeState}
        cssClass="input_el"
        errList={[]}
        placement="start"
      /> */}
    </Menu>
  );
};
