import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Typography,
  Button,
  Stack,
  Grid,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  InputLabel,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCardIcon from "@mui/icons-material/AddCard";
import EditIcon from "@mui/icons-material/Edit";
//import EditNoteIcon from '@mui/icons-material/EditNote';
import VisibilityIcon from "@mui/icons-material/Visibility";
//import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import MUISpeedDialMenu from "../Components/MUISpeedDialMenu";
import MUISelectField from "../Components/MUISelectField";
import MUITextField from "../Components/MUITextField";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../Components/UserContext";

function TalentsPage(props) {
  // Sets initial state for skill list to empty array
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [talents, setTalents] = useState([]);
  const [rulesetFilter, setRulesetFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [gradeFilter, setGradeFilter] = useState(0);
  const [textFilter, setTextFilter] = useState("");
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const endMessage =
    total < 0
      ? "premi Invio per iniziare la ricerca"
      : "Caricamento completato.";

  const actions = [];
  if (user.auth && user.level > 1) {
    actions.push({
      icon: <AddCardIcon />,
      name: "Crea nuovo",
      action: () => navigate("/talenti/nuovo"),
    });
  }

  useEffect(() => {
    getTalents();
  }, [rulesetFilter, typeFilter, gradeFilter]);

  function changeFilterHandler(name, value) {
    if (name === "searchText") {
      setTextFilter(value);
      setTotal(-1);
    } else {
      if (name === "ruleset") setRulesetFilter(value);
      if (name === "type") setTypeFilter(value);
      if (name === "grade") setGradeFilter(value);
      setTotal(0);
    }
    setTalents([]);
    setPage(1);
  }

  /*
        function getTalents() {
            let params = [];
            let urlParms = "";
            if (rulesetFilter.length > 0)
                urlParms += "&rs=" + rulesetFilter;
            if (typeFilter > 0)
                urlParms += "&tp=" + typeFilter;
            if (gradeFilter.length > 0)
                urlParms += "&gd=" + gradeFilter;
            if (textFilter.length > 0)
                urlParms += "&q=" + textFilter;
            props.wsCall(
                urlParms.length > 0 ? "talents?" + urlParms : "talents",
                "GET",
                IsLoading,
                setIsLoading,
                //netError,
                setNetError,
                params,
                (respData) => {
                    if (props.cfg.debug === true) {
                        console.log("get characters", respData);
                    }
                    // handle get order detail response
                    if (respData.status === true) {
                        setTalents(respData.data);
                    } else {
                        console.log("Failed: " + respData.message);
                    }
                }
            );
        }
    */

  function getTalents() {
    let params = [];
    let urlParms = "or=1&page=" + page;
    if (rulesetFilter.length > 0) urlParms += "&rs=" + rulesetFilter;
    if (typeFilter.length > 0) urlParms += "&tp=" + typeFilter;
    if (gradeFilter > 0) urlParms += "&gd=" + gradeFilter;
    if (textFilter.length > 0) urlParms += "&q=" + textFilter;
    props.wsCall(
      urlParms.length > 0 ? "talents?" + urlParms : "talents",
      "GET",
      IsLoading,
      setIsLoading,
      //netError,
      setNetError,
      params,
      (respData) => {
        if (props.cfg.debug === true) {
          console.log("get talents", respData);
        }
        // handle get order detail response
        if (respData.status === true) {
          setTalents((talents) => [...talents, ...respData.data.data]);
          setPage(respData.data.current_page + 1);
          setTotal(respData.data.total);
        } else {
          console.log("Failed: " + respData.message);
        }
      }
    );
  }

  // Render the talent list
  const renderTalents = () => {
    if (talents.length > 0) {
      return talents.map((talent) => {
        let ruleset = "";
        let t = props.cfg.rulesetList.filter((el) => el.id === talent.ruleset);
        if (t.length > 0) ruleset = t[0].name;
        let type = "";
        t = props.cfg.talentTypes.filter((el) => el.id === talent.type);
        if (t.length > 0) type = t[0].name;
        let grade = "";
        t = props.cfg.talentGrades.filter((el) => el.id === talent.grade);
        if (t.length > 0) grade = t[0].name;
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            sx={{ display: "flex" }}
            key={"ch_" + talent.id}
          >
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                minWidth: "210px",
              }}
              className="characterCard"
            >
              <CardContent align="left">
                <Typography
                  variant="subtitle1"
                  //variant='h6'
                  gutterBottom
                >
                  {talent.name}
                </Typography>
                <Divider light={true} sx={{ marginBottom: "6px" }}></Divider>
                <Typography>{type}</Typography>
                <Typography>{grade}</Typography>
                <Typography>{ruleset}</Typography>
                <Typography>Costo: {talent.cost} punti</Typography>
              </CardContent>
              <CardActions disableSpacing sx={{ mt: "auto" }}>
                <Button size="sm">
                  <Link to={"/talenti/" + talent.id}>
                    <VisibilityIcon />
                  </Link>
                </Button>
                {user.auth === true && user.level > 1 ? (
                  <Button size="sm">
                    <Link to={"/talenti/modifica/" + talent.id}>
                      <EditIcon />
                    </Link>
                  </Button>
                ) : null}
              </CardActions>
            </Card>
          </Grid>
        );
      });
    }
    /*
                else {
                    return (
                        <Grid item xs={12} sx={{ display: 'flex' }}>
                            <Typography align='center'>Nessun talento trovato</Typography>
                        </Grid>)
                }
        */
  };

  //const classes = useStyles();
  if (talents.length === 0 && IsLoading)
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );

  return (
    <>
      <Helmet>
        <title>Elenco talenti</title>
      </Helmet>

      <MUISpeedDialMenu
        //icon={<SpeedDialIcon />}
        actions={actions}
      />

      <main className="pg_container">
        <Container maxWidth="md" className="title_container">
          <Typography
            variant="h4"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            Talenti
          </Typography>
          <Typography align="center" color="textPrimary" gutterBottom>
            Filtra per:
          </Typography>
          <Container className="filters_container">
            <Grid container spacing={4}>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f1"}
              >
                <MUISelectField
                  required={false}
                  lbl="Sistema di gioco"
                  name="ruleset"
                  value={rulesetFilter}
                  options={props.cfg.rulesetList}
                  emptyOption={["", " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="160px"
                  errList={[]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f2"}
              >
                <MUISelectField
                  required={false}
                  lbl="Tipo"
                  name="type"
                  value={typeFilter}
                  options={props.cfg.talentTypes}
                  emptyOption={[0, " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="160px"
                  errList={[]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f3"}
              >
                <MUISelectField
                  required={false}
                  lbl="Grado"
                  name="grade"
                  value={gradeFilter}
                  options={props.cfg.talentGrades}
                  emptyOption={[0, " "]}
                  change={changeFilterHandler}
                  composeText={(el) => {
                    return el.name;
                  }}
                  valName="id"
                  cssClass="input_el"
                  minWidth="160px"
                  errList={[]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                sx={{ display: "flex" }}
                key={"f4"}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  sx={{ marginTop: "-10px" }}
                >
                  <InputLabel id={"searchText_label"}>Testo</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <MUITextField
                      required={false}
                      lbl="Cerca"
                      name="searchText"
                      value={textFilter}
                      change={changeFilterHandler}
                      cssClass="input_el"
                      minWidth="160px"
                      errList={[]}
                      onenter={() => {
                        setTalents([]);
                        getTalents();
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        setTalents([]);
                        getTalents();
                      }}
                    >
                      <SearchIcon variant="outlined" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Container>

        <InfiniteScroll
          dataLength={talents.length}
          next={getTalents}
          hasMore={talents.length >= total ? false : true}
          loader={
            <Typography sx={{ marginTop: "20px" }} gutterBottom>
              Loading...
            </Typography>
          }
          endMessage={
            <Typography sx={{ marginTop: "20px", marginBottom: "20px" }}>
              {endMessage}
            </Typography>
          }
          //style={{ width: '100%' }}
        >
          <Container maxWidth="md" className="characters_container">
            <Grid container spacing={4}>
              {renderTalents()}
            </Grid>
          </Container>
        </InfiniteScroll>
      </main>
    </>
  );
}

export default TalentsPage;
