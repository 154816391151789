import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Button, Grid, CardContent, Stack, Container } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
//import BuildIcon from '@mui/icons-material/Build';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ComputerIcon from '@mui/icons-material/Computer';
import IconButton from "@mui/material/IconButton";
import MUISpeedDialMenu from './MUISpeedDialMenu';
import MUITextField from './MUITextField';
import MUISelectField from './MUISelectField';
import MUINumberField from './MUINumberField';
import MUITextAreaField from './MUITextAreaField.js';
//import MUINumberFieldAdv from './MUINumberFieldAdv';
import MUISwitchField from './MUISwitchField';
import CircledText from './CircledText';
import isEmail from 'validator/lib/isEmail';
import OkPopup from "./OkPopup";
import ConfirmPopup from './ConfirmPopup';
//import SideCmdPanel from './SideCmdPanel';
import SkillValueCardSF from './SkillValueCardSF.tsx';
import BuildPopup from './BuildPopup';
//import BasicCharacter from "../Tools/BasicCharacter";
import SFCharacter from "../Tools/SFCharacter";
import './CharacterForm.css';

const statValues = [
  { valName: 0 },
  { valName: 1 },
  { valName: 2 },
  { valName: 3 },
  { valName: 4 },
  { valName: 5 },
  { valName: 6 },
  { valName: 7 },
  { valName: 8 },
];

function CharacterFormTCSF(props) {
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [showDialog, setShowDialog] = useState(false)

  // character data
  const [State, setState] = useState(props.character);
  const [Stats, setStats] = useState(props.character.stats);
  const [Skills, setSkills] = useState(props.character.skills);
  const [SubSkills, setSubSkills] = useState(props.character.subskills?props.character.subskills:[]);
  const [SubskillList, setSubskillList] = useState([]);
  //const [maxStat, setMaxStat] = useState(props.character.archety)
  var maxStat = State.archetype_id === 8 ? 8 : 6;

  const [ErrorList, setErrorList] = useState([]);
  const [Message, setMessage] = useState("");
  const [OutcomeTitle, setOutcomeTitle] = useState("");
  const [OutcomeMessage, setOutcomeMessage] = useState("");
  const [ConfirmDelete, setConfirmDelete] = useState(false);

  const [experienceLevel, setExperienceLevel] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();

  const settings = props.rulesetList.find((el) => el.id === State.ruleset);

  function changeStateHandler(name, value) {
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  function changeStatsHandler(name, value) {
    setStats(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  function changeSkillsHandler(name, value) {
    setSkills(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    // get character (invoke API)
    // if (IsLoading !== null) {
    //   return; // NOTA: questo è necessario perchè altrimenti solo con true e false viene richiamato di continuo al termine della chiamata
    // }
      if (IsLoading) return;
      setIsLoading(true);
      let params = [];
      let urlParms = "or=1&rs=tcsf&np=1";
      props.wsCall(
        "subskills?"+ urlParms,
        "GET",
        IsLoading,
        setIsLoading,
        setNetError,
        params,
        (respData) => {
          console.log("get subskills", respData);
          // handle  response
          if (respData.status === true) {
            setSubskillList(respData.data);
          } else {
            console.log("Subskills fetch failed: " + respData.message);
            setNetError(respData.message);
          }
        }
      );
  }, []);

  /*
  useEffect(() => {
    maxStat = State.archetype_id === 8 ? 8 : 6;
  }, [State.archetype_id])
  */

  //console.log('characterFormTCSF::character', props.character);

  function checkTextValue(txt, special = "") {
    //console.log(fieldName + ': ' + txt);
    if ((txt === null) || (txt.trim().length === 0))
      return "Il campo non può essere vuoto";
    if (special === "email") {
      if (!isEmail(txt))
        return "Indirizzo email non valido";
    }
    //if (special === "attribute")

    return "";
  }

  function formValidation() {
    var errList = [];
    let res = "";

    res = checkTextValue(State.name);
    if (res !== "") errList.push({ id: "name", msg: res });
    res = checkTextValue(State.race);
    if (res !== "") errList.push({ id: "race", msg: res });
    if (State.archetype_id <= 0)
      errList.push({ id: "archetype_id", msg: res });

    return errList;
  }

  function resetForm() {
    if (State.id === 0) {
      //let chr = new BasicCharacter(2, State.ruleset);
      let chr = new SFCharacter(2, State.ruleset);
      chr.user_id = State.user_id;
      setState(chr);
      setStats(chr.stats);
      setSkills(chr.skills);
    } else {
      setState(props.character);
      setStats(props.character.stats);
      setSkills(props.character.skills);
    }
  }

  function generateCharacterHandler() {
    //let chr = new BasicCharacter(2, State.ruleset);
    let chr = new SFCharacter(2, State.ruleset);
    chr.user_id = State.user_id;
    chr.gender = State.gender;
    setErrorList([]);
    try {
      if (State.archetype_id === 0 || State.archetype_id === 8)
        throw new Error('Devi selezionare un archetipo diverso da Creatura per generare automaticamente un personaggio.')
      chr.randomCharacter(State.ruleset, State.archetype_id, experienceLevel);
      //console.log('generated character', chr);
    } catch (error) {
      setShowDialog(true);
      setOutcomeTitle('Errore');
      setOutcomeMessage(error.message);
      setErrorList([{ id: "archetype_id", msg: "Seleziona un Archetipo valido" }]);
      return;
    }
    //console.log('state.id = '+State.id);
    if (State.id > 0) {
      // stiamo generando un personaggio già esistente. Modifichiamo quello, conserviamo alcuni dati
      chr.id = State.id;
      chr.name = State.name;
      chr.description = State.description;
    }
    setState(chr);
    setStats(chr.stats);
    setSkills(chr.skills);
  }

  //const { wsCall } = props;

  const sendFormHandler = () => {
    var errList = formValidation();

    setErrorList(errList);
    //console.log(errList);

    if (errList.length > 0) {
      setMessage("Dati non corretti");
      return;
    } else {
      setMessage("");
    }

    // validation ok, submit subscription
    // build character object
    let $character = State;
    $character.stats = Stats;
    $character.skills = Skills;

    // NOTE: Laravel ha problemi con le chiamate PUT (e PATCH)
    // per farlo funzionare occorre fare comunque una chiamata POST,
    // aggiungendo un parametro "_method: PUT"
    //console.log(obj);
    let jsonString = JSON.stringify($character);
    let params = [
      { name: "data_obj", value: jsonString },
    ];
    let url = props.wsUrl;
    if (State.id > 0) {
      // update (simulare il PUT per Laravel)
      params.push({ name: "_method", value: "PUT" })
      url += "/" + State.id;
    }

    props.wsCall(
      url,
      "POST", //State.id > 0 ? "PUT" : "POST", problemi con Lavarel API
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (result) => {
        if (result.status === true) {
          // operation successful
          setOutcomeTitle("Salvataggio riuscito");
          setOutcomeMessage(result.message);
          if (result.redirect && result.redirect.length > 0) {
            navigate(result.redirect);
          }
          //setState(result.data);
          //resetForm();
        } else {
          // processing error
          setOutcomeTitle("Salvataggio fallito");
          setOutcomeMessage(result.message);
          if (Object.keys(result.data).length > 0) {
            // form error list (should not happen, since we check it client side)
            var errList = [];
            Object.entries(result.data).forEach(([key, val]) => {
              errList.push({ id: key, msg: val });
            });
            //console.log('err');
            //console.log(errList);
            setErrorList(errList);
          }
        }
        setShowDialog(true);
        //console.log(result);
      }
    );
  };

  const deleteCharacterHandler = () => {
    let params = [];

    props.wsCall(
      props.wsUrl + "/" + State.id,
      "DELETE", //State.id > 0 ? "PUT" : "POST", problemi con Lavarel API
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (result) => {
        if (result.status === true) {
          // operation successful
          setOutcomeTitle("Cancellazione riuscita");
          setOutcomeMessage(result.message);
          resetForm();
          setRedirect(true);
        } else {
          // processing error
          setOutcomeTitle("Cancellazione fallita");
          setOutcomeMessage(result.message);
          if (Object.keys(result.data).length > 0) {
            // form error list (should not happen, since we check it client side)
            var errList = [];
            Object.entries(result.data).forEach(([key, val]) => {
              errList.push({ id: key, msg: val });
            });
            //console.log('err');
            //console.log(errList);
            setErrorList(errList);
          }
        }
        setShowDialog(true);
        //console.log(result);
      }
    );
  }

  const closeDialog = () => {
    if (redirect === true) {
      navigate(props.redirectUrl);
    } else {
      setShowDialog(false);
      setNetError(null);
      setMessage(null);
    }
  }
  const handleConfirmResponse = (bResponse) => {
    if (bResponse === true) {
      deleteCharacterHandler();
    }
    setConfirmDelete(false);
  }
  const handleExpLevel = (name, level) => {
    setExperienceLevel(level);
  }


  function statCost(val) {
    if (val <= 4)
      return val;
    if (val === 5)
      return 6;
    else
      return 6 + (val - 5) * 3;
  }

  function statsTotalCost() {
    return (
      statCost(Stats.AGI) +
      //statCost(Stats.AIM) +
      //statCost(Stats.APP) +
      statCost(Stats.CHA) +
      //statCost(Stats.CMB) +
      statCost(Stats.CON) +
      statCost(Stats.INT) +
      statCost(Stats.PER) +
      statCost(Stats.POW) +
      statCost(Stats.PRE) +
      statCost(Stats.STR) +
      statCost(Stats.WIL)
    );
  }

  function skillsTotalCost() {
    let sum = 0;
    let rk = 0;
    props.skills.forEach(sk => {
      if (sk.active === 1 && sk.ruleset === State.ruleset) {
        rk = Skills[sk.code];
        sum += sk.cost * (rk * (rk + 1) / 2);
      }
    });
    return sum;
  }
  const statsPts = statsTotalCost();
  const skillsPts = skillsTotalCost();

  function deleteHandler() {
    setConfirmDelete(true);
  }

  const [autoGenerator, setAutogenerator] = useState(false);
  const toggleGenerator = () => setAutogenerator(!autoGenerator);
  const actions = [
    { icon: <ArrowBackIosIcon />, name: 'Indietro', action: () => navigate('/personaggi') },
    { icon: <PersonAddIcon />, name: 'Nuovo', action: () => navigate('/personaggi/nuovo') },
    { icon: <RefreshIcon />, name: 'Reset', action: resetForm },
    { icon: <SaveIcon />, name: 'Salva', action: sendFormHandler },
    { icon: <DeleteIcon />, name: 'Elimina', action: deleteHandler },
    { icon: <ComputerIcon />, name: 'Genera', action: toggleGenerator },
  ];

  const buildHandler = (val) => {
    if (val === true) {
      generateCharacterHandler();
    }
    setAutogenerator(false);
  }

  function lexicalSort(a, b) {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0
  }

  function generateNotes() {
    let chr = new SFCharacter(2, State.ruleset);
    chr.user_id = State.user_id;
    chr.gender = State.gender;
    changeStateHandler('notes', chr.generateNotes())
  }

  return (
    <>
      {IsLoading ? (
        <div className="spinner">
          <img src="/images/waiting.gif" alt="loading..." />
        </div>
      )
        : null
      }
      {showDialog ? (
        <OkPopup
          titletext={OutcomeTitle}
          text={OutcomeMessage}
          closeHandler={closeDialog}
        />
      ) : null}
      {netError ? (
        <OkPopup
          titletext={/*props.lang.LBL_ERROR*/"Errore"}
          text={netError}
          closeHandler={closeDialog}
        />
      ) : null}
      {Message ? (
        <OkPopup
          titletext={"Avviso"}
          text={Message}
          closeHandler={closeDialog}
        />
      ) : null}
      {ConfirmDelete ? (
        <ConfirmPopup
          titletext={"Attenzione"}
          text="Vuoi procedere con la cancellazione di questo personaggio?"
          responseHandler={handleConfirmResponse}
        />
      ) : null}

      <MUISpeedDialMenu
        actions={actions}
        myVariant="crud"
      />

      {autoGenerator === true ? (
        <BuildPopup
          expSetting={experienceLevel}
          expLevels={props.expLevels}
          experienceHandler={handleExpLevel}
          responseHandler={buildHandler}
        />
      )
        : null
      }

      <Container maxWidth="xl" className='character_data' /*style={{margin: 'auto', backgroundColor: 'cyan'}}*/ >
        <Grid container className="character_gridz" spacing={0} alignItems="stretch" justifyContent="flex-end"  >
          <Grid item xs={12} sm={4} md={3} lg={3} xl={2} sx={{ display: 'flex' }} /*justifyContent="right"*/ >
            <CardContent className='character_col_01' >
              <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                Dati generali
              </Typography>
              <MUITextField
                required={true}
                type="text"
                lbl="Nome"
                name="name"
                value={State.name}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
                maxlen={255}
              />
              <MUITextField
                required={true}
                type="text"
                lbl="Razza"
                name="race"
                value={State.race}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                maxlen={255}
              />
              <MUINumberField
                required={true}
                lbl="Taglia"
                name="size"
                value={State.size}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                min={0}
                max={8}
              />
              <MUISelectField
                required={true}
                lbl="Genere"
                name="gender"
                value={State.gender}
                options={props.genderList}
                //emptyOption={["", ""]}
                emptyOption={[]}
                change={changeStateHandler}
                composeText={(el) => { return el.name; }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
              />

              <MUISelectField
                required={true}
                lbl="Archetipo"
                name="archetype_id"
                value={State.archetype_id}
                options={props.archetypeList}
                //emptyOption={["", ""]}
                emptyOption={[0, " "]}
                change={changeStateHandler}
                composeText={(el) => { return el.name; }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
                //lang={props.lang}
              />
              <MUISelectField
                required={true}
                lbl="Sistema di gioco"
                name="ruleset"
                value={State.ruleset}
                options={props.rulesetList}
                //emptyOption={["", ""]}
                emptyOption={[]}
                change={State.id > 0 ? null : props.changeRuleset}
                composeText={(el) => { return el.name; }}
                valName="id"
                cssClass="input_el"
                errList={ErrorList}
                readonly={State.id > 0 ? true : false}
              />
              <MUISwitchField
                required={true}
                lbl="Pubblico"
                name="public"
                value={State.public}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                placement="start"
              />
              <MUITextAreaField
                required={true}
                type="text"
                lbl="Descrizione"
                name="description"
                value={State.description}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                maxlen={255}
              />
              <MUITextAreaField
                required={true}
                type="text"
                lbl="Note"
                name="notes"
                value={State.notes}
                change={changeStateHandler}
                cssClass="input_el"
                errList={ErrorList}
                maxlen={255}
              />
              <Stack direction='row' sx={{float: 'right', marginTop: '-10px', displayPrint: 'none'}}>
                <Typography variant='caption'>Rigenera note</Typography>
                <IconButton
                  size="small"
                  aria-label="rigenera note"
                  color="inherit"
                  onClick={generateNotes}
                  sx={{marginTop: '-6px'}}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Stack>
            </CardContent>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={3} xl={2} sx={{ display: 'block' }} /*justifyContent="center"*/ >
            <Badge badgeContent={statsPts} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 16, fontWeight: 'bold', height: 25, minWidth: 15 } }} max={10000}>
              <CardContent align="center" className='character_col_02'>
                <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                  Caratteristiche
                </Typography>
                <div className='stats_gridX'>
                  {
                    settings?.statsOrder.map((key) =>
                      // <MUINumberField
                      //   key={key}
                      //   required={true}
                      //   lbl={settings.statsNames[key]}
                      //   name={key}
                      //   value={Stats[key]}
                      //   change={changeStatsHandler}
                      //   cssClass="input_el_02"
                      //   errList={ErrorList}
                      //   min={0}
                      //   max={maxStat}
                      //   small   
                      // />
                      <div style={{ height: '30px', marginBottom: '4px' }}>
                      <Select
                          key={'sk_' + key}
                          name={key}
                          value={Stats[key]}
                          //onChange={changeStatsHandler}
                          onChange={(e) => changeStatsHandler(key, e.target.value)}
                          variant='outlined'
                          //style={{ minWidth: "220px" }}
                          //style={{ minWidth: minWidth }}
                          style={{ height: '100%', float: 'left', width: '58px', marginRight: '6px' }}
                          size="small"
                      >
                          {statValues.map((el) => (
                            el.valName <= maxStat?
                              <MenuItem
                                  key={"op_" + key + "_" + el.valName}
                                  value={el.valName}
                              //style={{color: el.valName===0?'lightgray':el.valName<2?'red':'black'}}
                              >
                                  {el.valName}
                              </MenuItem >
                              :null
                          ))}
                      </Select>
                      <div
                          style={{
                              //marginLeft: '10px', 
                              fontSize: '16px',
                              height: '30px',
                              //float:'left',
                              lineHeight: '30px',
                              textAlign: 'left'
                          }}
                      >
                          {settings.statsNames[key]}
                      </div>
                  </div>
                  )
                  }
                </div>
                <Divider sx={{ marginBottom: '10px', marginTop: '10px' }} />
                <Stack direction="row" justifyContent='center'>
                  <CircledText label="VC" text={Math.round((Stats.AGI + Stats.AGI + Stats.STR) / 3) + Skills.skill_01} noSquare={true} />
                  <CircledText label="VT" text={Math.round((Stats.PRE + Stats.PRE + Stats.PER) / 3) + Skills.skill_02} noSquare={true} />
                  <CircledText label="VM" text={Stats.WIL + Skills.skill_16} noSquare={true} />
                </Stack>
              </CardContent>
            </Badge>
          </Grid>

          <Grid item xs={12} sm={4} md={5} lg={5} xl={8} style={{float:'right'}}>
            <Badge badgeContent={skillsPts} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 16, fontWeight: 'bold', height: 25, minWidth: 15 } }} max={10000}>
            <Box className="character_col_0304">

              <CardContent >
                <Typography align='center' sx={{ fontWeight: 'bold' }} gutterBottom>
                  Abilità
                </Typography>
                </CardContent>
              <Grid container spacing={0.5}>
                {
                  props.skills.sort(lexicalSort).map((sk) => sk.active === 1 && sk.ruleset === State.ruleset ?
                    (
                      <SkillValueCardSF
                        ruleset={settings}
                        character={State}
                        skill={sk}
                        level={Skills[sk.code]}
                        onChangeLevel={changeSkillsHandler}
                        onAddSubskill={null}
                        onRemoveSubskill={null}
                      />
                    ) : null
                  )
                }
              </Grid>
              </Box>
            </Badge>
          </Grid>

        </Grid>
      </Container>
    </>
  );
}

export default CharacterFormTCSF;