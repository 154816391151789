import React from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';

function MUISwitchField(props) {
    return (
        <div className="field_cont">
            <FormControlLabel
                value={props.lbl}
                control={
                    <Switch
                        color="primary"
                        checked={props.value > 0 ? true : false}
                        onChange={(e) => {
                            //console.log(e):
                            e.target.checked === true?
                            props.change(props.name, 1)
                            : props.change(props.name, 0);
                        }}
                    />
                }
                label={props.lbl}
                labelPlacement={props.placement}
            />
        </div>
    );
}

export default MUISwitchField;
