import React, { useState, useEffect, createContext } from "react";
//import MyUseLocalStorage from './MyUseLocalStorage';
/*
  reference:
  https://dev.to/dancrtis/learn-to-usecontext-with-hooks-in-3-minutes-4c4g
*/

export const UserContext = createContext({ 
  name: "", 
  id: 0,
  level: 0,
  token: "", 
  auth: false 
});

export const UserProvider = ({ children }) => {
  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState({
    name: "",
    id: 0,
    level: 0,
    token: "",
    auth: false,
  });
  //const {setItem} = MyUseLocalStorage();
  const lskey = "myuser";

  useEffect(() => {
    const usr = localStorage.getItem(lskey);
    //console.log('UserProvider::useEffect()', usr);
    if (usr) {
      setUser(JSON.parse(usr));
    }
  }, []);

  // Login updates the user data with a name parameter
  const login = (id, name, level, token) => {
    let usr = {
      id: id,
      name: name,
      level: level,
      token: token,
      auth: true,
    };
    setUser(usr);
    localStorage.setItem(lskey, JSON.stringify(usr));
  };

  // Logout updates the user data to default
  const logout = () => {
    let usr = {
      name: "",
      id: 0,
      level: 0,
      token: "",
      auth: false,
    };
    setUser(usr);
    localStorage.setItem(lskey, JSON.stringify(usr));
  };

  // check user editing access to character
  const checkCharacterEditAccess = (character) => {
    if (!character)
      return false;
    if (character.public == 1) {
      if (character.user_id > 0) {
        // character has an owner
        if (character.user_id === user.id) {
          // user is the character owner
          return true;
        } else {
          // user is not the character owner: is he a super user?
          if (user.id > 0 && user.level > 1) {
            return true;
          }
        }
      } else {
        // character does not have an owner (could that happen?)
        if (user.id > 0 && user.level >= 1) {
          // edited by super user only
          return true;
        }
       }
    } else {
      // character is not public => only owner (or super user) can edit it
      if (character.user_id > 0) {
        // has owner
        if (character.user_id === user.id) {
          // user is the character owner
          return true;
        } 
      } else {
        // no owner => edited by super user only
        if (user.id > 0 && user.level > 1)
          return true;
      }
    }
    return false;
  };

  return (
    <UserContext.Provider value={{ user, login, logout, checkCharacterEditAccess }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
