import BasicCharacter from './BasicCharacter'
//import { skillsTable_RF} from './BasicCharacter'
import { statsTable_RF } from './BasicCharacter';
import { randomSkillLevels } from './BasicCharacter';

// array values by archetype ids (-1), Creature archetype excluded (8)
export const skillsTable_SF = {
  //       ['Av', 'Co', 'Di', 'Es', 'Mi', 'Sa', 'Te'],
  skill_01: ["S", "P", "S", "S", "M", "M", "M"],
  skill_02: ["S", "S", "S", "P", "M", "M", "S"],
  skill_03: ["P", "S", "P", "P", "S", "S", "S"],
  skill_04: ["S", "M", "P", "S", "S", "S", "P"],
  skill_05: ["M", "M", "S", "M", "P", "P", "S"],
  skill_06: ["M", "M", "S", "M", "M", "M", "M"],
  skill_07: ["P", "S", "S", "S", "M", "M", "M"],
  skill_08: ["P", "S", "S", "S", "M", "M", "M"],
  skill_09: ["S", "P", "S", "S", "M", "M", "M"],
  skill_10: ["S", "S", "P", "S", "S", "S", "S"],
  skill_11: ["S", "M", "P", "S", "S", "S", "M"],
  skill_12: ["M", "M", "S", "M", "S", "S", "P"],
  skill_13: ["M", "M", "M", "M", "M", "M", "P"],
  skill_14: ["S", "M", "S", "S", "M", "M", "P"],
  skill_15: ["M", "S", "M", "S", "S", "P", "P"],
  skill_16: ["M", "M", "M", "M", "P", "S", "M"],
  skill_17: ["S", "S", "S", "P", "M", "M", "M"],
  skill_18: ["S", "M", "S", "S", "M", "M", "S"],
  skill_19: ["M", "M", "S", "M", "M", "P", "S"],
  skill_20: ["N", "N", "N", "N", "N", "N", "N"],
};

const ARCH_MYSTIC = 5;  // mystic/psionic archetype
const SKILL_MISTIC = 'skill_16'; // psi control skill

export default class SFCharacter extends BasicCharacter {
  constructor(defStat = 2, ruleset = "tcsf") {
    super();
    this.stats.AGI = defStat;
    //this.stats.AIM = defStat;
    this.stats.APP = defStat;
    this.stats.CHA = defStat;
    //this.stats.CMB = defStat;
    this.stats.CON = defStat;
    this.stats.INT = defStat;
    this.stats.PER = defStat;
    this.stats.POW = 0;
    this.stats.PRE = defStat;
    this.stats.STR = defStat;
    this.stats.WIL = defStat;
    this.ruleset = ruleset;
    this.statPoints = this.statCost(defStat) * 8;
  }

  randomCharacter(ruleset, archetype_id, experienceClass) {
    if (archetype_id < 0 || archetype_id > skillsTable_SF.skill_01.length)
      throw new Error("Archetipo fuori range " + archetype_id);
    if (experienceClass < 0 || experienceClass >= randomSkillLevels.P.length)
      throw new Error("Classe di esperienza fuori range");
    this.archetype_id = archetype_id;
    this.ruleset = ruleset;

    // set stats
    switch (ruleset) {
      case "tcsf":
        Object.keys(this.stats).forEach((k) => {
          let type = statsTable_RF[k][archetype_id - 1];
          //console.log('Stats '+k+' - type =' + type);
          this.stats[k] = this.randomStat(type);
        });
        break;
      default:
        throw new Error("Sistema di gioco non supportato");
    }

    // set skills
    switch (ruleset) {
      case "tcsf":
        Object.keys(this.skills).forEach((k) => {
          let type = skillsTable_SF[k][archetype_id - 1];
          console.log('Skill '+k+' - type =' + type);
          this.skills[k] = randomSkillLevels[type][experienceClass]();
          //console.log('rolled: '+this.skills[k]);
        });
        break;
      default:
        throw new Error("Sistema di gioco non supportato");
    }

    if (this.archetype_id !== ARCH_MYSTIC) {
      this.stats.POW = 0;
      this.skills[SKILL_MISTIC] = 0;
    }

    // personality traits
    this.notes = this.generateNotes();
  }

}