import React, { useState, useEffect } from "react";
//import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
//import validator from "validator";

function MUITextField(props) {
  const [ErrorMsg, setErrorMsg] = useState(() => {
    const initialState = initErrorMsg();
    return initialState;
  });

  function initErrorMsg() {
    if (props.type === "radio") return "";
    if (!props.errList || props.errList.length === 0) return "";
    let txt = "";
    let errList = props.errList.filter((el) => el.id === props.name);
    if (errList.length > 0) {
      errList.forEach((el) => (txt += el.msg + " "));
    }
    const initialState = txt;
    return initialState;
  }

  useEffect(() => {
    if (ErrorMsg === "") {
      let txt = initErrorMsg();
      //if (txt !== "") 
      setErrorMsg(txt);
    }
  }, [props.errList]);

  function checkField(ev) {
    props.change(props.name, ev.target.value);
    let val = ev.target.value;
    if (props.maxlen && props.maxlen > 0 && val.length > props.maxlen) {
      setErrorMsg('Testo troppo lungo: max ' + props.maxlen + ' caratteri');
      return;
    }
    if (props.required && val.trim().length === 0) {
      setErrorMsg('Inserisci un testo.');
    } else {
      setErrorMsg("");
    }
  }

  let label = props.lbl;
  //console.log(props);
  //console.log('... '+props.name + ' - ' + ErrorMsg);
  if (props.required === true && label.length > 0) label += "*";

  return (
    <div className={props.cssClass}>
      <TextField 
        label={label} 
        variant="outlined"
        name={props.name}
        value={props.value}
        placeholder={props.placeholder ? props.placeholder : ""}
        onChange={(e) => checkField(e)}
        //style={setStyle()}
        readOnly={props.readonly && props.readonly === true ? true : false}
        error={ErrorMsg.length > 0? true:false}
        helperText={ErrorMsg.length > 0? ErrorMsg:""}
        size="small"
        onKeyDown={props.onenter?(e) => e.key === 'Enter'?props.onenter():null : null}
        type={props.type? props.type : "text" }
      />
    </div>
  );
}

export default MUITextField;
