import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';


const defaultTheme = createTheme();

export default function Footer(props) {
  const [webBuildVersion, setWebBuildVersion] = useState('');

  // get build version
  useEffect(() => {
    let packageJson = require('../../package.json');
    setWebBuildVersion(packageJson.version);
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>

      <CssBaseline />
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: 'auto',
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1" align="center">
            {props.text}
          </Typography>
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href={props.cfg.urlBase} >
                {props.cfg.name}
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
              {" Build "+webBuildVersion}
            </Typography>
          </Container>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
