import React from "react";
import BasicCharacter from "../Tools/BasicCharacter";
import './StatsSvg.css';
import type { RulesetDescrType } from '../types';

type StatsSvgPropsType = {
    ruleset: RulesetDescrType;
    character: BasicCharacter;
    disabled?: boolean; // unused
    scale?: boolean;
}

//const height = 140;
const width = 220;
const height = 220;
const margin = 10;
const barLength = (height - (margin * 2)) / 2 - 10;
const xc = width / 2;
const yc = height / 2;
const textXOffset = -10;
const textYOffset = 2;
//const textRadOffset = -5;
const textBarOffset = 10;
const maxStat = 7;
const statsVals = [1, 2, 3, 4, 5, 6];
const statsStep = barLength / (maxStat);

export default function StatsSvg(props: StatsSvgPropsType) {
    //const ledY = 0;
    let rad = (360 / props.ruleset.statsOrder.length) * (Math.PI / 180);

    //console.log('..............', props) 

    return (
        <div className="charsvg-container" >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={"0 0 " + width + " " + height}
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
            >
                {
                    // draw stats lines
                    props.ruleset.statsOrder.map((attr, idx) => (
                        <line
                            //className="fil9 str1"
                            //stroke="#C5C6C6"
                            //fill="#dddddd"
                            stroke="#aaaaaa"
                            x1={xc}
                            y1={yc}
                            x2={xc + barLength * Math.cos(rad * idx)}
                            y2={yc + barLength * Math.sin(rad * idx)}
                            key={'statbar_' + props.character.id + '_' + idx}
                        />
                    ))
                }
                {
                    statsVals.map((val) => (
                        <>
                            <circle
                                cx={xc}
                                cy={yc}
                                r={statsStep * val}
                                stroke="#dddddd"
                                fill="transparent"
                                key={'stcrc_' + props.character.id + '_' + val}
                            />
                            {
                                props.scale ? (
                                    <>
                                        <text
                                            className="scale-label-svg"
                                            x={xc + statsStep * val + 2}
                                            y={yc + 10}
                                            key={'scaler_' + props.character.id + '_' + val}
                                        >
                                            {val}
                                        </text>
                                        <text
                                            className="scale-label-svg"
                                            x={xc - statsStep * val - 7}
                                            y={yc + 10}
                                            key={'scalel_' + props.character.id + '_' + val}
                                        >
                                            {val}
                                        </text>
                                    </>
                                ) : null
                            }
                        </>
                    ))
                }
                {
                    // draw stats labels
                    props.ruleset.statsOrder.map((attr, idx) => (
                        <text
                            className="stats-label-svg"
                            x={xc + (barLength + textBarOffset) * Math.cos(rad * idx) + textXOffset}
                            y={yc + (barLength + textBarOffset) * Math.sin(rad * idx) + textYOffset}
                            key={'stlbl_' + props.character.id + '_' + idx}
                        >
                            {props.ruleset.statsNames[attr].substring(0, 3).toUpperCase()}
                        </text>
                    )
                    )
                }
                {
                    // draw stat values connecting lines 
                    props.ruleset.statsOrder.map((attr, i) => {
                        let nexti = i < (props.ruleset.statsOrder.length - 1) ? (i + 1) : 0;
                        let len1 = statsStep * props.character.stats[attr];
                        let len2 = statsStep * props.character.stats[props.ruleset.statsOrder[nexti]];
                        return (
                            <line
                                stroke={"#2222ff"}
                                //stroke={"orange"}
                                //stroke={props.character.statPoints <= 35? "red": props.character.statPoints <= 40? "#2222ff" : "lime"}
                                x1={xc + len1 * Math.cos(rad * i)}
                                y1={yc + len1 * Math.sin(rad * i)}
                                x2={xc + len2 * Math.cos(rad * nexti)}
                                y2={yc + len2 * Math.sin(rad * nexti)}
                                key={'stval_' + props.character.id + '_' + i}
                            />
                        )
                    })
                }
            </svg>
        </div>
    )
}