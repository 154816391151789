import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import TalentForm from "../Components/TalentForm";
import BasicTalent from "../Tools/BasicTalent";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { UserContext } from '../Components/UserContext';

function TalentCreatePage(props) {
    // Sets initial state for skill list to empty array
    const [talent, setTalent] = useState(null);
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { cfg } = props;

    useEffect(() => {
        //console.log('id='+id);
        //console.log('TALENT TYPE',cfg.talentTypes[0]);
        if (!user.auth || user.level <= 1) {
            navigate('/login');
        } else {
            setTalent(new BasicTalent(cfg.talentTypes[0].id, 1, cfg.rulesetList[0].id, 0));
        }
    }, [cfg, user]);


    //if (!talent || IsLoading) {
    if (!talent) {
        return (
            <div className="spinner">
                <img src="/images/waiting.gif" alt="loading..." />
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>Scheda talento</title>
            </Helmet>

            <main>
                <div className='pg_container'>
                    <Container maxWidth="md" className='title_container'>
                        <Typography variant="h4" align="center" color="textPrimary" gutterBottom >
                            Scheda Talento
                        </Typography>
                    </Container>

                    <Container maxWidth="large" className='character_container'>
                        <TalentForm
                            talent={talent}
                            skills={props.skills}
                            talentTypes={cfg.talentTypes}
                            talentGrades={cfg.talentGrades}
                            rulesetList={cfg.rulesetList}
                            wsCall={props.wsCall}
                            wsUrl="talents"
                            redirectUrl='/talenti'
                            backUrl='/talenti'
                        />
                    </Container>
                </div>
            </main>
        </>
    );
}

export default TalentCreatePage;
