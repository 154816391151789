import React, { useState, useEffect, useCallback } from 'react';
//import { makeStyles } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function MUISelectField(props) {
  const initErrorMsg = useCallback(() => {
    if (!props.errList || props.errList.length === 0)
      return "";
    let txt = "";
    let errList = props.errList.filter((el) => el.id === props.name);
    if (errList.length > 0) {
      errList.forEach((el) => txt += el.msg + " ");
    }
    const initialState = txt;
    return initialState;
  }, [props.errList, props.name]);
  
  const [ErrorMsg, setErrorMsg] = useState(() => {
    const initialState = initErrorMsg();
    return initialState;
  });


  useEffect(() => {
    if (ErrorMsg === "") {
      let txt = initErrorMsg();
      if (txt !== "")
        setErrorMsg(txt);
    }
  }, [props.errList, ErrorMsg, initErrorMsg]);

  function checkField(ev) {
    props.change(props.name, ev.target.value);
    setErrorMsg("");
  }
  
  let label = props.lbl;
  if (props.required === true && label.length > 0)
    label += "*";

  if ((props.hideIfEmpty === true) && (props.options.length === 0))
    return null;

  let minWidth = props.minWidth? props.minWidth : "100%";
  //let style = {{ninWidth}}

  return (
    <>
      <div className={props.cssClass}>
        {props.left || !(props.right)? <InputLabel id={props.name + "_label"}>{label}</InputLabel>: null}        
        <Select
          //labelId={props.name + "_label"}
          id={props.name}
          name={props.name}
          value={props.value}
          //label={label}
          onChange={(e) => checkField(e)}
          variant='outlined'
          //style={{ minWidth: "220px" }}
          style={{ minWidth: minWidth }}
          readOnly={props.readonly && props.readonly === true?true:false}
          size="small"
          error={ErrorMsg.length > 0? true:false}
        >
          {props.options.length === 0 ? (
            <MenuItem value="0" key="op_0">
              {" "}
            </MenuItem >
          ) : null}
          {props.emptyOption && props.emptyOption.length >= 2 ? (
            <MenuItem value={props.emptyOption[0]} key={"op_empty"}>
              {props.emptyOption[1]}
            </MenuItem >
          ) : null}
          {props.options.map((el) => (
            <MenuItem value={el[props.valName]} key={"op_"+props.name+ "_" + el[props.valName]}>
              {props.composeText(el)}
            </MenuItem >
          ))}
        </Select>
        {props.right? <InputLabel id={props.name + "_label"}>{label}</InputLabel>: null}        

        {ErrorMsg.length > 0 ? (
          <div
            className="error_msg"
          >
            <p>{ErrorMsg}</p>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default MUISelectField;