import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

function OkPopup(props) {
  return (
    <Dialog open={true}>
      <DialogTitle>{props.titletext}</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {props.text}
        </Typography>
        <br />
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button variant="contained" onClick={props.closeHandler}>
            OK
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default OkPopup;
