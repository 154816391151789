//import "devextreme/dist/css/dx.light.css";
//import "./App.css";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Helmet, HelmetProvider } from "react-helmet-async";
import g_cfg from "./config";
import Sidebar from "./Components/Slidebar";
import Footer from "./Components/Footer";
import HomePage from "./WebPages/HomePage";
import SkillsPage from "./WebPages/SkillsPage";
import CharactersPage from "./WebPages/CharactersPage";
import CharacterViewPage from "./WebPages/CharacterViewPage";
import CharacterEditPage from "./WebPages/CharacterEditPage";
import CharacterCreatePage from "./WebPages/CharacterCreatePage";
import TalentsPage from "./WebPages/TalentsPage";
import TalentViewPage from "./WebPages/TalentViewPage";
import TalentEditPage from "./WebPages/TalentEditPage";
import TalentCreatePage from "./WebPages/TalentCreatePage";
import RulesetsPage from "./WebPages/RulesetsPage";
import RulesetPage from "./WebPages/RulesetPage";
import RegisterPage from "./WebPages/RegisterPage";
import LoginPage from "./WebPages/LoginPage";
import LogoutPage from "./WebPages/LogoutPage";
import UserPage from "./WebPages/UserPage";
import UserCharactersPage from "./WebPages/UserCharactersPage";
import NoPage from "./WebPages/NoPage";
import CookieConsent from "react-cookie-consent";
import { CssBaseline, AppBar, Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { UserContext } from "./Components/UserContext";
//import Paper from "@mui/material/Paper";
//import 'devextreme/dist/css/dx.material.blue.light.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: true,
      refetchOnReconnect: true,
      retry: true,
      staleTime: 15 * 60 * 1000, // 15 min
    },
  },
});

const themeLight = createTheme({
  palette: {
    background: {
      default: "#e4f0e2",
    },
  },
});

const themeDark = createTheme({
  palette: {
    background: {
      default: "#222222",
    },
    text: {
      primary: "#ffffff",
    },
  },
});

function App() {
  const [TextStrings, setTextStrings] = useState(null); // Unused: costanti di testo nella lingua corrente
  const [IsLoading, setIsLoading] = useState(false);
  const [netError, setNetError] = useState(null);
  const [skills, setSkills] = useState(null);
  const navigate = useNavigate();
  const { user, logout } = useContext(UserContext);
  const [ConsentCookie, setConsentCookie] = useState(() => {
    // cerca il cookie consent
    let cookie = getCookie(g_cfg.consentCookieName);
    return cookie.length > 0 ? cookie : null;
  });
  const [light, setLight] = React.useState(true);

  // da https://www.w3schools.com/js/js_cookies.asp
  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // da tabnine.com
  function setCookie(cName, cValue, expHours = 0) {
    var expires = "";
    if (expHours > 0) {
      let date = new Date();
      date.setTime(date.getTime() + expHours * 60 * 60 * 1000);
      expires = "expires=" + date.toUTCString() + "; ";
    }
    document.cookie =
      cName + "=" + cValue + "; " + expires + "path=/; SameSite=None; Secure";
  }

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const wsCallSvc = useCallback(async (
    url,
    method,
    loading,
    setLoading,
    setErr,
    paramList,
    action
  )=> {
    if (loading) return;
    setLoading(true);
    setErr(null);
    if (g_cfg.debug === true) console.log("wsCallSvc " + url);
    try {
      url = g_cfg.urlWSBase + url;
      var myHeaders = new Headers();
      if (user.auth === true) {
        myHeaders.append("Authorization", "Bearer " + user.token);
        //if (g_cfg.debug === true)
        //console.log('token = '+user.token);
      }
      myHeaders.append("Accept", "application/json");

      var requestOptions = {
        method: method,
        headers: myHeaders,
        redirect: "follow",
      };
      var formdata = new FormData();
      if (paramList && paramList.length > 0) {
        paramList.forEach((par) => {
          if (g_cfg.debug === true) console.log(par.name + ", " + par.value);
          formdata.append(par.name, par.value);
        });
        requestOptions.body = formdata;
      }

      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        if (g_cfg.debug === true) {
          console.log("--- error ---", response);
        }
        if (response.status === 401) {
          logout();
          navigate("/login");
        }
        if (response.status === 404) {
          navigate("/404");
        }
        const err = await response.json();
        //console.log(err);
        if (err.message) throw new Error(err.message);
        else throw new Error(response.statusText);
      }
      const respData = await response.json();
      if (g_cfg.debug === true) console.log(respData);
      action(respData);
      setLoading(false);
    } catch (error) {
      console.log(error.message + " (" + url + ")");
      setErr(error.message);
      setLoading(false);
    }
  }, [logout, navigate, user.auth, user.token]);

  const getSkills = useCallback(() => {
    let params = [];
    wsCallSvc(
      "skills",
      "GET",
      IsLoading,
      setIsLoading,
      setNetError,
      params,
      (respData) => {
        if (g_cfg.debug === true) {
          console.log("get skills", respData);
        }
        // handle get order detail response
        if (respData.status === true) {
          setSkills(respData.data);
        } else {
          console.log("Failed: " + respData.message);
        }
      }
    );
  }, [IsLoading, wsCallSvc]);

  useEffect(() => {
    if (!skills) {
      getSkills();
    }
  }, [skills, getSkills]);

  if (!skills)
    return (
      <div className="spinner">
        <img src="/images/waiting.gif" alt="loading..." />
      </div>
    );

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <ThemeProvider theme={light ? themeLight : themeDark}>
          <CssBaseline />
          <div className="App" id="outer-container">
            {g_cfg.googleAnalyticsId ? (
              <Helmet>
                <script
                  async
                  src={`https://www.googletagmanager.com/gtag/js?id=${g_cfg.googleAnalyticsId}`}
                />
                <script>
                  {
                  `window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
    
                  gtag('config', '${g_cfg.googleAnalyticsId}');`
                  }
                </script>
              </Helmet>
            ) : null}
            <div id="page-wrap">
              <AppBar>
                <Sidebar
                  pageWrapId={"page-wrap"}
                  outerContainerId={"outer-container"}
                  user={user}
                  // theme={light}
                  // changeState={setLight}
                />
              </AppBar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage cfg={g_cfg} skills={skills} />}
                  />
                  <Route
                    path="/abilita"
                    element={<SkillsPage cfg={g_cfg} skills={skills} />}
                  />

                  <Route
                    path="/personaggi/:id"
                    element={
                      <CharacterViewPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/personaggi/nuovo"
                    element={
                      <CharacterCreatePage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/personaggi/modifica/:id"
                    element={
                      <CharacterEditPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/personaggi"
                    element={
                      <CharactersPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />

                  <Route
                    path="/talenti/:id"
                    element={
                      <TalentViewPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/talenti/nuovo"
                    element={
                      <TalentCreatePage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/talenti/modifica/:id"
                    element={
                      <TalentEditPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/talenti"
                    element={
                      <TalentsPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />

                  <Route
                    path="/registrazione"
                    element={<RegisterPage cfg={g_cfg} wsCall={wsCallSvc} />}
                  />
                  <Route
                    path="/login"
                    element={<LoginPage cfg={g_cfg} wsCall={wsCallSvc} />}
                  />
                  <Route
                    path="/logout"
                    element={<LogoutPage cfg={g_cfg} wsCall={wsCallSvc} />}
                  />
                  <Route
                    path="/profilo/personaggi"
                    element={
                      <UserCharactersPage cfg={g_cfg} wsCall={wsCallSvc} />
                    }
                  />
                  <Route
                    path="/profilo"
                    element={<UserPage cfg={g_cfg} wsCall={wsCallSvc} />}
                  />
                  <Route
                    path="/sistemi-di-gioco"
                    element={
                      <RulesetsPage
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route
                    path="/sistemi-di-gioco/rapido-e-furioso"
                    element={
                      <RulesetPage
                        id="r_f"
                        cfg={g_cfg}
                        wsCall={wsCallSvc}
                        skills={skills}
                      />
                    }
                  />
                  <Route path="*" element={<NoPage />} />
                </Routes>
                <Footer cfg={g_cfg} text="Strumenti per Gdr Orremdi e oltre." />
              </Box>
            </div>
            {ConsentCookie === null ? (
              <CookieConsent
                cookieName={g_cfg.consentCookieName}
                buttonText="Accetto"
                onAccept={() => {
                  setConsentCookie("ACCEPTED");
                }}
                debug={g_cfg.debug}
                //enableDeclineButton
                //acceptOnScroll
                //acceptOnScrollPercentage={80}
              >
                <div className="cookie-text-cont">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: TextStrings
                        ? TextStrings.TXT_COOKIE_POLICY
                        : "Nessun cookie è stato maltrattato per realizzare questo sito. Usiamo solo cookie tecnici per finalità di funzionamento, senza alcun tracciamento delle vostre attività. Buon divertimento!",
                    }}
                    className="cookie-div"
                  />
                </div>
              </CookieConsent>
            ) : null}

            {g_cfg.devTools === true ? <ReactQueryDevtools /> : null}
          </div>
        </ThemeProvider>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
