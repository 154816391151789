import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import './CircledText.css';

function CircledText(props) {

    return (
        <Stack 
            direction="column" 
            justifyContent="center" 
            sx={props.noSquare === true? {padding: '2px'} : {border: '1px solid #d0d7de', boxShadow: '0px 2px 2px #f6f8fa', padding: '4px'}}
            spacing={0}
        >
            <div style={{ margin: 'auto' }}>
                <Box 
                    className={props.nocircle && props.nocircle === true?"nocircle":"circle"}
                >
                    {props.text}
                </Box>
                <Typography>{props.label}</Typography>
            </div>
        </Stack>
    )
}

export default CircledText;